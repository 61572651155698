import React from 'react';
import { useQuery, useMutation } from 'react-query';

import config from 'src/config';

const useMutationGetFoodMenuAds = () => {
  let REST_MNGT_ENDPOINT = config.REST_MNGT_ENDPOINT;
  return useMutation(
    'get-food-menu-ads',
    async (rest_id) => {
      console.log(
        'useMutationGetFoodMenuAds',
        `${REST_MNGT_ENDPOINT}/getFoodMenuAds?rest_id=${rest_id}`
      );
      const response = await fetch(
        `${REST_MNGT_ENDPOINT}/getFoodMenuAds?rest_id=${rest_id}`
      );

      if (!response.ok) {
        // throw new Error('Network response was not ok');
        console.log('error during adding ads impressions');
      }

      return response.json();
    },
    { retry: 5 }
  );
};

export default useMutationGetFoodMenuAds;
