import db from 'src/Firebase/db';
import db_config from 'src/configs/db_config';

const select_values = {
  is_enabled: { ENABLED: true, DISABLED: false },
  ad_window: {
    FOOD_MENU: { id: 1, name: 'food_menu' },
    FOOD_DETAIL: { id: 2, name: 'food_detail' },
    ORDER_LIST: { id: 3, name: 'order_list' },
  },
  ad_type: {
    AD_TYPE_MENYLIGHT_ADVERTISEMENT: 'AD_TYPE_MENYLIGHT_ADVERTISEMENT',
    AD_TYPE_MENY_REST_ADVERTISEMENT: 'AD_TYPE_MENY_REST_ADVERTISEMENT',
    AD_TYPE_REST_DEFINE_ADVERTISEMENT: 'AD_TYPE_REST_DEFINE_ADVERTISEMENT',
  },
  ad_action: {
    FOOD_MENU: { id: 1, name: 'food_menu' },
    FOOD_DETAIL: { id: 2, name: 'food_detail' },
  },
};

const ADVERTISEMENT_CONFIG_REF = db.collection(
  db_config.DB_TABLE_ADVERTISEMENT_CONFIG
);

function getAdvertisementByRestId(rest_id) {
  return db
    .collection(db_config.DB_TABLE_ADVERTISEMENT_CONFIG)
    .where('target_restaurants', 'array-contains-any', ['all', rest_id]);
}

function getAllAdvertisements() {
  return db.collection(db_config.DB_TABLE_ADVERTISEMENT_CONFIG);
}

function getFoodMenuAdvertisements(rest_id) {
  return getAllAdvertisements()
    .where('ad_window.name', '==', 'food_menu')
    .get()
    .then((result) => {
      return result.docs.map((doc) => {
        return {
          id: doc.id,
          data: doc.data(),
        };
      });
    });
}

// TODO: remove unused code
// function getFoodMenuAdvertisements1(rest_id) {
//   return getAdvertisementByRestId(rest_id)
//     .where('ad_window_new.name', '==', 'food_menu')
//     .get()
//     .then((result) => {
//       return result.docs.map((doc) => {
//         return {
//           id: doc.id,
//           data: doc.data(),
//         };
//       });
//     });
// }

function getAllFoodMenuAdvertisements() {
  return getAllAdvertisements()
    .where('ad_window.name', '==', 'food_menu')
    .get()
    .then((result) => {
      return result.docs.map((doc) => {
        return {
          id: doc.id,
          data: doc.data(),
        };
      });
    });
}

function getAllMenyLightFoodMenuAdvertisements() {
  return (
    getAllAdvertisements()
      // .where(
      //   'ad_type',
      //   '==',
      //   select_values.ad_type.AD_TYPE_MENYLIGHT_ADVERTISEMENT
      // )
      .where('ad_window.name', '==', select_values.ad_window.FOOD_MENU.name)
      .get()
      .then((result) => {
        return result.docs.map((doc) => {
          return {
            id: doc.id,
            data: doc.data(),
          };
        });
      })
  );
}

function getAllFoodDetailAdvertisements() {
  return getAllAdvertisements()
    .where('ad_window.name', '==', 'food_detail')
    .get()
    .then((result) => {
      return result.docs.map((doc) => {
        return {
          id: doc.id,
          data: doc.data(),
        };
      });
    });
}

function getFoodDetailAdvertisements(rest_id) {
  return getAdvertisementByRestId(rest_id)
    .where('ad_window.name', '==', 'food_detail')
    .get()
    .then((result) => {
      return result.docs.map((doc) => {
        return {
          id: doc.id,
          data: doc.data(),
        };
      });
    });
}

export {
  select_values,
  getFoodMenuAdvertisements,
  getFoodDetailAdvertisements,
  getAdvertisementByRestId,
  ADVERTISEMENT_CONFIG_REF,
  getAllFoodDetailAdvertisements,
  getAllFoodMenuAdvertisements,
  getAllMenyLightFoodMenuAdvertisements,
};
