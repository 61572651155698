import React from 'react';
import { Box, Grid } from '@material-ui/core';

import { LYNKED_GREEN, LYNKED_WHITE } from 'src/consts/colors';
import { MenyContext } from '../../contexts';

import { CLIENT_TABLE_ASSIGNED, CLIENT_WAITING_TABLE } from 'src/StateMachines/STATES';

import TableNumberLabel from './TableNumberLabel';

import ReservationNumberLabel from './ReservationNumberLabel';
import { useTranslation } from 'react-i18next';

const LOG_PREFIX = 'ThinNamePlate';

function LineupNumOfAdult({ formInfo }) {
  const { t } = useTranslation();

  if (formInfo)
    return (
      <span id={'lineup-num-of-adult'} style={{ height: '100%', fontSize: '10px', lineHeight: '17px' }}>
        {t('MENU_ADULT_WITH_QTY', { qty: (formInfo && formInfo['num-of-adult']) || 0 })}
      </span>
    );

  return <span style={{ height: '100%', fontSize: '10px', lineHeight: '17px' }}></span>;
}
function LineupNumOfChildren({ formInfo }) {
  const { t } = useTranslation();

  if (formInfo)
    return (
      <span id={'lineup-num-of-child'} style={{ height: '100%', fontSize: '10px', lineHeight: '17px' }}>
        {t('MENU_CHILD_WITH_QTY', { qty: (formInfo && formInfo['num-of-child']) || 0 })}
      </span>
    );

  return <span style={{ height: '100%', fontSize: '10px', lineHeight: '17px' }}></span>;
}

function LineupUserName({ formInfo }) {
  const { t } = useTranslation();

  return (
    <Box
      style={{
        textAlign: 'right',
        color: LYNKED_GREEN,
        fontWeight: 'bold',
        fontSize: '18px',
        lineHeight: '26px',
      }}
    >
      {t('MENU_MR', { name: (formInfo && formInfo['lineup-username']) || '' })}
    </Box>
  );
}

export default () => {
  const { meny_client_state_machine, formInfo } = React.useContext(MenyContext);

  let [client_state] = meny_client_state_machine;

  let [current_ticket, setCurrentTicket] = React.useState('');

  let [show_table_grey, setShowTableGrey] = React.useState(false);

  React.useEffect(() => {
    let { form_info } = client_state.context;
    if (client_state.matches(CLIENT_TABLE_ASSIGNED)) {
      setShowTableGrey(true);
    } else if (client_state.matches(CLIENT_WAITING_TABLE)) {
      setCurrentTicket(form_info?.current_ticket);
    } else {
      setShowTableGrey(false);
    }
  }, [meny_client_state_machine]);

  return (
    <>
      <Box style={{ height: '100%' }}>
        <Grid container style={{ height: '100%' }}>
          <Grid item xs={7} style={{ height: '100%' }}>
            <Grid container style={{ height: '100%', fontStyle: 'normal', fontWeight: 'normal', fontSize: '12px' }}>
              <Grid
                item
                xs={12}
                style={{
                  height: '100%',
                  display: 'flex',
                  flexFlow: 'row',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                }}
              >
                <LineupUserName formInfo={formInfo} />
                <Box>
                  <LineupNumOfAdult formInfo={formInfo} />
                  <LineupNumOfChildren formInfo={formInfo} />
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={5} style={{ height: '100%' }}>
            <Grid
              container
              style={{
                height: '100%',
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '10px',
                lineHeight: '14px',
                color: LYNKED_WHITE,
              }}
            >
              {show_table_grey ? (
                <Grid
                  item
                  xs={6}
                  style={{ display: 'flex', flexFlow: 'column', justifyContent: 'center', alignItems: 'center' }}
                >
                  <TableNumberLabel formInfo={formInfo} />
                </Grid>
              ) : (
                <Grid item xs={6}></Grid>
              )}
              <Grid
                item
                xs={6}
                style={{ display: 'flex', flexFlow: 'column', justifyContent: 'center', alignItems: 'center' }}
              >
                <ReservationNumberLabel ticket_number={current_ticket} show_table_grey={show_table_grey} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
