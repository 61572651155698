const STATUS_HIDE_FROM_MENU = 'STATUS_HIDE_FROM_MENU';
const STATUS_AVAILABLE = 'STATUS_AVAILABLE';
const STATUS_SOLD_OUT = 'STATUS_SOLD_OUT';
const STATUS_IN_STOCK = 'STATUS_IN_STOCK';

export {
  STATUS_HIDE_FROM_MENU,
  STATUS_AVAILABLE,
  STATUS_SOLD_OUT,
  STATUS_IN_STOCK,
};
