import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import { ThirdPartyLibProvider } from '../contexts/ThirdPartyLib';

export default function AppContexts({ children }) {
  try {
    return (
      <>
        <BrowserRouter>
          <ThirdPartyLibProvider>{children}</ThirdPartyLibProvider>
        </BrowserRouter>
      </>
    );
  } catch (error) {
    return <div>sorry technical issue</div>;
  }
}
