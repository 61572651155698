import React from 'react';

function LynkedLogoJsx({ width = 1000, height = 400 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}
      version="1.2"
      viewBox="0 0 750 300"
    >
      <defs>
        <g>
          <symbol overflow="visible">
            <path
              stroke="none"
              d="M15.39-215.438h137.876V0H15.39zm88.938 26.157l-19.687 60.015L65.25-189.28H38.781l26.766 79.406-28.61 83.719h23.688l21.234-64.64 20.938 64.64h27.078l-28.625-83.719 26.781-79.406zm0 0"
            ></path>
          </symbol>
          <symbol id="glyph0-1" overflow="visible">
            <path
              stroke="none"
              d="M44.016-71.406L1.234-215.437h35.391l25.844 98.171 25.86-98.171h32.312L77.859-71.407V0H44.016zm0 0"
            ></path>
          </symbol>
          <symbol id="glyph0-2" overflow="visible">
            <path
              stroke="none"
              d="M42.469-156.031V0H12v-215.438h42.469L89.25-86.483v-128.954h30.156V0H84.641zm0 0"
            ></path>
          </symbol>
          <symbol id="glyph0-3" overflow="visible">
            <path
              stroke="none"
              d="M56.625-85.86L46.172-66.171V0h-33.86v-215.438h33.86v93.875l44.312-93.874h33.86l-47.094 96.03L124.344 0H89.563zm0 0"
            ></path>
          </symbol>
          <symbol id="glyph0-4" overflow="visible">
            <path
              stroke="none"
              d="M46.172-124.64H92.64v30.765H46.17v63.094h58.47V0H12.312v-215.438h92.329v30.782H46.17zm0 0"
            ></path>
          </symbol>
          <symbol id="glyph0-5" overflow="visible">
            <path
              stroke="none"
              d="M12.313-215.438h53.546c17.032 0 29.696 4.516 38 13.547 8.313 9.024 12.47 22.258 12.47 39.703V-53.25c0 17.45-4.157 30.688-12.47 39.719C95.555-4.508 82.891 0 65.86 0H12.313zm33.859 30.782v153.875H65.25c5.332 0 9.535-1.535 12.61-4.61 3.081-3.082 4.624-8.52 4.624-16.312v-112.031c0-7.79-1.543-13.223-4.625-16.297-3.074-3.082-7.277-4.625-12.609-4.625zm0 0"
            ></path>
          </symbol>
          <symbol id="glyph0-6" overflow="visible">
            <path
              stroke="none"
              d="M12.313-215.438h33.859V-30.78h55.703V0H12.312zm0 0"
            ></path>
          </symbol>
        </g>
      </defs>
      <g
        fill="maroon"
        fillOpacity="1"
        transform="matrix(1.04439 0 0 1.04439 -27.762 -258.091)"
      >
        <use
          width="100%"
          height="100%"
          x="115.535"
          y="498.466"
          fill="maroon"
          fillOpacity="1"
          xlinkHref="#glyph0-1"
        ></use>
      </g>
      <g
        fill="#000"
        fillOpacity="1"
        transform="matrix(1.04439 0 0 1.04439 -27.762 -258.091)"
      >
        <use
          width="100%"
          height="100%"
          x="237.372"
          y="498.466"
          xlinkHref="#glyph0-2"
        ></use>
      </g>
      <g
        fill="#000"
        fillOpacity="1"
        transform="matrix(1.04439 0 0 1.04439 -27.762 -258.091)"
      >
        <use
          width="100%"
          height="100%"
          x="368.783"
          y="498.466"
          xlinkHref="#glyph0-3"
        ></use>
      </g>
      <g
        fill="#000"
        fillOpacity="1"
        transform="matrix(1.04439 0 0 1.04439 -27.762 -258.091)"
      >
        <use
          width="100%"
          height="100%"
          x="496.193"
          y="498.466"
          xlinkHref="#glyph0-4"
        ></use>
      </g>
      <g
        fill="#000"
        fillOpacity="1"
        transform="matrix(1.04439 0 0 1.04439 -27.762 -258.091)"
      >
        <use
          width="100%"
          height="100%"
          x="609.447"
          y="498.466"
          xlinkHref="#glyph0-5"
        ></use>
      </g>
      <g
        fill="#000"
        fillOpacity="1"
        transform="matrix(1.04439 0 0 1.04439 -27.762 -258.091)"
      >
        <use
          width="100%"
          height="100%"
          x="32.941"
          y="498.466"
          xlinkHref="#glyph0-6"
        ></use>
      </g>
    </svg>
  );
}

export default LynkedLogoJsx;
