import React from 'react';
import { useSnackbar } from 'notistack';
import { Box, Button, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';

import useMutationAddServiceRequest from 'src/hooks/useMutationAddServiceRequest';

import active_lang from 'src/langs/jp_en';

import { useStyles } from './style';
import AlarmIconJsx from './AlarmIcon';
import { MenyContext } from '../../../contexts';
import { useTranslation } from 'react-i18next';

let LOG_PREFIX = 'ServiceRequestDialog';

export default function DialogBody({ handleBackOnClick, setOpen }) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  let mutationAddServiceRequest = useMutationAddServiceRequest();
  let { meny_client_state_machine, monitor_order_status_machine, formInfo } = React.useContext(MenyContext);
  const { t } = useTranslation();

  let [current_client_state] = meny_client_state_machine;
  let [current_monitor_order_status] = monitor_order_status_machine;

  let { order_history_id } = current_monitor_order_status.context;

  const handleCloseOnClick = () => {
    setOpen(false);
  };

  const handleSendServiceRequest = () => {
    const { rest_id } = current_client_state.context;
    const username = formInfo['lineup-username'];
    const table_name = formInfo['assign_table_name'];

    return mutationAddServiceRequest
      .mutateAsync({ rest_id, table_name, username, order_history_id })
      .then((service_request_doc) => {
        // MENY-256, update fixing request monitor missing after browser refresh
        enqueueSnackbar(t('OTHERS_CALL_COMPLETED'), { variant: 'success' });
      })
      .then(() => {
        handleBackOnClick();
      })
      .catch((err) => {
        console.error(LOG_PREFIX, 'handleSendServiceRequest', 'err', err);
      });
  };

  return (
    <>
      <DialogTitle id="alert-dialog-slide-title" style={{ textAlign: 'center' }}>
        <Typography
          variant="h3"
          color="textPrimary"
          style={{
            fontSize: '23.4px',
            fontWeight: '700',
          }}
        >
          {t('OTHERS_CALL_REQUEST')}
        </Typography>
      </DialogTitle>
      <DialogContent style={{ textAlign: 'center' }}>
        <Box
          style={{
            display: 'inline-flex',
            flexFlow: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box style={{ width: '50px', height: '50px' }}>
            <AlarmIconJsx width="100%" height="50px" />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center' }}>
        <Box style={{ paddingTop: '2rem' }}>
          <Button id="button-cancel" onClick={handleCloseOnClick} className={classes.button_no}>
            {t('OTHERS_NO')}
          </Button>
          <Button id="button-ok" onClick={handleSendServiceRequest} className={classes.button_yes}>
            {t('OTHERS_YES')}
          </Button>
        </Box>
      </DialogActions>
    </>
  );
}
