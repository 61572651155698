import React from 'react';

import ThinNamePlate from '../../../components/ThinNamePlate';

export default function ShowNamePlace() {
  return (
    <div style={{ minHeight: '50px', height: '50px' }}>
      <ThinNamePlate />
    </div>
  );
}
