import React from 'react';

import { IconButton } from '@material-ui/core';

import RemoveIcon from '@material-ui/icons/Remove';

export default function RemovePeopleButton({ handleRemoveItem }) {
  return (
    <>
      <div
        style={{
          width: '25%',
          display: 'flex',
          flexFlow: 'row',
          justifyContent: 'flex-end',
          marginRight: '1rem',
        }}
      >
        <div
          style={{
            borderRadius: '26px',
            border: '1px solid #707070',
            width: '28px',
            height: '28px',

            display: 'flex',
            flexFlow: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IconButton id="remove_people" onClick={handleRemoveItem}>
            <RemoveIcon />
          </IconButton>
        </div>
      </div>
    </>
  );
}
