import React from 'react';

import { Box, Grid } from '@material-ui/core';

import RestaurantLogo from '../RestaurantLogo';

import BackButton from './BackButton';
import ServiceRequestButton from './ServiceRequestButton';

import { useStyles } from './styles';

export default ({ show_back_button, back_button_callback }) => {
  const classes = useStyles();

  return (
    <>
      <Grid container style={{ height: '100%' }}>
        {/* left */}
        <Grid item xs={3} style={{ height: '100%' }}>
          {show_back_button ? (
            <Box className={classes.headbar_content}>
              <BackButton back_button_callback={back_button_callback} />
            </Box>
          ) : (
            <></>
          )}
        </Grid>
        {/* left */}

        {/* center */}
        <Grid item xs={6} style={{ height: '100%' }}>
          <RestaurantLogo />
        </Grid>
        {/* center */}

        {/* right */}
        <Grid item xs={3} style={{ height: '100%' }}>
          <ServiceRequestButton />
        </Grid>
        {/* right */}
      </Grid>
    </>
  );
};
