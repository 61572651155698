import { createMachine } from 'xstate';

import {
  RESTAURANT_CLOSED,
  RESTAURANT_OPENED,
  CLIENT_BILL_CLEARED,
  RESTAURANT_STATUS_UNKNOWN,
  CLIENT_WAITING_TABLE,
  CLIENT_TABLE_ASSIGNED,
  CLIENT_LANDING,
} from './STATES';

const LOG_PREFIX = 'MenyClientStateMachine';

const MenyClientStateMachine = createMachine({
  id: 'meny_client_state_machine',
  initial: RESTAURANT_STATUS_UNKNOWN,
  context: {
    lineup_id: null,
    rest_id: null,
    menu_id: null,
    form_info: null,
    table_assign_status: null,
    restaurant_open_id: null,
    order_history_id: null,
    table_name: null,
  },
  states: {
    RESTAURANT_STATUS_UNKNOWN: {
      entry: [
        (context, event) => {
          context.lineup_id = null;
          context.rest_id = null;
          context.menu_id = null;
          context.form_info = null;
          context.table_assign_status = null;
          context.restaurant_open_id = null;
          context.order_history_id = null;
          context.table_name = null;
        },
      ],
      on: {
        RESTAURANT_OPENED: {
          target: RESTAURANT_OPENED,
          actions: [
            (context, event) => {
              context.rest_id = event.rest_id;
              context.menu_id = event.menu_id;
            },
          ],
        },

        RESTAURANT_CLOSED: {
          target: RESTAURANT_CLOSED,
          actions: [
            (context, event) => {
              context.rest_id = event.rest_id;
              context.menu_id = event.menu_id;
            },
          ],
        },
      },
      always: { target: CLIENT_LANDING },
    },
    RESTAURANT_OPENED: {
      // immediately take the transition to 'three'
      entry: [
        (context, event) => {
          context.lineup_id = null;
          context.form_info = null;
        },
      ],
      on: {},
      always: { target: CLIENT_LANDING },
    },
    RESTAURANT_CLOSED: {
      entry: [
        (context, event) => {
          context.lineup_id = null;
          context.form_info = null;
        },
      ],
      on: {
        CLIENT_LANDING: {
          target: CLIENT_LANDING,
          actions: [
            (context, event) => {
              context.form_info = event.form_info;
            },
          ],
        },

        CLIENT_WAITING_TABLE: {
          target: CLIENT_WAITING_TABLE,
          actions: [
            (context, event) => {
              context.rest_id = event.rest_id;
              context.menu_id = event.menu_id;
              context.form_info = event.form_info;
              context.lineup_id = event.lineup_id;
              context.restaurant_open_id = event.restaurant_open_id;
            },
          ],
        },
      },
    },
    CLIENT_LANDING: {
      on: {
        CLIENT_WAITING_TABLE: {
          target: CLIENT_WAITING_TABLE,
          actions: [
            (context, event) => {
              context.rest_id = event.rest_id;
              context.menu_id = event.menu_id;
              context.form_info = event.form_info;
              context.lineup_id = event.lineup_id;
              context.restaurant_open_id = event.restaurant_open_id;
            },
          ],
        },
        RESTAURANT_CLOSED: { target: RESTAURANT_CLOSED },
      },
    },
    CLIENT_WAITING_TABLE: {
      on: {
        CLIENT_TABLE_ASSIGNED: {
          target: CLIENT_TABLE_ASSIGNED,
          actions: [
            (context, event) => {
              context.table_assign_status = event.table_assign_status;
              context.table_name = event.table_name;
              context.order_history_id = event.order_history_id;
            },
          ],
        },
        RESTAURANT_CLOSED: { target: RESTAURANT_CLOSED },
        RESTAURANT_STATUS_UNKNOWN: {
          target: RESTAURANT_STATUS_UNKNOWN,
          actions: [
            (context, event) => {
              context.lineup_id = null;
              context.rest_id = null;
              context.menu_id = null;
              context.form_info = null;
              context.table_assign_status = null;
              context.restaurant_open_id = null;
              context.order_history_id = null;
              context.table_name = null;
            },
          ],
        },
      },
    },
    CLIENT_TABLE_ASSIGNED: {
      on: {
        CLIENT_BILL_CLEARED: { target: CLIENT_BILL_CLEARED },
        RESTAURANT_CLOSED: { target: RESTAURANT_CLOSED },
        RESTAURANT_STATUS_UNKNOWN: {
          target: RESTAURANT_STATUS_UNKNOWN,
          actions: [
            (context, event) => {
              context.lineup_id = null;
              context.rest_id = null;
              context.menu_id = null;
              context.form_info = null;
              context.table_assign_status = null;
              context.restaurant_open_id = null;
              context.order_history_id = null;
              context.table_name = null;
            },
          ],
        },

        UPDATE_RESTAURANT_OPEN_ID: {
          target: CLIENT_TABLE_ASSIGNED,
          actions: [
            (context, event) => {
              console.log(LOG_PREFIX, 'update restaurant_open_id');
              context.restaurant_open_id = event.restaurant_open_id;
            },
          ],
        },
      },
    },
    CLIENT_BILL_CLEARED: {
      on: {
        RESTAURANT_STATUS_UNKNOWN: {
          target: RESTAURANT_STATUS_UNKNOWN,
          actions: [
            (context, event) => {
              context.lineup_id = null;
              context.rest_id = null;
              context.menu_id = null;
              context.form_info = null;
              context.table_assign_status = null;
              context.restaurant_open_id = null;
              context.order_history_id = null;
              context.table_name = null;
            },
          ],
        },
      },
    },
  },
});

export { MenyClientStateMachine };
