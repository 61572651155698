import React from 'react';
import { makeStyles, Slide } from '@material-ui/core';

import { LYNKED_WHITE } from 'src/consts/colors';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexFlow: 'column',
    overflowY: 'auto',
  },
  restaurant_bar: {
    minHeight: '100px',

    // MENY-243, required for iphone
    height: '100px',

    backgroundColor: LYNKED_WHITE,

    // boxShadow: '0px 3px 6px #00000029',
  },
}));

export { useStyles, Transition };
