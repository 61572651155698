import React from 'react';
import { isUndefined } from 'lodash';

import { MenyContext } from '../../../contexts';
import FullScreenLoading from '../../../components/FullScreenLoading';
import RestaurantIsNotActive from '../../../components/RestaurantIsNotActive';
import ShowOrderHistoryPage from './ShowOrderHistoryPage';
import ShowDebug from 'src/components/ShowDebug';

export default () => {
  let [is_loading, setIsLoading] = React.useState(true);
  let [load_status] = React.useState('loading_status');

  let { restaurant_config, order_history } = React.useContext(MenyContext);

  let [is_restaurant_active, setIsRestaurantActive] = React.useState(true);

  React.useEffect(() => {
    setIsRestaurantActive(isUndefined(restaurant_config.restaurant_active) || restaurant_config.restaurant_active);
  }, [restaurant_config]);

  React.useEffect(() => {
    setIsLoading(false);
  }, []);

  if (is_loading) return <FullScreenLoading />;

  if (is_restaurant_active) return <ShowOrderHistoryPage order_history={order_history} load_status={load_status} />;

  return (
    <>
      {/* <ShowDebug>{JSON.stringify({ restaurant_config })}</ShowDebug> */}
      <RestaurantIsNotActive />
    </>
  );
};
