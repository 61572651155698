import React from 'react';
import active_lang from 'src/langs/jp_en';

import { LYNKED_RED, LYNKED_WHITE } from 'src/consts/colors';
import { useTranslation } from 'react-i18next';

const sold_out_label_container = {
  width: '75px',
  height: '16px',

  background: LYNKED_RED,
  border: '1px solid rgba(173, 105, 105, 0.33)',

  boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.25)',
  borderRadius: '10px 0px 0px 10px',
  color: LYNKED_WHITE,
};

const sold_out_label = {
  width: '100%',
  height: '100%',

  fontFamily: 'Noto Sans JP',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: '10px',
  lineHeight: '150%',

  display: 'flex',
  flexFlow: 'column',
  alignItems: 'center',
  textAlign: 'center',
  letterSpacing: '0.25em',
  color: LYNKED_WHITE,
};

export default function SoldOutLabel() {
  const { t } = useTranslation();

  return (
    <div style={sold_out_label_container}>
      <div style={sold_out_label}>{t('MENU_OUT_OF_STOCK')}</div>
    </div>
  );
}
