import React from 'react';
import { Box } from '@material-ui/core';
import { useStyles } from './styles';

export default ({ food_entry }) => {
  let classes = useStyles();
  let food_image = food_entry?.image_url;

  if (food_image)
    return (
      <Box className={classes.root} style={{ backgroundImage: `url('${food_entry.image_url}')` }}>
        <Box className={classes.food_image} style={{ backgroundImage: `url('${food_entry.image_url}')` }}></Box>
      </Box>
    );

  return <></>;
};
