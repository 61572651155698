import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { LYNKED_LIGHT_GREY_HOVER } from 'src/consts/colors';
import ShowYen from 'src/components/ShowYen';
import { makeStyles } from '@material-ui/core/styles';
import { LYNKED_RED } from 'src/consts/colors';
import active_lang from 'src/langs/jp_en';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  foodStockCount: {
    color: LYNKED_RED,
    fontWeight: '600',
  },
}));

export default ({ food_entry, restaurant_config }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const getTaxFoodDetail = () => {
    if (!restaurant_config?.taxFoodDetail) 
      return '(税込)';
    if (!restaurant_config.taxFoodDetail.trim())
      return "";
    return `(${restaurant_config.taxFoodDetail})`;
  }

  return (
    <>
      <Box
        style={{
          width: '100%',
          borderBottom: `1px solid ${LYNKED_LIGHT_GREY_HOVER}`,
          fontWeight: 'bold',
        }}
      >
        <Grid container spacing={2} style={{ padding: '1rem' }}>
          <Grid item xs={8} style={{ whiteSpace: 'break-spaces' }}>
            <Box>
              <Grid container>
                <Grid item xs={12}>
                  {food_entry.name}
                </Grid>
                <Grid item xs={12}>
                  {food_entry.long_name}
                </Grid>
                {(!food_entry.sold_out || food_entry.sold_out === active_lang.STATUS_IN_STOCK) &&
                  food_entry.isEnableStockCount && (
                    <Grid item xs={12}>
                      <Typography component="h3" className={classes.foodStockCount}>
                        {t('COMMON_REMAINING_STOCK_QUANTITY')}{food_entry.foodStockCount}
                      </Typography>
                    </Grid>
                  )}
                {food_entry.sold_out === active_lang.STATUS_SOLD_OUT && (
                  <Grid item xs={12}>
                    <Typography component="h3" className={classes.foodStockCount}>
                      {t('MENU_OUT_OF_STOCK')}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={4} style={{ textAlign: 'right' }}>
            <Box
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexFlow: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Box
                style={{
                  width: '100%',
                  display: 'flex',
                  flexFlow: 'row',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                <ShowYen value_yen={food_entry.unit_price} restaurant_config={restaurant_config} />{' '}
                {getTaxFoodDetail()}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
