import React from 'react';
import { useNavigate, useParams } from 'react-router';
import { getNewUserLineupLink } from 'src/utils/getLinks';
import { useErrorBoundary } from 'use-error-boundary';

function RedirectToLineupPage() {
  let navigate = useNavigate();
  let { rest_id, menu_id } = useParams();
  localStorage.clear();
  navigate(getNewUserLineupLink(rest_id, menu_id));

  return <></>;
}

export default ({ children }) => {
  const { ErrorBoundary, didCatch, error, reset } = useErrorBoundary();

  // consider:
  // redirect user to restart when error found.
  if (didCatch) {
    if (process.env.NODE_ENV !== 'development') {
      return <RedirectToLineupPage />;
    }
  }

  return (
    <>
      <ErrorBoundary>{children}</ErrorBoundary>
    </>
  );
};
