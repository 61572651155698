import React from 'react';
import { isUndefined } from 'lodash';
import { useNavigate } from 'react-router-dom';

import FullScreenLoading from '../../../components/FullScreenLoading';
import RestaurantIsNotActive from '../../../components/RestaurantIsNotActive';

import RestaurantIsActive from './RestaurantIsActive';

import { MenyContext } from '../../../contexts';

export default () => {
  const navigate = useNavigate();
  let [is_loading, setIsLoading] = React.useState(true);
  const { rest_id, menu_id, meny_client_state_machine, restaurant_config } = React.useContext(MenyContext);
  let [is_restaurant_active, setIsRestaurantActive] = React.useState(true);

  React.useEffect(() => {
    setIsLoading(false);
  }, []);

  React.useEffect(() => {
    setIsRestaurantActive(isUndefined(restaurant_config.restaurant_active) || restaurant_config.restaurant_active);
  }, [restaurant_config]);

  if (is_loading) return <FullScreenLoading />;

  if (is_restaurant_active) return <RestaurantIsActive />;

  return <RestaurantIsNotActive />;
};
