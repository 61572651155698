import db from 'src/Firebase/db';
import db_config from 'src/configs/db_config';

let SEAT_WAITING = 'SEAT_WAITING';
let SEAT_ASSIGNED = 'SEAT_ASSIGNED';
let SEAT_LINEUP_CANCELLED_AS_SHOP_CLOSED = 'SEAT_LINEUP_CANCELLED_AS_SHOP_CLOSED';

export { SEAT_ASSIGNED, SEAT_WAITING, SEAT_LINEUP_CANCELLED_AS_SHOP_CLOSED };

function assignTableByDocId(rest_id, doc_id, payload) {
  let DocRef = db.collection(db_config.DB_TABLE_LINEUP).doc(rest_id).collection('lineup').doc(doc_id);

  return db
    .runTransaction((transaction) => {
      return transaction.get(DocRef).then(() => {
        transaction.set(DocRef, payload, { merge: true });
      });
    })
    .then(() => {
      console.log('Transaction successfully committed!');
    })
    .catch((error) => {
      console.log('Transaction failed: ', error);
    });
}

function getCurrentLineup(rest_id, doc_id) {
  return db.collection(db_config.DB_TABLE_LINEUP).doc(rest_id).collection('lineup').doc(doc_id);
}

export { assignTableByDocId, getCurrentLineup };
