import React from 'react';
import { Box, Grid } from '@material-ui/core';
import up_arrow_svg from './up_arrow.svg';

export default function UpArrow() {
  return (
    <Box
      style={{
        marginTop: '0.5rem',
        marginBottom: '0.5rem',
        height: '10px',
        width: '100%',
        backgroundImage: `url(${up_arrow_svg})`,
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      }}
    ></Box>
  );
}
