import axios from 'axios';
import config from 'src/config';

export default function createEmptybill({
  rest_id,
  lineup_id,
  order_table_number,
  order_username,
  num_of_adult,
  num_of_child,
}) {
  return axios.post(`${config.REST_MNGT_ENDPOINT}/createEmptybill`, {
    rest_id,
    lineup_id,
    order_table_number,
    order_username,
    num_of_adult,
    num_of_child,
  });
}
