import React from 'react';

import { monitorRestaurantConfigChange } from 'src/modals/restaurant_config';

import { MenyContext } from '..';

let LOG_PREFIX = 'contexts/RestaurantConfig';

export default () => {
  let { rest_id, menu_id, setRestaurantConfig, setIsLoadingRestaurantConfig, restaurant_config } =
    React.useContext(MenyContext);

  React.useEffect(() => {
    setIsLoadingRestaurantConfig(!restaurant_config);
  }, [restaurant_config]);

  React.useEffect(() => {
    let unsub;
    if (rest_id) {
      console.log(LOG_PREFIX, 'start monitoring restaurant_config');
      unsub = monitorRestaurantConfigChange(rest_id, (doc) => {
        let data = doc.data();
        console.log(LOG_PREFIX, 'restaurant_config changed', data);

        setRestaurantConfig({ ...data });
      });
    }

    return () => {
      console.log(LOG_PREFIX, 'stop monitoring restaurant_config');
      if (unsub) unsub();
    };
  }, [rest_id]);

  return <></>;
};
