import React from 'react';

import { MenyContext } from '..';

let LOG_PREFIX = 'RestaurantAssets';

const blobToData = (blob) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
};

export function RestaurantAssets() {
  let {
    rest_id,
    menu_id,
    restaurant_config,
    restaurant_profile_pic_base64,
    setRestaurantProfilePicBase64,
    setIsLoadingRestaurantAssets,
  } = React.useContext(MenyContext);

  React.useEffect(() => {
    if (restaurant_profile_pic_base64) {
      setIsLoadingRestaurantAssets(false);
    }
  }, [restaurant_profile_pic_base64]);

  React.useEffect(() => {
    if (restaurant_config?.restaurant_profile_pic) {
      console.log(LOG_PREFIX, 'pre-fetch restaurant_profile_pic');
      fetch(restaurant_config.restaurant_profile_pic)
        .then((res) => res.blob())
        .then((res_blob) => blobToData(res_blob))
        .then((base64) => {
          setRestaurantProfilePicBase64(base64);
        });
    } else {
    }
  }, [restaurant_config]);

  return <></>;
}
