import { createMachine } from 'xstate';

import { NOT_MONITORING } from 'src/StateMachines/STATES';

const MonitorOrderStatusMachine = createMachine({
  id: 'monitor_order_status',
  initial: NOT_MONITORING,
  context: {
    lineup_id: null,
    rest_id: null,
    menu_id: null,
    order_history_id: null,
  },
  states: {
    NOT_MONITORING: {
      on: {
        // sendMonitorOrderStatus({
        //   type: 'MONITORING',
        //   lineup_id,
        //   rest_id,
        //   menu_id,
        // });
        MONITORING: {
          target: 'MONITORING',
          actions: [
            (context, event) => {
              // alert('another world1');
              context.lineup_id = event.lineup_id;
              context.rest_id = event.rest_id;
              context.menu_id = event.menu_id;
              context.order_history_id = event.order_history_id;
              // console.log(`monitor_order_status`, event);
            },
          ],
        },
      },
    },
    MONITORING: {
      on: {
        // sendMonitorOrderStatus({ type: NOT_MONITORING });
        NOT_MONITORING: {
          target: 'NOT_MONITORING',
          actions: [
            (context, event) => {
              context.lineup_id = null;
              // test fix for MENY-374,
              // context.rest_id = null;
              context.menu_id = null;
              context.order_history_id = null;
              // alert('another world');
              // console.log(`monitor_order_status`, event);
            },
          ],
        },
      },
    },
  },
});

export { MonitorOrderStatusMachine };
