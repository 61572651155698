import React from 'react';

import { Box } from '@material-ui/core';

import { useStyles } from './styles';
import { MenyContext } from '../../contexts';

export default ({ ads_info }) => {
  let classes = useStyles();
  let { rest_id, increaseAdsImpressions } = React.useContext(MenyContext);

  let { image_urls } = ads_info;

  React.useEffect(() => {
    increaseAdsImpressions({ rest_id });
  }, []);

  return (
    <>
      <Box
        className={classes.root}
        style={{
          // don't move for iphone styling
          background: 'rgba( 255, 255, 255, 0.50 )',
          backgroundImage: `url(${image_urls[0]})`,

          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',

          backdropFilter: 'blur( 10px )',
        }}
      >
        <Box
          id="order-list-ads"
          className={classes.order_list_ad}
          style={{ backgroundImage: `url(${image_urls[0]})` }}
        ></Box>
      </Box>
    </>
  );
};
