import React from 'react';

import { Box, IconButton } from '@material-ui/core';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';

// import { PreCacheContext } from 'src/contexts/PreCacheContext';
import { MenyContext } from 'src/MenyApp/contexts';

function BackButton({ onClick }) {
  return (
    <Box>
      <IconButton onClick={onClick}>
        <ArrowBackIcon />
      </IconButton>
    </Box>
  );
}

export default ({ show_back_button, handleBackOnClick }) => {
  let { rest_id, menu_id, restaurant_config, restaurant_profile_pic_base64 } = React.useContext(MenyContext);

  return (
    <Box
      style={{
        display: 'flex',
        flexFlow: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '200px',
        width: '100%',
      }}
    >
      <Box style={{ width: '10vw' }}>{show_back_button ? <BackButton onClick={handleBackOnClick} /> : <Box></Box>}</Box>
      <Box
        id="restaurant-brand-plate"
        style={{
          backgroundImage: `url('${restaurant_profile_pic_base64}')`,
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          height: '100%',
          width: '100%',
          maxWidth: '50vw',
        }}
      ></Box>

      <Box style={{ width: '10vw' }}></Box>
    </Box>
  );
};
