import React from 'react';

export default function LineupSharedVar() {
  let [Lineup_to_monitor1, setLineupToMonitor1] = React.useState(null);

  let [reservation_number, setReservationNumber] = React.useState(null);

  return {
    Lineup_to_monitor1,
    setLineupToMonitor1,

    reservation_number,
    setReservationNumber,
  };
}
