import React from 'react';
import { Box } from '@material-ui/core';

import { LYNKED_GREEN, LYNKED_DEEP_GREY } from 'src/consts/colors';
import { MenyContext } from '../../contexts';
import { useTranslation } from 'react-i18next';

export default ({ ticket_number, show_table_grey = false }) => {
  // form_info.current_ticket
  const { meny_client_state_machine } = React.useContext(MenyContext);
  let [client_state] = meny_client_state_machine;
  let { form_info } = client_state.context;
  const { t } = useTranslation();

  return (
    <>
      <Box
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexFlow: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {show_table_grey ? (
          <Box
            style={{
              width: '100%',
              height: '24px',

              backgroundColor: LYNKED_DEEP_GREY,

              display: 'flex',
              flexFlow: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          ></Box>
        ) : (
          <></>
        )}
        <Box
          style={{
            position: 'absolute',
            width: '21%',

            height: '24px',

            backgroundColor: LYNKED_GREEN,
            borderRadius: '12px 0px 0px 12px',

            display: 'flex',
            flexFlow: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box>{t('MENU_RECEIPT_NUMBER')} {form_info && form_info.current_ticket}</Box>
        </Box>
      </Box>
    </>
  );
};
