import { useMachine } from '@xstate/react';

import { toggleMachine } from '../../StateMachines/toggleMachine';
import { MenyClientStateMachine } from '../../StateMachines/MenyClientStateMachine';
import { MonitorOrderStatusMachine } from '../../StateMachines/MonitorOrderStatusMachine';
import { MonitorTableStatusMachine } from '../../StateMachines/MonitorTableStatusMachine';
import { MonitorRestaurantConfigMachine } from '../../StateMachines/MonitorRestaurantConfigMachine';

let LOG_PREFIX = 'StateMachines/shared_var.js';

const setLocalStorage = (key, value) => localStorage.setItem(key, JSON.stringify(value));
const getLocalStorage = (key) => JSON.parse(localStorage.getItem(key));
const clearLocalStorage = (key) => localStorage.removeItem(key);

export default function StateMachineSharedVar({ rest_id }) {
  const toggleMachine1 = useMachine(toggleMachine);
  const toggleMachine2 = useMachine(toggleMachine);

  const setLocalClientState = (client_state) => setLocalStorage(`${rest_id}/client_state`, client_state);
  const getLocalClientState = () => getLocalStorage(`${rest_id}/client_state`);
  const clearLocalClientState = () => clearLocalStorage(`${rest_id}/client_state`);

  const meny_client_state_machine = useMachine(MenyClientStateMachine, {
    state: getLocalClientState() || MenyClientStateMachine.initialState,
  });

  const monitor_order_status_machine = useMachine(MonitorOrderStatusMachine, {
    state: MonitorOrderStatusMachine.initialState,
  });

  const monitor_table_status_machine = useMachine(MonitorTableStatusMachine, {
    state: MonitorTableStatusMachine.initialState,
  });

  const monitor_restaurant_config_machine = useMachine(MonitorRestaurantConfigMachine, {
    state: MonitorTableStatusMachine.initialState,
  });

  return {
    toggleMachine1,
    toggleMachine2,

    meny_client_state_machine,
    monitor_order_status_machine,
    monitor_table_status_machine,

    monitor_restaurant_config_machine,

    setLocalClientState,
    clearLocalClientState,
    getLocalClientState,
  };
}
