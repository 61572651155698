import { useMutation } from 'react-query';
import { subscribeRestaurantConfig } from 'src/modals/restaurant_config';

export default function useMutationSubscribeRestaurantConfig() {
  const mutation = useMutation(
    (rest_id) => {
      return subscribeRestaurantConfig(rest_id);
    },
    {
      retry: 10,
    }
  );

  return mutation;
}
