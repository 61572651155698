import React from 'react';

import OrderItem from './OrderItem';

import OrderListEmpty from './OrderListEmpty';

export default function ListOrderItem({
  cart_items,
  setCartItems,
  getOrderTotal,
  updateNumOfFoodItemCount,
  restaurant_config,
}) {
  if (cart_items && cart_items.length > 0) {
    return (
      <>
        {cart_items.map((cart_item, idx) => {
          return (
            <OrderItem
              key={idx}
              item_idx={idx}
              cart_item={cart_item}
              cart_items={cart_items}
              setCartItems={setCartItems}
              getOrderTotal={getOrderTotal}
              updateNumOfFoodItemCount={updateNumOfFoodItemCount}
              restaurant_config={restaurant_config}
            />
          );
        })}
      </>
    );
  }

  return <OrderListEmpty />;
}
