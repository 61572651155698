import React from 'react';

import { Box } from '@material-ui/core';

import active_lang from 'src/langs/jp_en';
import { useTranslation } from 'react-i18next';

export default function OrderListEmpty() {
  const { t } = useTranslation();

  return (
    <>
      <Box style={{ width: '100%', textAlign: 'center', marginTop: '2rem' }}>{t('CART_NO_PRODUCTS_MSG')}</Box>
    </>
  );
}
