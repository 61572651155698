import React from 'react';
import Box from '@material-ui/core/Box';

import { useStyles } from './styles';
import { MenyLightContext } from 'src/MenyLightApp/contexts';

const LOG_PREFIX = 'BottomAds';

export default ({ bottom_ads_config }) => {
  let classes = useStyles();
  const { rest_id, increaseAdsImpressions } = React.useContext(MenyLightContext);

  let bottom_ads_img_url = bottom_ads_config.data.image_urls[0];

  console.log(LOG_PREFIX, { bottom_ads_img_url });

  React.useEffect(() => {
    console.log(LOG_PREFIX, 'increaseAdsImpressions', { rest_id });
    increaseAdsImpressions({ rest_id });
  }, []);

  return (
    <>
      <Box className={classes.root} style={{ backgroundImage: `url(${bottom_ads_img_url})` }}>
        <Box className={classes.food_menu_ad} style={{ backgroundImage: `url(${bottom_ads_img_url})` }}></Box>
      </Box>
    </>
  );
};
