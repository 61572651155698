import React from 'react';
import { Grid } from '@material-ui/core';

import testValidItemCustomizeSetting from '../../utils/testValidItemCustomizeSetting.js';

import CustomizeButton from './CustomizeButton';

import active_lang from 'src/langs/jp_en';

export default ({
  food_entry,
  disable_add_to_cart_button,
  list_customize_options,
  setListCustomizeOptions,
  restaurant_config,
}) => {
  const toggleEnableCustomizationOption = (option_idx) => {
    let temp = list_customize_options.a;
    temp[option_idx].enabled = !temp[option_idx].enabled;
    console.log('toggleEnableCustomizationOption', 'temp', temp);
    setListCustomizeOptions({ a: temp });
  };

  React.useEffect(() => {
    if (testValidItemCustomizeSetting(food_entry.customize_group_settings)) {
      let temp = food_entry.customize_group_settings.map((x) => {
        return { ...x, enabled: false };
      });
      setListCustomizeOptions({ a: temp });
    } else {
      setListCustomizeOptions({ a: [] });
    }
  }, [food_entry]);

  return (
    <>
      <Grid container spacing={1} style={{ padding: '0.5rem' }}>
        {list_customize_options.a.map((option, idx) => (
          <Grid item xs={4}>
            <CustomizeButton
              disabled={food_entry.sold_out === active_lang.STATUS_SOLD_OUT ? true : false}
              key={idx}
              name={option.name}
              price={option.price}
              option_enabled={option.enabled}
              customize_idx={0}
              handleOnClick={() => toggleEnableCustomizationOption(idx)}
              restaurant_config={restaurant_config}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};
