import React from 'react';

import { Box, IconButton } from '@material-ui/core';
import RemoveIcon from '@material-ui/icons/Remove';

export default function RemoveChildSeatButton({ onClick }) {
  return (
    <>
      <Box style={{ width: '25%' }}>
        <Box
          style={{
            borderRadius: '26px',
            border: '1px solid #707070',
            width: '39px',
            height: '39px',

            display: 'flex',
            flexFlow: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IconButton onClick={onClick}>
            <RemoveIcon />
          </IconButton>
        </Box>
      </Box>
    </>
  );
}
