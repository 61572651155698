import React from 'react';
import { Box, Button } from '@material-ui/core';
import LynkedLogoSvg from 'src/components/LynkedLogoSvg';

export default function ListEnd() {
  const handleOnClick = () => {
    window.location.href = ' http://tabilife.co.jp';
  };

  return (
    <>
      <Button style={{ textDecoration: 'unset', width: '100%' }} onClick={handleOnClick}>
        <Box style={{ width: '100%' }} mt="0.5rem" mb="0.5rem">
          <Box
            style={{
              display: 'flex',
              flexFlow: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: '40px',
            }}
          >
            <Box
              style={{
                height: '30px',
                display: 'flex',
                flexFlow: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                marginRight: '0.25rem',
              }}
            >
              Powered by
            </Box>

            <Box id="bottom-lynked-logo" style={{ width: '100px', height: '20px' }}>
              <LynkedLogoSvg />
            </Box>
          </Box>
        </Box>
      </Button>
    </>
  );
}
