import { useMutation } from 'react-query';

import { addServiceRequest } from 'src/modals/service_request';

const useMutationAddServiceRequest = () => {
  return useMutation(
    ({ rest_id, table_name, username, order_history_id }) => {
      console.log(
        'useMutationAddServiceRequest',
        rest_id,
        table_name,
        username
      );
      return addServiceRequest(
        rest_id,
        table_name,
        username,
        order_history_id
      ).then((doc_id) => doc_id);
    },
    { retry: 5 }
  );
};

export default useMutationAddServiceRequest;
