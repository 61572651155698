import React from 'react';
import { isEqual } from 'lodash';
import { updateMenyOrder } from 'src/modals/order';

const LOG_PREFIX = 'OrderList/shared_var';

let init_order_history = {};

export default function OrderListSharedVar({ rest_id }) {
  let STORE_PATH = `${rest_id}/cart_items`;

  let [orderlist_to_monitor1, setOrderListToMonitor1] = React.useState(null);
  const [cart_items, setCartItems] = React.useState([]);
  const [num_of_cart_items, setNumOfCartItems] = React.useState(0);
  const [order_history, setOrderHistory] = React.useState(init_order_history);

  const clearCartItems = () => localStorage.removeItem(STORE_PATH);
  const loadCartItems = () => JSON.parse(localStorage.getItem(STORE_PATH));
  const storeCartItems = (cart_items) => {
    return localStorage.setItem(STORE_PATH, JSON.stringify(cart_items));
  };

  const resetCartItems = () => {
    setCartItems([]);
    setNumOfCartItems(0);
    clearCartItems();
  };

  const updateNumOfFoodItemCount = (item_list) => {
    let temp_quantity = 0;

    // consider:
    // item_list is undefined when local_storage is empty
    item_list && item_list.forEach((item) => (temp_quantity = temp_quantity + item.item_quantity));

    setNumOfCartItems(temp_quantity || 0);
    console.log(LOG_PREFIX, 'updateNumOfFoodItemCount', 'temp_quantity', temp_quantity);
  };

  const handleUpdateCartItems = (new_cart_items) => {
    setCartItems(new_cart_items);
    updateNumOfFoodItemCount(new_cart_items);
    // handleCookieSetCartItems(new_cart_items);
  };

  const isIfSameFoodIdAndCustomizeSettings = (item_to_test1, item_to_test2) => {
    if (item_to_test1.item_food_id === item_to_test2.item_food_id) {
      console.log(LOG_PREFIX, 'isIfSameFoodIdAndCustomizeSettings', 'food_id same');
      if (isEqual(item_to_test1.item_customize_settings, item_to_test2.item_customize_settings)) {
        console.log(LOG_PREFIX, 'isIfSameFoodIdAndCustomizeSettings', 'item_customize_settings same');
        return true;
      }
    }
    return false;
  };

  const isFoodItemAlreadyInCart = (food_item_to_check) => {
    for (let i = 0; i < cart_items.length; i++) {
      if (isIfSameFoodIdAndCustomizeSettings(cart_items[i], food_item_to_check)) {
        console.log(LOG_PREFIX, 'isFoodItemAlreadyInCart', 'found');
        return true;
      }
    }
    console.log('isFoodItemAlreadyInCart', 'not found');
    return false;
  };

  const AddFoodItemToCart = (food_item_to_add) => {
    // 2 considerations:
    // adding alredy exist item, add the number of item only
    // add non-exist item, add the item to cart
    //
    let new_cart_items = cart_items;
    let food_ids = cart_items.map((x) => x.item_food_id);
    console.log(LOG_PREFIX, 'food_ids', food_ids);
    console.log(LOG_PREFIX, 'food_ids.includes', food_ids.includes(food_item_to_add.item_food_id));
    console.log(LOG_PREFIX, 'cart_items', cart_items);
    if (isFoodItemAlreadyInCart(food_item_to_add)) {
      for (let i = 0; i < new_cart_items.length; i++) {
        if (isIfSameFoodIdAndCustomizeSettings(new_cart_items[i], food_item_to_add)) {
          new_cart_items[i].item_quantity = new_cart_items[i].item_quantity + food_item_to_add.item_quantity;
        }
      }
      handleUpdateCartItems(new_cart_items);
      // handleCookieSetCartItems(new_cart_items);
    } else {
      let item_to_add = [...cart_items, food_item_to_add];
      handleUpdateCartItems(item_to_add);
      //   // setCookie(config.CART_ITEMS, item_to_add, config.DEFAULT_COOKIE_OPTIONS);
      //   handleCookieSetCartItems(item_to_add);
    }
  };

  const handleUpdateMenyOrder = (rest_id, order_history_id, cart_items) => {
    console.log(LOG_PREFIX, 'handleUpdateMenyOrder', { rest_id, order_history_id, cart_items });
    let current_order_history = order_history;
    let current_order_list = current_order_history?.orders || [];
    let new_order_list = [...current_order_list, ...cart_items];
    let temp_order_history = { ...current_order_history, orders: new_order_list };

    return updateMenyOrder(rest_id, order_history_id, temp_order_history);
  };

  return {
    orderlist_to_monitor1,
    setOrderListToMonitor1,

    AddFoodItemToCart,
    cart_items,
    setCartItems,
    num_of_cart_items,
    setNumOfCartItems,
    updateNumOfFoodItemCount,
    handleUpdateMenyOrder,
    order_history,
    setOrderHistory,
    resetCartItems,

    clearCartItems,
    loadCartItems,
    storeCartItems,
    handleUpdateCartItems,
  };
}
