import React from 'react';

import useMutationGetFoodMenuAds from 'src/hooks/useMutationGetFoodMenuAds';
import useMutationGetFoodDetailAds from 'src/hooks/useMutationGetFoodDetailAds';
import useMutationGetRestaurantAds from 'src/hooks/useMutationGetRestaurantAds';

import ShowDebug from 'src/components/ShowDebug';

import { MenyLightContext } from '..';

let LOG_PREFIX = 'AdvertisementContext';

function readFileAsync(file) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();

    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}

export function AdvertisementContext() {
  let {
    rest_id,
    advertisement_config,
    setAdvertisementConfig,
    cached_food_menu_ads,
    setCachedFoodMenuAds,
    setIsLoadingAdvertisement,
  } = React.useContext(MenyLightContext);

  let mutateFoodMenuAds = useMutationGetFoodMenuAds();
  let mutateFoodDetailAds = useMutationGetFoodDetailAds();
  let mutationGetRestaurantAds = useMutationGetRestaurantAds();

  const insertIntoFoodMenuAdsCache = (key, value) => setCachedFoodMenuAds({ ...cached_food_menu_ads, [key]: value });

  React.useEffect(() => {
    mutationGetRestaurantAds
      .mutateAsync(rest_id)
      .then(async (res) => {
        for (let i = 0; i < res.length; i++) {
          let ad_img_url = res[i].data.image_urls[0];

          let img_base64 = await fetch(ad_img_url)
            .then((res) => res.blob())
            .then((res_blob) => readFileAsync(res_blob));

          console.log(LOG_PREFIX, 'res[i]', res[i]);
          res[i].data.image_urls[0] = img_base64;
        }

        return res;
      })

      .then((res) => {
        console.log(LOG_PREFIX, 'res', res);
        setAdvertisementConfig(res || []);
      })
      .finally(() => setIsLoadingAdvertisement(false));
  }, []);

  return (
    <>
      <ShowDebug>AdvertisementContxt</ShowDebug>
    </>
  );
}
