import React from 'react';

import { MenyContext } from '..';
import ReservationNumberAssignedDialog from '../../components/Dialogs/ReservationNumberAssignedDialog';
import TableAvailableDialog from '../../components/Dialogs/TableAvailableDialog';
import FoodAddToCartSign from '../../components/Dialogs/FoodAddToCartSign';
import SorryRestaurantClosedSign from '../../components/Dialogs/SorryRestaurantClosedSign';
import SorryRestaurantClosedSignAndRestartFlow from '../../components/Dialogs/SorryRestaurantClosedSignAndRestartFlow';

import FoodDetailDialog from '../../components/FoodDetailDialog';
import ShowDebug from 'src/components/ShowDebug';

export function Dialogs() {
  let {
    open_reservation_number_assigned_dialog,
    setOpenReservationNumberAssignedDialog,
    open_table_available_dialog,
    setOpenTableAvailableDialog,
    open_food_add_to_cart_sign,
    setOpenFoodAddToCartSign,
    open_food_detail_dialog,
    setOpenFoodDetailDialog,
    food_entry,
    setFoodEntry,
    open_restaurant_closed_sign,
    setOpenRestaurantClosedSign,

    open_restaurant_closed_sign_and_restart_flow,
    setOpenRestaurantClosedSignAndRestartFlow,
  } = React.useContext(MenyContext);

  return (
    <>
      {/* <div style={{ position: 'absolute', zIndex: 9999, top: 0 }}>
        <ShowDebug>{JSON.stringify({ open_food_detail_dialog })}</ShowDebug>
      </div> */}
      <ReservationNumberAssignedDialog
        open={open_reservation_number_assigned_dialog}
        setOpen={setOpenReservationNumberAssignedDialog}
      />

      <TableAvailableDialog open={open_table_available_dialog} setOpen={setOpenTableAvailableDialog} />
      <FoodAddToCartSign open={open_food_add_to_cart_sign} setOpen={setOpenFoodAddToCartSign} />
      <SorryRestaurantClosedSign open={open_restaurant_closed_sign} setOpen={setOpenRestaurantClosedSign} />
      <SorryRestaurantClosedSignAndRestartFlow
        open={open_restaurant_closed_sign_and_restart_flow}
        setOpen={setOpenRestaurantClosedSignAndRestartFlow}
      />

      <FoodDetailDialog
        open={open_food_detail_dialog}
        setOpen={setOpenFoodDetailDialog}
        food_entry={food_entry}
        food_menu_view_only={false}
      />
    </>
  );
}
