import { useMutation } from 'react-query';

import { addLineupRequest } from 'src/modals/table';

const useMutationAddIntoLineupTable = () => {
  return useMutation(({ rest_id, form_info, current_ticket }) => {
    console.log('useMutationAddIntoLineupTable', rest_id, form_info, current_ticket);
    return addLineupRequest({ rest_id, form_info }).then((docRef) => {
      return docRef.id;
    });
  });
};

export default useMutationAddIntoLineupTable;
