import { useMutation } from 'react-query';

import config from 'src/config';

const LOG_PREFIX = 'useMutationGetRestaurantAds';

let { REST_MNGT_ENDPOINT } = config;

const useMutationGetRestaurantAds = () =>
  useMutation('use_mutation_get_restaurant_ads', async (rest_id) => {
    console.log(LOG_PREFIX, `${REST_MNGT_ENDPOINT}/getRestaurantAds?rest_id=${rest_id}`);
    const response = await fetch(`${REST_MNGT_ENDPOINT}/getRestaurantAds?rest_id=${rest_id}`);

    if (!response.ok) console.error(LOG_PREFIX, 'error during getting ads', { rest_id });

    return response.json();
  });

export default useMutationGetRestaurantAds;
