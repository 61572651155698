import React from 'react';
import ScrollMenu from 'react-horizontal-scrolling-menu';

import {ReactComponent as ChevronLeftIcon} from 'src/assets/icons/left-double-arrow.svg'
import {ReactComponent as ChevronRightIcon} from 'src/assets/icons/right-double-arrow.svg'

import { getTagIdFromTagName } from 'src/utils/getTagIdFromTagName';
import { STATUS_FOOD_SHOW } from 'src/modals/food_details';

import Menu from './Menu';

import './horizontal-scrollbar.css';
import { isDefined } from 'src/utils/lodash-cust';

const ArrowLeft = <ChevronLeftIcon />;
const ArrowRight = <ChevronRightIcon />;

export default function HorizontalScrollBar({ list_ref, food_menu, category_selected, setCategorySelected }) {
  let categories = food_menu.columnOrder
    .filter((co) => {
      if (isDefined(food_menu.columns[co].category_show)) {
        return food_menu.columns[co].category_show === STATUS_FOOD_SHOW;
      } else {
        return true;
      }
    })
    .map((co) => {
      return {
        name: food_menu.columns[co].title,
        id: co,
        html_id: getTagIdFromTagName(co),
        category_show: food_menu.columns[co].category_show,
      };
    });

  const menu = Menu(list_ref, categories, category_selected);

  const onSelect = (key) => {
    console.log('onSelect', key);
    setCategorySelected(key);
  };

  return (
    <>
      <ScrollMenu
        data={menu}
        arrowLeft={ArrowLeft}
        arrowRight={ArrowRight}
        selected={category_selected}
        onSelect={onSelect}
        scrollToSelected={true}
        list_ref={list_ref}
      />
    </>
  );
}
