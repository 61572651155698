import React from 'react';
import { Box, Button, Grid, IconButton, Input } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { LYNKED_DEEP_GREY, LYNKED_GREEN, LYNKED_WHITE } from 'src/consts/colors';
import ShowYen from 'src/components/ShowYen';

import { MenyContext } from '../../contexts';
import getItemSubtotal from '../../utils/getItemSubtotal';
import { STATUS_SOLD_OUT } from 'src/consts/SoldOutStatus';
import isRestaurantClosed from '../../utils/isRestaurantClosed';

import active_lang from 'src/langs/jp_en';
import { isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';

const LOG_PREFIX = 'DialogBody';
const MAX_ITEM_TO_ORDER = 99;

export default ({ food_entry, handleClose, list_customize_options }) => {
  let { restaurant_config, AddFoodItemToCart, cart_items } = React.useContext(MenyContext);
  const { t } = useTranslation();

  let [disable_add_to_cart_button, setDisableAddToCartButton] = React.useState(true);
  let [item_subtotal, setItemSubtotal] = React.useState(0);

  let [item, setItem] = React.useState({
    quantity: 1,
    subtotal: 1 * food_entry.unit_price,
    unit_price: food_entry.unit_price,
  });

  const getEnabledCustomizeOption = () => {
    console.log(LOG_PREFIX, 'getEnabledCustomizeOption', 'food_entry', food_entry);
    return list_customize_options.a.filter((option) => option.enabled === true);
  };

  const handleAddFoodItemToCart = () => {
    let adjust_by_customization = 0;
    let enabled_customization = getEnabledCustomizeOption();

    enabled_customization.forEach((x) => {
      adjust_by_customization = Number(adjust_by_customization) + Number(x.price);
    });

    let item_subtotal = getItemSubtotal(item.quantity, item.unit_price, adjust_by_customization);


    let food_item_to_add = {
      item_name: food_entry.name,
      item_food_id: food_entry.id,
      item_subtotal: Math.max(0, item_subtotal),
      item_price: item.unit_price,
      item_quantity: item.quantity,
      item_customize_settings: enabled_customization,
    };
    if (cart_items.length) {
      const isExists = cart_items.map((item) => item.item_food_id).includes(food_entry.id);

      if (!isExists) {
        AddFoodItemToCart(food_item_to_add);
        handleClose();
        return;
      }

      if (!parseInt(food_entry.foodStockCount)) {
        AddFoodItemToCart(food_item_to_add);
        handleClose();
        return;
      }

      const existedItem = cart_items.find(item => item.item_food_id === food_entry.id && isEqual(item.item_customize_settings, food_item_to_add.item_customize_settings));

      if (existedItem) {
        if (existedItem.item_quantity + Number(food_item_to_add.item_quantity) <= parseInt(food_entry.foodStockCount)) {
          AddFoodItemToCart(food_item_to_add);
          handleClose();
          return;
        }

        AddFoodItemToCart({
          ...food_item_to_add,
          item_quantity: parseInt(food_entry.foodStockCount) - existedItem.item_quantity,
          item_subtotal:
            (parseInt(food_entry.foodStockCount) - existedItem.item_quantity) * Number(food_entry.unit_price),
        });
        handleClose();
        return;
      }

      AddFoodItemToCart(food_item_to_add);
    } else {
      AddFoodItemToCart(food_item_to_add);
    }
    handleClose();
  };

  const handleTryAddFoodItemToCart = () => {
    handleAddFoodItemToCart();
  };

  React.useEffect(() => {
    if (food_entry.sold_out === STATUS_SOLD_OUT || isRestaurantClosed(restaurant_config)) {
      console.log(LOG_PREFIX, 'food not available');
      setDisableAddToCartButton(true);
    } else {
      console.log(LOG_PREFIX, 'food available');
      setDisableAddToCartButton(false);
    }
  }, [food_entry, restaurant_config]);

  const handleAddButtonTap = () => {
    let quantity = Math.min(item.quantity + 1, MAX_ITEM_TO_ORDER);
    let subtotal = quantity * Number(item.unit_price);
    setItem({ ...item, quantity, subtotal });
  };
  const handleDelButtonTap = () => {
    let quantity = Math.max(item.quantity - 1, 1);
    let subtotal = quantity * item.unit_price;

    setItem({ ...item, quantity, subtotal });
  };
  const handleQuantityChange = (e) => {
    try {
      if (food_entry.isEnableStockCount) {
        if (!e.target.value) {
          setItem({ ...item, quantity: 1 });
        } else if (parseInt(e.target.value) <= parseInt(food_entry.foodStockCount)) {
          setItem({ ...item, quantity: parseInt(e.target.value) });
        } else {
          setItem({ ...item, quantity: parseInt(food_entry.foodStockCount) });
        }
      } else {
        setItem({ ...item, quantity: parseInt(e.target.value) });
      }
    } catch (error) {
      console.log(LOG_PREFIX, 'user input error value, skipping');
    }
  };

  React.useEffect(() => {
    let adjust_by_customization = 0;
    let enabled_customization = getEnabledCustomizeOption();

    enabled_customization.forEach((x) => {
      adjust_by_customization = Number(adjust_by_customization) + Number(x.price);
    });

    setItemSubtotal(getItemSubtotal(item.quantity, item.unit_price, adjust_by_customization));
  }, [list_customize_options, item]);

  return (
    <>
      <Grid container>
        <Grid item xs={6} container>
          <Grid item xs={2}></Grid>
          <Grid
            item
            xs={3}
            style={{ display: 'flex', flexFlow: 'column', justifyContent: 'center', alignItems: 'center' }}
          >
            <Box
              style={{
                borderRadius: '26px',
                border: '1px solid #707070',
                width: '39px',
                height: '39px',

                display: 'flex',
                flexFlow: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <IconButton
                id={'remove_item'}
                disabled={disable_add_to_cart_button || parseInt(item.quantity) === 1}
                onClick={handleDelButtonTap}
              >
                <RemoveIcon />
              </IconButton>
            </Box>
          </Grid>
          <Grid
            item
            xs={2}
            style={{
              display: 'flex',
              flexFlow: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box style={{ textAlign: 'center' }}>
              <Input
                disabled={
                  disable_add_to_cart_button ||
                  ((parseInt(item.quantity) > parseInt(food_entry.foodStockCount) ||
                    food_entry.sold_out === STATUS_SOLD_OUT) &&
                    food_entry.isEnableStockCount)
                }
                type="number"
                disableUnderline={true}
                // defaultValue="1"
                value={item.quantity || 1}
                onInput={handleQuantityChange}
                inputProps={{ 'aria-label': 'description', style: { textAlign: 'center' } }}
              />
            </Box>
          </Grid>
          <Grid
            item
            xs={3}
            style={{
              display: 'flex',
              flexFlow: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box
              style={{
                borderRadius: '26px',
                border: '1px solid #707070',
                width: '39px',
                height: '39px',

                display: 'flex',
                flexFlow: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <IconButton
                id={'add_item'}
                disabled={
                  (parseInt(item.quantity) === parseInt(food_entry.foodStockCount) ||
                    food_entry.sold_out === STATUS_SOLD_OUT) &&
                  food_entry.isEnableStockCount
                }
                onClick={handleAddButtonTap}
              >
                <AddIcon />
              </IconButton>
            </Box>
          </Grid>
          <Grid item xs={2}></Grid>
        </Grid>
        <Grid item xs={6}>
          <Button
            id="add-food"
            disabled={disable_add_to_cart_button}
            style={{
              width: '100%',
              height: '100%',

              display: 'flex',
              flexFlow: 'row',
              justifyContent: 'center',
              alignItems: 'center',

              backgroundColor: disable_add_to_cart_button ? LYNKED_DEEP_GREY : LYNKED_GREEN,
              color: LYNKED_WHITE,
              fontSize: '16px',
              lineHeight: '25px',
              borderRadius: '0px',
            }}
            onClick={() => handleTryAddFoodItemToCart()}
          >
            <div>
              <div style={{ display: 'flex', flexFlow: 'row', paddingRight: '0.5rem', paddingLeft: '0.5rem' }}>
                <div style={{ paddingRight: '0.5rem' }}>{t('FOOD_DETAIL_SELECT', {x: item.quantity || 1})}</div>
                <div>
                  <ShowYen value_yen={item_subtotal || food_entry.unit_price} restaurant_config={restaurant_config}></ShowYen>
                </div>
              </div>
            </div>
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
