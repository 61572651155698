import React from 'react';
import CurrencyFormat from 'react-currency-format';

function ShowYen({ value_yen, restaurant_config }) {
  return (
    <>
      <CurrencyFormat
        value={Number(value_yen)}
        displayType={'text'}
        thousandSeparator={true}
        prefix={restaurant_config && restaurant_config.currencySymbol ? restaurant_config.currencySymbol : '¥'}
      />
    </>
  );
}

export default ShowYen;
