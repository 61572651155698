import React from 'react';

import { MenyLightContext } from '../../contexts';
import useMutationFoodMenu from 'src/hooks/useMutationFoodMenu';

export function FoodMenu() {
  let { rest_id, setFoodMenu, setIsLoadingFoodMenu } = React.useContext(MenyLightContext);
  let mutationFoodMenu = useMutationFoodMenu();

  React.useEffect(() => {
    mutationFoodMenu
      .mutateAsync(rest_id)
      .then((res) => setFoodMenu(res))
      .then(() => setIsLoadingFoodMenu(false));
  }, [rest_id]);

  return <></>;
}
