import React from 'react';
import { useParams } from 'react-router';
import { Box, IconButton, Grid } from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/Notifications';

import { BELL_COLOR_GREY, LYNKED_DEEP_GREY } from 'src/consts/colors';
import active_lang from 'src/langs/jp_en';

import ServiceRequestDialog from '../../views/ServiceRequestDialog';
import { MenyLightContext } from '../../contexts';

import { useStyles } from './styles';

export default () => {
  const classes = useStyles();

  let { service_request_not_cleared, restaurant_config } = React.useContext(MenyLightContext);

  let [open_service_request_dialog, setOpenServiceRequestDialog] = React.useState(false);

  return restaurant_config.isEnableBellRing ? (
    <>
      <ServiceRequestDialog open={open_service_request_dialog} setOpen={setOpenServiceRequestDialog} />

      <Box id="service-request-button" className={classes.headbar_content} style={{ alignItems: 'flex-start' }}>
        <Box
          style={{
            display: 'flex',
            flexFlow: 'row',
            justifyContent: 'center',
            alignItems: 'center',

            whiteSpace: 'nowrap',

            fontSize: '10px',
            lineHeight: '18px',

            textAlign: 'center',

            color: LYNKED_DEEP_GREY,
          }}
        >
          <Grid container>
            <Grid item xs={6} style={{ textAlign: 'left' }}>
              <IconButton
                id="create_service_request_button"
                onClick={() => setOpenServiceRequestDialog(true)}
                style={{
                  padding: '0.25rem',
                  color: service_request_not_cleared.state.length > 0 ? 'orange' : BELL_COLOR_GREY,
                }}
              >
                <NotificationsIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  ) : (
    <></>
  );
};
