import React from 'react';

export default ({ rest_id }) => {
  let STORE_PATH = `${rest_id}/assign_table_prompted`;
  let FIRST_TIME_PATH = `${rest_id}/isTheFirstTime`;

  let [dialogs_to_monitor1, setDialogsToMonitor1] = React.useState(null);
  let [open_reservation_number_assigned_dialog, setOpenReservationNumberAssignedDialog] = React.useState(false);
  let [open_table_available_dialog, setOpenTableAvailableDialog] = React.useState(false);
  let [open_food_add_to_cart_sign, setOpenFoodAddToCartSign] = React.useState(false);
  let [open_food_detail_dialog, setOpenFoodDetailDialog] = React.useState(false);

  let [open_restaurant_closed_sign, setOpenRestaurantClosedSign] = React.useState(false);

  let [open_restaurant_closed_sign_and_restart_flow, setOpenRestaurantClosedSignAndRestartFlow] = React.useState(false);

  let [food_entry, setFoodEntry] = React.useState();

  const loadAssignedTablePromptedAlreadyNumber = () => JSON.parse(localStorage.getItem(STORE_PATH));
  const loadIsTheFirstTime = () => JSON.parse(localStorage.getItem(FIRST_TIME_PATH));
  const storeAssignedTablePromptedAlreadyNumber = ({ done }) =>
    localStorage.setItem(STORE_PATH, JSON.stringify({ done }));
  const storeIsTheFirstTime = (value) =>
    localStorage.setItem(FIRST_TIME_PATH, JSON.stringify({ isTheFirstTime: value }));
  const clearAssignedTablePromptedAlready = () => localStorage.removeItem(STORE_PATH);

  const showRestaurantClosedSignAndRedirect = () => {
    // consider:
    // user engaged, user hold on food detail screen , MENY-440
    setOpenFoodDetailDialog(false);

    setOpenRestaurantClosedSignAndRestartFlow(true);
  };

  const closeFoodDetailDialog = () => {
    setOpenFoodDetailDialog(false);
  };

  return {
    dialogs_to_monitor1,
    setDialogsToMonitor1,

    open_reservation_number_assigned_dialog,
    setOpenReservationNumberAssignedDialog,

    open_table_available_dialog,
    setOpenTableAvailableDialog,

    open_food_add_to_cart_sign,
    setOpenFoodAddToCartSign,

    open_food_detail_dialog,
    setOpenFoodDetailDialog,

    food_entry,
    setFoodEntry,

    loadIsTheFirstTime,
    loadAssignedTablePromptedAlreadyNumber,
    storeAssignedTablePromptedAlreadyNumber,
    clearAssignedTablePromptedAlready,
    storeIsTheFirstTime,

    open_restaurant_closed_sign,
    setOpenRestaurantClosedSign,
    open_restaurant_closed_sign_and_restart_flow,
    setOpenRestaurantClosedSignAndRestartFlow,
    showRestaurantClosedSignAndRedirect,
    closeFoodDetailDialog,
  };
};
