const checkLink = (url, criteria) => url.search(criteria) > -1;
const checkNewUserLineupLink = (url) => checkLink(url, /NewUserlineup/);
const checkFoodMenuLink = (url) => checkLink(url, /FoodMenuLandingAds/);
const checkOrderHistoryLink = (url) => checkLink(url, /OrderHistoryAds/);
const checkOrderlistLink = (url) => checkLink(url, /OrderListAds/);

const isUserOnNewUserLineupPage = () => checkNewUserLineupLink(window.location.href);
const isUserOnFoodMenuPage = () => checkFoodMenuLink(window.location.href);
const isUserOnOrderHistoryPage = () => checkOrderHistoryLink(window.location.href);
const isUserOnOrderlistPage = () => checkOrderlistLink(window.location.href);

const LOG_PREFIX = 'getLinks';

function getFoodMenuLandingLink(rest_id, menu_id, with_ads = false) {
  let link = `/meny/${rest_id}/${menu_id}/FoodMenuLandingAds`;
  console.log(LOG_PREFIX, 'getFoodMenuLandingLink', link);
  // console.error('getFoodMenuLandingLink', new Error().stack);
  return link;
}

function getNewUserLineupLink(rest_id, menu_id) {
  let link = `/meny/${rest_id}/${menu_id}/NewUserlineup`;
  console.log(LOG_PREFIX, 'getNewUserLineupLink', link);
  console.log(LOG_PREFIX, 'getNewUserLineupLink', new Error().stack);
  return link;
}

function getOrderListAdsLink(rest_id, menu_id) {
  let link = `/meny/${rest_id}/${menu_id}/OrderListAds`;
  console.log(LOG_PREFIX, 'getOrderListAdsLink', link);
  // console.log(LOG_PREFIX, 'getOrderListAdsLink', new Error().stack);
  return link;
}

function getOrderHistoryAdsLink(rest_id, menu_id) {
  let link = `/meny/${rest_id}/${menu_id}/OrderHistoryAds`;
  console.log(LOG_PREFIX, 'getOrderHistoryAdsLink', link);
  // console.log(LOG_PREFIX, 'getOrderHistoryAdsLink', new Error().stack);
  return link;
}

export {
  checkFoodMenuLink,
  checkNewUserLineupLink,
  checkOrderHistoryLink,
  checkOrderlistLink,
  getFoodMenuLandingLink,
  getNewUserLineupLink,
  getOrderHistoryAdsLink,
  getOrderListAdsLink,
  isUserOnFoodMenuPage,
  isUserOnNewUserLineupPage,
  isUserOnOrderHistoryPage,
  isUserOnOrderlistPage,
};
