import React from 'react';

import { Box } from '@material-ui/core';

import TapNoAction from './TapNoAction';

import LynkedLogoJsx from 'src/components/LynkedLogoJsx';

export default ({ ads_info, food_menu }) => {
  if (ads_info) return <TapNoAction ads_info={ads_info} food_menu={food_menu} />;

  return (
    <Box mt="3rem" style={{ width: '100%', height: '100px' }}>
      <LynkedLogoJsx width="100%" height="100px" />
    </Box>
  );
};
