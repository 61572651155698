import React from 'react';
import { Box, Chip } from '@material-ui/core';

import './horizontal-scrollbar.css';

import { LYNKED_LIGHT_GREY, LYNKED_BLACK } from 'src/consts/colors';

const MenuItem = ({ idx, list_ref, text, selected, html_id, first_html_id }) => {
  const handleMenuItemOnClick = (e, html_id) => {
    let ele_target_cat = document.querySelector(`#${html_id}`);
    let target_y_pos = ele_target_cat.getClientRects()[0].y;

    let ele_first_cat = document.querySelector(`#${first_html_id}`);
    let first_cat_y_pos = ele_first_cat.getClientRects()[0].y;

    list_ref.current.scrollTo({
      top: target_y_pos - first_cat_y_pos,
      behavior: 'smooth',
    });
  };
  let selected_style = selected
    ? { color: LYNKED_BLACK, border: '1px solid #000000' }
    : { color: LYNKED_LIGHT_GREY, border: '1px solid #9A9A9A' };

  return (
    <Box id={`menu-item-id-${idx}`} style={{ padding: '0 3px' }} onClick={(e) => handleMenuItemOnClick(e, html_id)}>
      <Chip
        label={text}
        variant="outlined"
        style={{
          // font: 'normal normal normal 15px/28px Noto Sans JP',
          fontSize: '15px',
          lineHeight: '28px',

          ...selected_style,
        }}
      />
    </Box>
  );
};

export default MenuItem;
