import React from 'react';

import { Box, Button, Dialog } from '@material-ui/core';

import { LYNKED_WHITE, LYNKED_GREEN } from 'src/consts/colors';
import { useTranslation } from 'react-i18next';

export default function FoodOutOfStock({ open, setOpen, listFoodOutOfStock }) {
  const { t } = useTranslation();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        id="terms_and_condition_sign"
        open={open}
        style={{ height: '100%' }}
        BackdropProps={{
          style: {
            background: 'rgba( 0, 0, 0, 0.15 )',
            boxShadow: '0 8px 32px 0 rgba( 31, 38, 135, 0.37 )',
            backdropFilter: 'blur( 4px )',
            borderRadius: '10px',
            border: '1px solid rgba( 255, 255, 255, 0.18 )',
          },
        }}
        transitionDuration={{ enter: 0, exit: 0 }}
      >
        <div
          style={{
            height: '100%',
            display: 'flex',
            flexFlow: 'column',
            background: '#ACACAC 0% 0% no-repeat padding-box',

            justifyContent: 'center',
            alignItems: 'center',

            overflowX: 'hidden',
          }}
          id={'terms_and_conds_sign'}
        >
          <Box
            style={{
              backgroundColor: LYNKED_WHITE,

              display: 'flex',
              flexFlow: 'column',
              justifyContent: 'space-between',
            }}
          >
            <Box
              style={{
                padding: '3rem 0rem',
                width: '100%',
                textAlign: 'center',

                display: 'flex',
                flexFlow: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Box style={{ width: '80%', textAlign: 'center' }}>
                {listFoodOutOfStock?.map((food) => food.item_name).join(', ')}{' '}
                現在品切れです。お手数ですが、注文リストをもう一度確認してください。
              </Box>
            </Box>

            <Box style={{ width: '100%' }}>
              <Button
                id="accept_terms_and_cond"
                fullWidth
                style={{
                  height: '50px',
                  color: LYNKED_WHITE,
                  backgroundColor: LYNKED_GREEN,
                  borderRadius: 'unset',
                  fontWeight: 'bold',
                }}
                onClick={handleClose}
              >
                {t('LINEUP_OK')}
              </Button>
            </Box>
          </Box>
        </div>
      </Dialog>
    </>
  );
}
