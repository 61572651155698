import React from 'react';

export default () => {
  let [helloworld_to_monitor1, setHelloworldToMonitor1] = React.useState('helloworld_to_monitor1');

  return {
    helloworld_to_monitor1,
    setHelloworldToMonitor1,
  };
};
