import { useMutation } from 'react-query';
import { updateAllFoods } from 'src/modals/food_menu';

export default function useMutateUpdateAllFoods() {
  const mutation = useMutation(
    ({ rest_id, food_menu }) => {
      return updateAllFoods(rest_id, food_menu);
    },
    {
      retry: 10,
    }
  );

  return mutation;
}
