import React from 'react';

const USER_ENGAGED = 'USER_ENGAGED';
const USER_NOT_ENGAGED = 'USER_NOT_ENGAGED';

export default () => {
  let [helloworld_to_monitor1, setHelloworldToMonitor1] = React.useState('helloworld_to_monitor1');
  // consider:
  // true if user see pages on screen
  let [user_engage_state, setUserEngageState] = React.useState({
    state: USER_NOT_ENGAGED,
  });

  return {
    helloworld_to_monitor1,
    setHelloworldToMonitor1,

    USER_NOT_ENGAGED,
    USER_ENGAGED,
    user_engage_state,
    setUserEngageState,

    // setUserNotEngaged,
  };
};
