import React from 'react';
import { Box } from '@material-ui/core';

import { LYNKED_WHITE } from 'src/consts/colors';
import { MenyLightContext } from '../../contexts';
import RestaurantBar from '../../components/RestaurantBar';

export default React.memo(({ handleBackOnClick, view_only }) => {
  let { restaurant_config } = React.useContext(MenyLightContext);

  return (
    <>
      <Box
        style={{
          backgroundColor: 'black',
          color: ' white',
          minHeight: '20px',
          width: '100%',
          display: 'flex',
          flexFlow: 'column',
          justifyContent: 'center',
          textAlign: 'center',
          // font: 'normal normal normal 10px/18px Noto Sans JP',
          fontSize: '10px',
          lineHeight: '18px',
        }}
      >
        <span>{restaurant_config.name}</span>
      </Box>
      <Box
        style={{
          backgroundColor: LYNKED_WHITE,
          color: ' white',
          height: '100px',
          width: '100%',
          boxShadow: '0px 3px 6px #00000029',
        }}
      >
        <RestaurantBar show_back_button={true} back_button_callback={handleBackOnClick} restaurant_closed={view_only} />
      </Box>
    </>
  );
});
