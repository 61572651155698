import React from 'react';
import { Box, IconButton } from '@material-ui/core';

import { Icon } from '@iconify/react';
import doubleArrowDown from '@iconify-icons/radix-icons/double-arrow-down';

import { useStyles } from './styles';
import { MenyLightContext } from '../../contexts';

const LOG_PREFIX = 'FoodMenuListingPageAdTapToHighlightCategories';

export default ({ config }) => {
  const classes = useStyles();

  const { rest_id, food_menu, advertisement_config, increaseAdsImpressions } = React.useContext(MenyLightContext);

  let target_tag = config.ad_destination.cat_id;
  let ad_img_url = config.image_urls[0];
  console.log(LOG_PREFIX, 'config', config.image_urls);

  const handleAdBannerOnClick = (e, target_tag) => {
    let first_category = food_menu.columnOrder[0];
    console.log(LOG_PREFIX, 'handleAdBannerOnClick', 'food_menu', food_menu);
    console.log(LOG_PREFIX, 'handleAdBannerOnClick', 'first_category', first_category);

    let tag_label = `tag_id_${target_tag}`;
    console.log(LOG_PREFIX, 'handleAdBannerOnClick', 'tag_label', tag_label);

    let ele_target_cat = document.querySelector(`#tag_id_${target_tag}`);
    let ele_first_cat = document.querySelector(`#tag_id_${first_category}`);

    // NOTE: to handle the target category not found when clicked
    if (ele_first_cat && ele_target_cat) {
      let target_y_pos = ele_target_cat.getClientRects()[0].y;

      let first_cat_y_pos = ele_first_cat.getClientRects()[0].y;

      document.querySelector('#food-list').scrollTo({
        top: target_y_pos - first_cat_y_pos,
        behavior: 'smooth',
      });
    } else {
      console.log(LOG_PREFIX, 'target category not found when clicked');
    }
  };

  React.useEffect(() => {
    console.log(LOG_PREFIX, 'increaseAdsImpressions', { rest_id });
    increaseAdsImpressions({ rest_id });
  }, []);

  return (
    <>
      <Box className={classes.root} style={{ backgroundImage: `url(${ad_img_url})` }}>
        <Box
          id="food-menu-ads"
          className={classes.food_menu_ad}
          style={{ backgroundImage: `url(${ad_img_url})` }}
          onClick={(e) => handleAdBannerOnClick(e, target_tag)}
        >
          <Box>
            <IconButton onClick={handleAdBannerOnClick} className={classes.button}>
              <Icon icon={doubleArrowDown} />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </>
  );
};
