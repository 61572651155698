import { createMachine } from 'xstate';

import { NOT_MONITORING, MONITORING } from 'src/StateMachines/STATES';

const MonitorRestaurantConfigMachine = createMachine({
  id: 'monitor_restaurant_config_status',
  initial: NOT_MONITORING,
  context: { rest_id: null, menu_id: null, restaurant_config: null },
  states: {
    NOT_MONITORING: {
      on: {
        // sendMonitorRestaurantConfigMachine({
        //   type: MONITORING,
        //   lineup_id,
        //   rest_id,
        //   menu_id,
        // });

        MONITORING: {
          target: MONITORING,
          actions: [
            (context, event) => {
              context.rest_id = event.rest_id;
              context.menu_id = event.menu_id;
              console.log(`monitor_restaurant_config_status`, 'start', event);
            },
          ],
        },
      },
    },

    MONITORING: {
      on: {
        // sendMonitorRestaurantConfigMachine({
        //   type: NOT_MONITORING,
        // });

        NOT_MONITORING: {
          target: NOT_MONITORING,
          actions: [
            (context) => {
              // test fix for MENY-374,
              // context.rest_id = null;
              context.menu_id = null;
            },
          ],
        },
      },
    },
  },
});

export { MonitorRestaurantConfigMachine };
