import React from 'react';

import { Box } from '@material-ui/core';

import BottomBarWithAds from '../../../components/BottomBarWithAds';

import RestaurantBar from '../../../components/RestaurantBar';
import { LYNKED_BLACK, LYNKED_WHITE } from 'src/consts/colors';
import active_lang from 'src/langs/jp_en';

import OrderHistoryEmpty from './OrderHistoryEmpty';

import BottomPanel from './BottomPanel';
import ListOrders from './ListOrders';
import { MenyContext } from '../../../contexts';
import ShowDebug from 'src/components/ShowDebug';
import { useTranslation } from 'react-i18next';

function OrderHistoryPageHead({ restaurant_name }) {
  return (
    <>
      <Box
        style={{
          backgroundColor: LYNKED_BLACK,
          color: LYNKED_WHITE,
          minHeight: '20px',
          display: 'flex',
          flexFlow: 'column',
          justifyContent: 'center',
          // font: 'normal normal normal 10px/18px Noto Sans JP',
          fontSize: '10px',
          lineHeight: '18px',
        }}
      >
        <Box component="span">{restaurant_name}</Box>
      </Box>

      <Box
        style={{
          backgroundColor: LYNKED_WHITE,
          height: '100px', //for iphone
          minHeight: '100px', // for chrome
          boxShadow: '0px 3px 6px #00000029',
        }}
      >
        <RestaurantBar />
      </Box>
    </>
  );
}

function OrderHistoryPageBody({ order_history, load_status, restaurant_config }) {
  const { t } = useTranslation();

  return (
    <>
      <Box
        style={{
          height: '40px',

          // font: 'normal normal normal 20px/28px Noto Sans JP',
          fontSize: '20px',
          lineHeight: '28px',

          textAlign: 'left',
          padding: '0.5rem 1rem 0',
          background: '#fafafa',
        }}
      >
        {t('COMMON_ORDER_HISTORY')}
      </Box>

      <Box style={{ flexGrow: 1, overflowY: 'auto', padding: '0 1rem' }}>
        <Box style={{ display: 'flex', flexGrow: '1', paddingBottom: '3rem' }}>
          <Box style={{ width: '100%' }}>
            {order_history ? (
              <>
                <ListOrders
                  orders={order_history.orders}
                  load_status={load_status}
                  restaurant_config={restaurant_config}
                />
                {/* <ShowDebug>{JSON.stringify(order_history, null, 2)}</ShowDebug> */}
              </>
            ) : (
              <>
                <OrderHistoryEmpty />
              </>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default function ShowOrderHistoryPage({ order_history, load_status }) {
  let { restaurant_config } = React.useContext(MenyContext);
  let restaurant_name = restaurant_config.name;

  return (
    <>
      <Box style={{ height: '100%', display: 'flex', flexFlow: 'column' }}>
        <OrderHistoryPageHead restaurant_name={restaurant_name} />
        <OrderHistoryPageBody
          order_history={order_history}
          load_status={load_status}
          restaurant_config={restaurant_config}
        />

        <Box style={{ minHeight: '170px' }}>
          <BottomPanel order_history={order_history} />
        </Box>

        <Box style={{ minHeight: '75px' }}>
          <BottomBarWithAds />
        </Box>
      </Box>
    </>
  );
}
