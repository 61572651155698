import React from 'react';
import { Box } from '@material-ui/core';
import DownArrow from './DownArrow';
import UpArrow from './UpArrow';

export default ({ food_entry }) => {
  const ref_food_detail_inner_div = React.useRef(null);

  return (
    <>
      <UpArrow />
      <Box style={{ flexGrow: 1, width: '100%', overflowY: 'scroll', overflowX: 'hidden' }}>
        <Box
          ref={ref_food_detail_inner_div}
          dangerouslySetInnerHTML={{ __html: food_entry.description.replace(/\n/g, '<br />') }}
          style={{ paddingLeft: '1rem', paddingRight: '1rem', paddingTop: '1rem', paddingBottom: '2rem' }}
        ></Box>
      </Box>
      <DownArrow />
    </>
  );
};
