import { createMachine } from 'xstate';

import { NOT_MONITORING, MONITORING } from 'src/StateMachines/STATES';

const fetchUser = (context) => {
  console.log('hello fetchuser');
  return new Promise((res, rej) => {
    setTimeout(() => {
      res();
    }, 3000);
  });
};

const MonitorTableStatusMachine = createMachine({
  id: 'monitor_table_assign_status',
  initial: NOT_MONITORING,
  context: {
    lineup_id: null,
    rest_id: null,
    menu_id: null,
    form_info: {},
    table_assign_status: null,
  },
  states: {
    NOT_MONITORING: {
      on: {
        // sendMonitorTableStatusMachine(MONITORING, {
        //   lineup_id,
        //   rest_id,
        //   menu_id,
        //   form_info,
        // });
        MONITORING: {
          target: MONITORING,
          actions: [
            (context, event) => {
              context.lineup_id = event.lineup_id;
              context.rest_id = event.rest_id;
              context.menu_id = event.menu_id;
              context.form_info = event.form_info;
              context.table_assign_status = event.table_assign_status;
              console.log(`monitor_table_assign_status`, event.lineup_id);
            },
          ],
        },
      },
    },

    MONITORING: {
      on: {
        // sendMonitorTableStatusMachine({
        //   type: 'NOT_MONITORING',

        // });
        NOT_MONITORING: {
          target: NOT_MONITORING,
          actions: [
            (context, event) => {
              context.lineup_id = null;
              // test fix for MENY-374,
              // context.rest_id = null;
              context.menu_id = null;
              context.form_info = null;
              context.table_assign_status = null;
            },
          ],
        },
      },
    },
  },
});

export { MonitorTableStatusMachine };
