import { makeStyles } from '@material-ui/core';

import { LYNKED_RED } from 'src/consts/colors';

const useStyles = makeStyles((theme) => ({
  clipLoader: {
    display: 'block',
    margin: '0 auto',
    borderColor: LYNKED_RED,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

export { useStyles };
