import firebase from 'firebase';
import { getDecryptedUserUid } from '../utils/getDecryptedUserUid';

import config from '../config';

import db_config from 'src/configs/db_config';
import db from 'src/Firebase/db';
import { isNull, isUndefined } from 'lodash';

let LOG_PREFIX = 'service_request.js';

function createServiceRequestJson(table_name, username, order_history_id) {
  let update_time = new Date().getTime();
  let status = config.INIT_SERVICE_REQUEST_STATUS;
  return {
    update_time,
    status,
    table_name,
    username,
    order_history_id,
  };
}

function monitorMenyServiceRequestNotClearedByOrderHistoryId(rest_id, order_history_id, cb) {
  console.log(LOG_PREFIX, 'monitorMenyServiceRequestNotClearedByOrderHistoryId', { rest_id, order_history_id });

  return db
    .collection(db_config.DB_TABLE_ALL_SERVICE_REQUESTS)
    .doc(rest_id)
    .collection('requests')
    .where('order_history_id', '==', order_history_id)
    .where('status', '==', 'NOT_CLEARED')
    .onSnapshot((doc) => {
      if (doc?.docs) cb(doc);
    });
}

function monitorMenyServiceRequestByOrderHistoryId(rest_id, order_history_id, cb) {
  console.log(LOG_PREFIX, 'monitorMenyServiceRequestByOrderHistoryId', { rest_id, order_history_id });

  return db
    .collection(db_config.DB_TABLE_ALL_SERVICE_REQUESTS)
    .doc(rest_id)
    .collection('requests')
    .where('order_history_id', '==', order_history_id)
    .onSnapshot((doc) => {
      if (doc?.docs) cb(doc);
    });
}

function monitorMenyServiceRequestByDocId(rest_id, doc_id, cb) {
  console.log('monitorMenyServiceRequestByDocId', 'init', rest_id, doc_id);

  return db
    .collection(db_config.DB_TABLE_ALL_SERVICE_REQUESTS)
    .doc(rest_id)
    .collection('requests')
    .where(firebase.firestore.FieldPath.documentId(), '==', doc_id)
    .onSnapshot((doc) => {
      if (isUndefined(doc) || isNull(doc) || isUndefined(doc.docs[0])) {
        console.log('monitorMenyServiceRequestByDocId', 'cannot get docs');
      } else {
        console.log('monitorMenyServiceRequestByDocId', 'receive update', doc.docs[0].data());
        cb(doc);
      }
    });
}

function monitorServiceRequestByDocId(encrypted_rest_id, doc_id) {
  let rest_id = getDecryptedUserUid(encrypted_rest_id);
  console.log('monitorServiceRequestByDocId', 'init');

  return db
    .collection(db_config.DB_TABLE_ALL_SERVICE_REQUESTS)
    .doc(rest_id)
    .collection('requests')
    .where(firebase.firestore.FieldPath.documentId(), '==', doc_id)
    .onSnapshot((doc) => {
      console.log('monitorServiceRequestByDocId', 'doc.docs[0].data()', doc.docs[0].data());
      console.log('monitorServiceRequestByDocId', 'doc_id', doc_id);

      let doc_data = doc.docs[0].data();

      return doc_data;
    });
  // setUnsubRef(unsub_order);
}

function addServiceRequest(rest_id, table_name, username, order_history_id) {
  console.log('addServiceRequest', 'rest_id', rest_id);
  console.log('addServiceRequest', 'table_name', table_name);
  console.log('addServiceRequest', 'username', username);

  let temp_json = createServiceRequestJson(table_name, username, order_history_id);
  // let rest_id = getDecryptedUserUid(encrypted_rest_id);
  let decrypted_rest_id = rest_id;

  console.log('addServiceRequest', 'temp_json', temp_json);

  return db
    .collection(db_config.DB_TABLE_ALL_SERVICE_REQUESTS)
    .doc(decrypted_rest_id)
    .collection('requests')
    .add({
      ...temp_json,
      request_create_time: firebase.firestore.FieldValue.serverTimestamp(),
    });
}

function addMenyLightServiceRequest(rest_id, table_name) {
  let decrypted_rest_id = rest_id;
  let temp_json = createServiceRequestJson(table_name, table_name);

  console.log('addMenyLightServiceRequest', 'temp_json', temp_json);

  return db
    .collection(db_config.DB_TABLE_ALL_SERVICE_REQUESTS)
    .doc(decrypted_rest_id)
    .collection('requests')
    .add({
      ...temp_json,
      request_create_time: firebase.firestore.FieldValue.serverTimestamp(),
    });
}

function helloworldServiceRequest() {
  console.log('service_request.js', 'helloworldSserviceRequest');
}

function monitorMenyLightNotClearedServiceRequestByTableName({ rest_id, table_name }) {
  console.log('monitorMenyLightNotClearedServiceRequestByTableName', { rest_id, table_name });

  return db
    .collection(db_config.DB_TABLE_ALL_SERVICE_REQUESTS)
    .doc(rest_id)
    .collection('requests')
    .where('status', '!=', 'CLEARED')
    .where('table_name', '==', table_name);
}

function monitorMenyLightServiceRequestByTableName({ rest_id, table_name }) {
  console.log('monitorMenyLightServiceRequestByTableName', { rest_id, table_name });

  return db
    .collection(db_config.DB_TABLE_ALL_SERVICE_REQUESTS)
    .doc(rest_id)
    .collection('requests')
    .where('table_name', '==', table_name);
}

export {
  monitorServiceRequestByDocId,
  monitorMenyServiceRequestNotClearedByOrderHistoryId,
  monitorMenyServiceRequestByOrderHistoryId,
  monitorMenyServiceRequestByDocId,
  monitorMenyLightServiceRequestByTableName,
  monitorMenyLightNotClearedServiceRequestByTableName,
  helloworldServiceRequest,
  addServiceRequest,
  addMenyLightServiceRequest,
};
