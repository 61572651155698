import React from 'react';

import { Box } from '@material-ui/core';

import LynkedLogoJsx from 'src/components/LynkedLogoJsx';

import { select_values } from 'src/modals/advertisement_config';

import TapToFoodCategory from '../TapToFoodCategory';
import TapToFoodDetail from '../TapToFoodDetail';
import { MenyContext } from 'src/MenyApp/contexts';

let LOG_PREFIX = 'LoadAdWindow';

const NoAdsDefined = React.memo(() => {
  return (
    <Box mt="3rem" style={{ width: '100%', height: '100px' }}>
      <LynkedLogoJsx width="100%" height="100px" />
    </Box>
  );
});

export default ({ ads_info }) => {
  let { restaurant_food_menu } = React.useContext(MenyContext);

  let ad_action = ads_info?.ad_action;
  console.log(LOG_PREFIX, 'ad_action', ad_action);

  switch (ad_action?.name) {
    case select_values.ad_action.FOOD_MENU.name:
      return <TapToFoodCategory ads_info={ads_info} food_menu={restaurant_food_menu} />;

    case select_values.ad_action.FOOD_DETAIL.name:
      return <TapToFoodDetail ads_info={ads_info} food_menu={restaurant_food_menu} />;

    default:
      return <NoAdsDefined />;
  }
};
