import React from 'react';
import { Box, IconButton } from '@material-ui/core';

import { Icon } from '@iconify/react';
import doubleArrowDown from '@iconify-icons/radix-icons/double-arrow-down';

import { MenyContext } from '../../contexts';

import { useStyles } from './styles';

let LOG_PREFIX = 'TapToFoodCategory';

export default ({ food_menu, ads_info }) => {
  let classes = useStyles();

  let { rest_id, increaseAdsImpressions } = React.useContext(MenyContext);
  let { image_urls } = ads_info;
  let target_tag = ads_info?.ad_destination?.cat_id;

  const handleAdBannerOnClick = () => {
    let first_category = food_menu.columnOrder[0];
    console.log(LOG_PREFIX, 'food_menu', food_menu);
    console.log(LOG_PREFIX, 'first_category', first_category);

    let tag_label = `tag_id_${target_tag}`;
    console.log(LOG_PREFIX, 'tag_label', tag_label);
    let ele_target_cat = document.querySelector(`#${tag_label}`);

    if (ele_target_cat) {
      let target_y_pos = ele_target_cat.getClientRects()[0].y;
      let ele_first_cat = document.querySelector(`#tag_id_${first_category}`);
      let first_cat_y_pos = ele_first_cat.getClientRects()[0].y;

      document.querySelector('#food-menu-food-entry').scrollTo({
        top: target_y_pos - first_cat_y_pos,
        behavior: 'smooth',
      });
    } else {
      console.log(LOG_PREFIX, 'ele_target_cat is empty');
    }
  };

  React.useEffect(() => {
    increaseAdsImpressions({ rest_id });
  }, []);

  return (
    <>
      <Box
        className={classes.root}
        style={{
          // don't move for iphone styling
          background: 'rgba( 255, 255, 255, 0.50 )',
          backgroundImage: `url(${image_urls[0]})`,

          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
      >
        <Box className={classes.food_menu_ad} style={{ backgroundImage: `url(${image_urls[0]})` }}>
          <IconButton id={'tap_to_food_category_ad_button'} className={classes.button} onClick={handleAdBannerOnClick}>
            <Icon icon={doubleArrowDown} />
          </IconButton>
        </Box>
      </Box>
    </>
  );
};
