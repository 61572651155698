import React from 'react';
import { Box } from '@material-ui/core';

import lynked_svg from 'src/assets/tabi-life-black.svg';

export default function LynkedLogoSvg() {
  return (
    <>
      <Box
        style={{
          width: '100%',
          height: '100%',
          backgroundImage: `url(${lynked_svg})`,
          backgroundSize: 'contain',
          // backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      ></Box>
    </>
  );
}
