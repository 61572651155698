import React from 'react';
import SquareLinkedLogo from 'src/components/SquareLinkedLogo';

import WARNING_ERROR_SVG from './warning_error.svg';

export default function TechnicalSupportPage() {
  console.log('TechnicalSupportPage', 'logging', window.location.href);
  return (
    <>
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexFlow: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            width: '150px',
            height: '150px',
            backgroundImage: `url(${WARNING_ERROR_SVG})`,
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        ></div>
        <div
          style={{
            paddingTop: '5rem',

            fontFamily: 'Noto Sans JP',
            fontStyle: 'normal',
            fontWeight: '300',
            fontSize: '18px',
            lineHeight: '26px',
          }}
        >
          <div>押し戻して再試行してください...</div>
          <div>支払いが中断されました</div>
        </div>
        <div style={{ paddingTop: '5rem' }}>
          <SquareLinkedLogo width={100} height={100} />
        </div>
      </div>
    </>
  );
}
