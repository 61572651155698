import React from 'react';

import { Box, Button } from '@material-ui/core';

import ShowDebug from 'src/components/ShowDebug';

import { LYNKED_WHITE } from 'src/consts/colors';

import active_lang from 'src/langs/jp_en';
import { useTranslation } from 'react-i18next';

export default function TableAssignedSign({ table_number, handleOKClick }) {
  const { t } = useTranslation();

  return (
    <>
      <div
        style={{
          height: '100%',
          display: 'flex',
          flexFlow: 'column',
          background: '#ACACAC 0% 0% no-repeat padding-box',

          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: '5px',
        }}
      >
        <Box
          style={{
            width: '80vw',
            height: '30vh',
            backgroundColor: LYNKED_WHITE,

            display: 'flex',
            flexFlow: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box
            style={{
              display: 'flex',
              flexFlow: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              flexGrow: 1,
            }}
          >
            {/* {active_lang.YOUR_TABLE_NUMBER(table_number)} */}
            <Box>{t('LINEUP_RECEPTION_COMPLEDTED')}</Box>
            <Box>{t('LINEUP_PRODUCT_CAN_ORDERED')}</Box>
            {/* <Box>
              <ShowDebug>WE_HAD_CONFIRMED_YOUR_RECEPTION_INFO</ShowDebug>
              <ShowDebug>YOU_CAN_NOW_PLACE_ORDER</ShowDebug>
            </Box> */}
          </Box>
          <Box style={{ width: '100%' }}>
            <Button
              id="ack-table-assign"
              onClick={handleOKClick}
              fullWidth
              style={{
                height: '50px',
                color: LYNKED_WHITE,
                background: '#388E00 0% 0% no-repeat padding-box',
                borderRadius: '0px 0px 5px 5px',

                // font: 'normal normal normal 15px/20px Noto Sans JP',
                fontSize: '15px',
                lineHeight: '20px',
              }}
            >
              {t('LINEUP_OK')}
            </Button>
          </Box>
        </Box>
      </div>
    </>
  );
}
