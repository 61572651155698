// restaurant_open_state

const RESTAURANT_OPEN_STATE_LOADING = -1;
const RESTAURANT_OPEN_STATE_OPENED = 0;
const RESTAURANT_OPEN_STATE_PREPARING = 1; // restaurant closed, not accept orders

export {
  RESTAURANT_OPEN_STATE_LOADING,
  RESTAURANT_OPEN_STATE_OPENED,
  RESTAURANT_OPEN_STATE_PREPARING,
};
