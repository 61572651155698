import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  headbar_content: {
    height: '100%',
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export { useStyles };
