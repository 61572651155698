import React from 'react';
import { MenyContext } from '..';

import MenyClientStateMachineComponent from './MenyClientStateMachineComponent';
import MonitorOrderStatusMachineComponent from './MonitorOrderStatusMachineComponent';
import MonitorTableStatusMachineComponent from './MonitorTableStatusMachineComponent';
// import MonitorRestaurantConfigMachineComponent from './MonitorRestaurantConfigMachineComponent';

export function StateMachines() {
  let {
    meny_client_state_machine,
    monitor_order_status_machine,
    monitor_table_status_machine,
    monitor_restaurant_config_machine,
  } = React.useContext(MenyContext);

  const all_machines = {
    meny_client_state_machine,
    monitor_order_status_machine,
    monitor_table_status_machine,
    monitor_restaurant_config_machine,
  };

  return (
    <>
      <MenyClientStateMachineComponent machines={all_machines} />{' '}
      <MonitorOrderStatusMachineComponent machines={all_machines} />
      <MonitorTableStatusMachineComponent machines={all_machines} />
      {/* <MonitorRestaurantConfigMachineComponent machines={all_machines} /> */}
    </>
  );
}
