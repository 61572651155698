import React from 'react';
import { MenyContext } from '..';

import useMutationSubscribeFoodMenu from 'src/hooks/useMutationSubscribeFoodMenu';

let LOG_PREFIX = 'FoodMenu';

export default () => {
  let {
    rest_id,
    menu_id,
    setRestaurantFoodMenu,
    food_menu,
    setFoodMenu,
    setFoodMenuToMonitor1,
    is_loading_food_menu,
    setIsLoadingFoodMenu,
  } = React.useContext(MenyContext);
  const mutationSubscribeFoodMenu = useMutationSubscribeFoodMenu();

  React.useEffect(() => {
    mutationSubscribeFoodMenu
      .mutateAsync(rest_id)
      .then((res) => {
        res.onSnapshot({ includeMetadataChanges: true }, (doc) => {
          setFoodMenuToMonitor1(doc.data());
          setRestaurantFoodMenu(doc.data());
        });
      })
      .then(() => {
        setIsLoadingFoodMenu(false);
      });
  }, []);

  return <></>;
};
