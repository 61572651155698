import React from 'react';
import { MenyContext } from '.';
import { CLIENT_TABLE_ASSIGNED } from './StateMachines/STATES';
import { getOrderHistoryByOrderHistoryId } from 'src/modals/order';

let LOG_PREFIX = 'FetchDB';
export default ({ children }) => {
  let [is_loading, setIsLoading] = React.useState(true);
  let { meny_client_state_machine, rest_id, setOrderHistory } = React.useContext(MenyContext);
  let [client_state, sendClientState] = meny_client_state_machine;

  React.useEffect(() => {
    console.log(LOG_PREFIX, 'client_state', client_state);

    if (client_state.matches(CLIENT_TABLE_ASSIGNED)) {
      console.log(LOG_PREFIX, 'order_history_id', client_state.context.order_history_id);
      let { order_history_id } = client_state.context;
      getOrderHistoryByOrderHistoryId(rest_id, order_history_id)
        .then((doc) => {
          console.log(LOG_PREFIX, 'get_order_history', doc.data());
          setOrderHistory(doc.data());
        })
        .catch((err) => {
          console.error(LOG_PREFIX, 'err', err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, [client_state]);

  if (is_loading) return <></>;
  return <>{children}</>;
};
