import React from 'react';

import ReservationPage from '../ReservationPage';

export default function RedirectAfterLoading() {
  return (
    <>
      <ReservationPage />
    </>
  );
}
