import React from 'react';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { Box, Button, Input, Grid } from '@material-ui/core';

import RestaurantBrandPlate from '../../../components/RestaurantBrandPlate';

import TermsAndConditionsSign from '../../../components/TermsAndConditionsSign';

import useMutationRestaurantConfig from 'src/hooks/useMutationRestaurantConfig';
import useMutationAddIntoLineupTable from 'src/hooks/useMutationAddIntoLineupTable';
import useMutationGetTicketIndex from 'src/hooks/useMutationGetTicketIndex';
import ShowDebug from 'src/components/ShowDebug';

import { LYNKED_GREEN, LYNKED_LIGHT_GREY } from 'src/consts/colors';
import config from 'src/config';
import active_lang from 'src/langs/jp_en';
import useStyles from './styles';
import AddChildSeatButton from './AddChildSeatButton';
import RemoveChildSeatButton from './RemoveChildSeatButton';
import AddAdultSeatButton from './AddAdultSeatButton';
import RemoveAdultSeatButton from './RemoveAdultSeatButton';
import { RESTAURANT_OPEN_STATE_OPENED, RESTAURANT_OPEN_STATE_PREPARING } from 'src/consts/RESTAURANT_OPEN_STATE';
import { MenyContext } from '../../../contexts';

import { CLIENT_WAITING_TABLE } from 'src/StateMachines/STATES';
import * as Yup from 'yup';
import WarningIcon from '@material-ui/icons/Warning';
import { LYNKED_RED } from 'src/consts/colors';
import { SEAT_ASSIGNED, assignTableByDocId } from 'src/modals/lineup';
import firebase from 'firebase/app';
import createEmptybill from 'src/helpers/createEmptyBill';
import { useTranslation } from 'react-i18next';

const LOG_PREFIX = 'ReservationPage';

const default_form_value = {
  'num-of-adult': 1,
  'num-of-child': 0,
  'num-of-baby': 0,
  'lineup-username': '',
  'lineup-note': '',
  'lineup-table-number': '',
};

export default function ReservationPage() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  let {
    rest_id,
    menu_id,
    restaurant_config,
    setReservationNumber,
    setOpenRestaurantClosedSign,
    setOpenReservationNumberAssignedDialog,
    meny_client_state_machine,
    storeIsTheFirstTime,
  } = React.useContext(MenyContext);

  let mutation_get_ticket_index = useMutationGetTicketIndex();
  let mutation_restaurant_config = useMutationRestaurantConfig();
  let mutate_add_lineup_table = useMutationAddIntoLineupTable();

  let [client_state, sendClientState] = meny_client_state_machine;

  // let [is_loading, setIsLoading] = React.useState(true);
  // const [use_meny_ads_page, setMenyAdsPage] = React.useState(false);

  // let { storeRestaurantConfig } = React.useContext(RestaurantConfigContext);
  // let { storeLineupDocId } = React.useContext(LineupDocIdContext);

  const [sending_lineup_request, setSendingLineupRequest] = React.useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: default_form_value,
    validationSchema: Yup.object({
      'lineup-username': Yup.string().required(t('LINEUP_ITEM_REQUIRED')),
      'lineup-table-number': Yup.string().test('lineup-table-number is required', t('LINEUP_ITEM_REQUIRED'), (val) => {
        if (restaurant_config.isSkippableToAssignTable) return Boolean(val);
        return true;
      }),
    }),
    onSubmit: (values, { resetForm }) => {
      setSendingLineupRequest(true);
      return mutation_restaurant_config
        .mutateAsync(rest_id)
        .then(async (doc_data) => {
          let form_info = { ...values, status: config.SEAT_WAITING, 'request-time-utc': Date.now() };

          let restaurant_config = doc_data;

          // MENY-409, update restaurant_open_id when submit lineup request
          // setRestaurantConfig({ data: doc_data });

          switch (restaurant_config?.restaurant_open_state) {
            case RESTAURANT_OPEN_STATE_OPENED:
              enqueueSnackbar(t('LINEUP_ACCEPT_COMPLETED'), { variant: 'success' });
              let ticket_index_result = await mutation_get_ticket_index.mutateAsync(rest_id);
              let { current_ticket } = ticket_index_result;
              let { restaurant_open_id } = restaurant_config;
              form_info['current_ticket'] = current_ticket;
              form_info['restaurant_open_id'] = restaurant_open_id;
              let lineup_id = await mutate_add_lineup_table.mutateAsync({
                rest_id,
                form_info,
                current_ticket,
              });

              setReservationNumber(current_ticket);
              setOpenReservationNumberAssignedDialog(true);
              // storeLineupDocId(lineup_id);

              sendClientState(CLIENT_WAITING_TABLE, {
                rest_id,
                menu_id,
                lineup_id,
                restaurant_open_id,
                form_info,
              });

              if (restaurant_config.isSkippableToAssignTable) {
                assignTableByDocId(rest_id, client_state.context.lineup_id, {
                  status: SEAT_ASSIGNED,
                  assign_table_name: formik.values['lineup-table-number'],
                  assign_table_time: firebase.firestore.Timestamp.now(),
                  restaurant_open_id: restaurant_config.restaurant_open_id,
                })
                  .then(() => {
                    return createEmptybill({
                      rest_id,
                      lineup_id: client_state.context.lineup_id,
                      order_table_number: formik.values['lineup-table-number'],
                      order_username: client_state.context.form_info['lineup-username'],
                      num_of_adult: client_state.context.form_info['num-of-adult'],
                      num_of_child: client_state.context.form_info['num-of-child'],
                    });
                  })
                  .then(() => {
                    resetForm({});
                  })
                  .catch(() => {
                    console.error('fail to allocate table');
                  });
              }
              storeIsTheFirstTime(true);
              break;

            case RESTAURANT_OPEN_STATE_PREPARING:
              // trigger when restaurant is preparing before user lineup
              setOpenRestaurantClosedSign(true);

              formik.resetForm();
              break;

            default:
              break;
          }
        })
        .then(() => formik.resetForm())
        .catch((err) => {
          alert('error found');
          console.error(LOG_PREFIX, 'addRecord', 'err', err);
        })
        .finally(() => {
          setSendingLineupRequest(false);
        });
    },
  });

  return (
    <>
      <TermsAndConditionsSign />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit(e);
        }}
      >
        <Box className={classes.root}>
          <Box className={classes.global_restaurant_name}>
            <Box component="span">{restaurant_config.name}</Box>
          </Box>
          <Box className={classes.restaurant_brand_plate_container}>
            <Box className={classes.restaurant_brand_plate}>
              <RestaurantBrandPlate show_back_button={false} />
            </Box>
          </Box>

          <Box className={classes.reservation_details}>{t('LINEUP_RECEPTION_CONTENT')}</Box>
          <Box className={classes.reservation_details_container}>
            <Grid container>
              {restaurant_config.isSkippableToAssignTable && (
                <Grid item xs={12}>
                  <Box className={classes.lineup_username_container}>
                    <Input
                      disableUnderline={true}
                      placeholder={restaurant_config?.lineup?.lineup_table_placeholder || '部屋番号/お名前'}
                      className={classes.lineup_table_number}
                      id="lineup-table-number"
                      {...formik.getFieldProps('lineup-table-number')}
                    />
                    {formik.touched['lineup-table-number'] && formik.errors['lineup-table-number'] ? (
                      <Box
                        style={{
                          color: LYNKED_RED,
                          display: 'flex',
                          flexFlow: 'row',
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                          paddingLeft: '0.25rem',
                          paddingTop: '0.25rem',
                          paddingBottom: '0.25rem',
                        }}
                      >
                        <Box pr="1rem">
                          <WarningIcon fontSize="small" />
                        </Box>
                        <Box>{formik.errors['lineup-table-number']}</Box>
                      </Box>
                    ) : null}
                  </Box>
                </Grid>
              )}

              <Grid item xs={12}>
                <Box className={classes.lineup_username_container}>
                  <Input
                    id="lineup-username"
                    {...formik.getFieldProps('lineup-username')}
                    disableUnderline={true}
                    placeholder={restaurant_config?.lineup?.lineup_username_placeholder || '名前'}
                    className={classes.lineup_username}
                  />
                  {formik.touched['lineup-username'] && formik.errors['lineup-username'] ? (
                    <Box
                      style={{
                        color: LYNKED_RED,
                        display: 'flex',
                        flexFlow: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        paddingLeft: '0.25rem',
                        paddingTop: '0.25rem',
                        paddingBottom: '0.25rem',
                      }}
                    >
                      <Box pr="1rem">
                        <WarningIcon fontSize="small" />
                      </Box>
                      <Box>{formik.errors['lineup-username']}</Box>
                    </Box>
                  ) : null}
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box className={classes.adult_child_seat_container}>
                  <Grid container spacing={3}>
                    <Grid item xs={6} container>
                      <Grid item xs={3} className={classes.grid_item_vertical_center}>
                        {t('LINEUP_ADULT')}
                      </Grid>
                      <Grid item xs={3}>
                        <RemoveAdultSeatButton
                          onClick={() => {
                            formik.setFieldValue('num-of-adult', Math.max(1, formik.values['num-of-adult'] - 1));
                          }}
                        />
                      </Grid>
                      <Grid item xs={3} className={classes.grid_item_vertical_center}>
                        <Input
                          type="number"
                          id="num-of-adult"
                          {...formik.getFieldProps('num-of-adult')}
                          inputProps={{ style: { textAlign: 'center' } }}
                          disableUnderline={true}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <AddAdultSeatButton
                          onClick={() => {
                            formik.setFieldValue(
                              'num-of-adult',
                              Math.min(
                                restaurant_config?.lineup?.max_num_of_adult || 100,
                                formik.values['num-of-adult'] + 1
                              )
                            );
                          }}
                        />
                      </Grid>
                    </Grid>

                    <Grid item xs={6} container>
                      <Grid item xs={3} className={classes.grid_item_vertical_center}>
                        {t('LINEUP_CHILD')}
                      </Grid>
                      <Grid item xs={3}>
                        <RemoveChildSeatButton
                          onClick={() => {
                            formik.setFieldValue('num-of-child', Math.max(0, formik.values['num-of-child'] - 1));
                          }}
                        />
                      </Grid>
                      <Grid item xs={3} className={classes.grid_item_vertical_center}>
                        <Input
                          type="number"
                          id="num-of-child"
                          {...formik.getFieldProps('num-of-child')}
                          disableUnderline={true}
                          inputProps={{ style: { textAlign: 'center' } }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <AddChildSeatButton
                          onClick={() => {
                            formik.setFieldValue(
                              'num-of-child',
                              Math.min(
                                restaurant_config?.lineup?.max_num_of_children || 100,
                                formik.values['num-of-child'] + 1
                              )
                            );
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box style={{ paddingTop: '0.5rem' }}>
                  <Input
                    id="lineup-note"
                    {...formik.getFieldProps('lineup-note')}
                    placeholder={restaurant_config?.lineup?.lineup_remarks_placeholder || '備考'}
                    disableUnderline={true}
                    className={classes.lineup_remarks_placeholder}
                    multiline
                    rows={6}
                  />
                </Box>
              </Grid>
              {/* <ShowDebug>
                <pre>{formik.values['lineup-note']}</pre>
              </ShowDebug> */}
              <Grid item xs={12}>
                <Box style={{ marginTop: '1rem' }}>
                  <Button
                    id="send-lineup-request"
                    disabled={sending_lineup_request}
                    className={`${classes.send_lineup_request_button} ${
                      sending_lineup_request ? classes.disabled : ''
                    }`}
                    onClick={(e) => formik.handleSubmit(e)}
                    style={{ backgroundColor: sending_lineup_request ? LYNKED_LIGHT_GREY : LYNKED_GREEN }}
                  >
                    {sending_lineup_request ? t('LINEUP_SUBMITTING') : t('LINEUP_ACCEPT_ABOVE')}
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box mt={'0.5rem'} style={{ fontSize: 'x-small' }}>
                  {t('LINEUP_ACCEPT_ABOVE_MSG')}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </form>
    </>
  );
}
