import React from 'react';

import { IconButton } from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';

export default function AddPeopleButton({ handleAddItem }) {
  return (
    <>
      <div style={{ width: '25%' }}>
        <div
          style={{
            marginLeft: '1rem',
            borderRadius: '26px',
            border: '1px solid #707070',
            width: '28px',
            height: '28px',

            display: 'flex',
            flexFlow: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IconButton id="add_people" onClick={handleAddItem}>
            <AddIcon />
          </IconButton>
        </div>
      </div>
    </>
  );
}
