import React from 'react';
import { Link as RouterLink, useMatch, useResolvedPath } from 'react-router-dom';

import { Box, Button } from '@material-ui/core';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';

import { useStyles } from './styles';
import { MenyContext } from '../../contexts';
import active_lang from 'src/langs/jp_en';
import classNames from 'classnames';
import { getOrderHistoryAdsLink } from 'src/utils/getLinks';
import { ReactComponent as RightArrow } from 'src/assets/icons/right-arrow.svg';
import { useTranslation } from 'react-i18next';

export default function OrderHistoryButton() {
  const classes = useStyles();
  let { rest_id, menu_id } = React.useContext(MenyContext);
  const orderHistoryAdsLink = getOrderHistoryAdsLink(rest_id, menu_id)
  const resolved = useResolvedPath(orderHistoryAdsLink);
  const match = useMatch({ path: resolved.pathname });
  const { t } = useTranslation();

  return (
    <Box>
      <RouterLink to={orderHistoryAdsLink} style={{ textDecoration: 'none' }} replace>
        <Button id="button-order-history" className={classNames(classes.button, { active: match }, 'green')}>
          <Box className={classes.button_style}>
            <Box p={0} m={0} className={classes.button_icon}>
              <RightArrow width={22} height={22} className="btn-icon" />
            </Box>
            <Box p={0} m={0}>
              {t('COMMON_ORDER_HISTORY')}
            </Box>
          </Box>
        </Button>
      </RouterLink>
    </Box>
  );
}
