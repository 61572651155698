let OPENING_HOURS_MESSAGE1 = `店舗をご利用いただきありがとうございます。
ただ今のお時間、営業致しておりません。`;

let OPENING_HOURS_MESSAGE2 = `次のご利用を心よりお待ちしております。`;

let OPENING_HOURS_OK_TEXT = 'メニューを見る';

let OPENING_HOURS_OK_TEXT_MENY_LIGHT = 'メニューを見る'; //for meny light/carryout
let OPENING_HOURS_OK_TEXT_MENY = 'OK'; //for meny dine-in/meny hotel in-room-dining

export default { OPENING_HOURS_MESSAGE1, OPENING_HOURS_MESSAGE2, OPENING_HOURS_OK_TEXT, OPENING_HOURS_OK_TEXT_MENY, OPENING_HOURS_OK_TEXT_MENY_LIGHT };
