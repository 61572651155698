import React from 'react';
import { Box } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';

import { LYNKED_WHITE } from 'src/consts/colors';

import active_lang from 'src/langs/jp_en';
import { useTranslation } from 'react-i18next';

export default function FoodAddToCartSign({ open, setOpen }) {
  const { t } = useTranslation();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        open={open}
        onClick={handleClose}
        style={{ height: '100%' }}
        BackdropProps={{
          style: {
            background: 'rgba( 0, 0, 0, 0.15 )',
            boxShadow: '0 8px 32px 0 rgba( 31, 38, 135, 0.37 )',
            backdropFilter: 'blur( 4px )',
            borderRadius: '10px',
            border: '1px solid rgba( 255, 255, 255, 0.18 )',
          },
        }}
        transitionDuration={{ enter: 0, exit: 0 }}
      >
        <div
          style={{
            height: '100%',
            display: 'flex',
            flexFlow: 'column',

            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box
            id="add-food-done-dialog"
            style={{
              display: 'flex',
              flexFlow: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '0.5rem 2rem',
              backgroundColor: LYNKED_WHITE,
              borderRadius: '30px',
            }}
          >
            {t('FOOD_DETAIL_COMPLETED_ORDER_ADDITION')}
          </Box>
        </div>
      </Dialog>
    </>
  );
}
