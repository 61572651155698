const getItemSubtotal = (quantity, unit_price, adjust_by_customization) => {
  try {
    let subtotal = parseInt(quantity) * (Number(unit_price) + Number(adjust_by_customization));
    return Number(Math.max(0, subtotal).toFixed(2));
  } catch (error) {
    console.log('error during getItemSubtotal', quantity, unit_price, adjust_by_customization);
  }
};

export default getItemSubtotal;
