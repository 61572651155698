import React, { lazy, Suspense } from 'react';
import { Box, Button, Dialog } from '@material-ui/core';

import active_lang from 'src/langs/jp_en';
import { useTranslation } from 'react-i18next';
import { importMDX } from 'mdx.macro';
const ExplainContact = lazy(() => importMDX('./explain_contact.mdx'));

export default function TAndCExplain({ open, setOpen }) {
  const { t } = useTranslation();

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Dialog fullScreen open={open} onClose={handleClose} transitionDuration={{ enter: 0, exit: 0 }}>
        <Box style={{ display: 'flex', flexFlow: 'row', justifyContent: 'center' }}>
          <Box style={{ width: '90%', paddingBottom: '3rem' }}>
            <Suspense fallback={<div>{active_lang.LOADING}</div>}>
              <ExplainContact />
            </Suspense>
            <Box style={{ width: '100%', paddingBottom: '3rem' }}>
              <Button style={{ width: '100%', height: '2rem' }} onClick={handleClose}>
                {t('LINEUP_CLOSE')}
              </Button>
            </Box>
          </Box>
        </Box>
      </Dialog>
    </>
  );
}
