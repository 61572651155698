import React from 'react';
import MenyLightLayout from 'src/MenyLightApp/layouts/MenyLightLayout';

import { MenyLightContextProvider } from 'src/MenyLightApp/contexts';
import MenyLightInitializePage from 'src/MenyLightApp/views/MenyLightInitializePage';

import ErrorsHandler from 'src/MenyLightApp/errors/ErrorsHandler';
import { I18nProvider } from 'src/utils/i18n';
import { MenyLightContext } from 'src/MenyLightApp/contexts';
const ENV_PUBLIC_URL = process.env.PUBLIC_URL;

const MenyLightApp = () => {
  return (
    <ErrorsHandler>
      <MenyLightContextProvider>
        <I18nProvider context={MenyLightContext}>
          <MenyLightLayout />
        </I18nProvider>
      </MenyLightContextProvider>
    </ErrorsHandler>
  );
};

const meny_light_production_route = [
  {
    path: `${ENV_PUBLIC_URL}/:rest_id/:menu_id/:table_name`,
    element: <MenyLightApp />,
    children: [
      {
        // 192.168.88.254:8004/huccMJpeQPUzwmV5k5Jb/huccMJpeQPUzwmV5k5Jb/table_1
        path: '/',
        element: <MenyLightInitializePage />,
      },
    ],
  },
  {
    path: `${ENV_PUBLIC_URL}/:rest_id/:menu_id`,
    element: <MenyLightApp />,
    children: [
      {
        // 192.168.88.254:8004/huccMJpeQPUzwmV5k5Jb/huccMJpeQPUzwmV5k5Jb/{unknown route}
        path: '/*',
        element: <>sorry please try again</>,
      },
    ],
  },
];

export default meny_light_production_route;
