import React from 'react';

export default function RestaurantMetaSharedVar() {
  let [restaurantmeta_to_monitor1, setRestaurantMetaToMonitor1] = React.useState(null);

  let [food_menu, setRestaurantMeta] = React.useState();
  let [restaurant_profile_pic_base64, setRestaurantProfilePicBase64] = React.useState();

  let [is_loading_restaurant_assets, setIsLoadingRestaurantAssets] = React.useState(true);

  return {
    restaurantmeta_to_monitor1,
    setRestaurantMetaToMonitor1,

    food_menu,
    setRestaurantMeta,

    restaurant_profile_pic_base64,
    setRestaurantProfilePicBase64,

    is_loading_restaurant_assets,
    setIsLoadingRestaurantAssets,
  };
}
