import React from 'react';
import { Box } from '@material-ui/core';
import LynkedLogoJsx from 'src/components/LynkedLogoJsx';

export default function SquareLinkedLogo({ width = 100, height = 100 }) {
  return (
    <>
      <Box
        style={{
          width: width,
          height: height,
        }}
      >
        <LynkedLogoJsx width={width} height={height} />
      </Box>
    </>
  );
}
