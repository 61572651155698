import { makeStyles } from '@material-ui/core';
import { LYNKED_BLACK, LYNKED_GREEN, LYNKED_LIGHT_GREY, LYNKED_WHITE } from 'src/consts/colors';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    display: 'flex',
    flexFlow: 'column',
  },
  global_restaurant_name: {
    backgroundColor: LYNKED_BLACK,
    color: LYNKED_WHITE,
    minHeight: '20px',
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',

    fontSize: '10px',
    lineHeight: '18px',

    textAlign: 'center',
    width: '100%',
  },
  restaurant_brand_plate_container: {
    backgroundColor: LYNKED_WHITE,
    color: LYNKED_BLACK,
    minHeight: '200px',
    boxShadow: '0px 3px 6px #00000029',
  },
  restaurant_brand_plate: {
    backgroundColor: LYNKED_WHITE,
    color: LYNKED_BLACK,
    minHeight: '200px',
    boxShadow: '0px 3px 6px #00000029',
  },
  send_lineup_request_button: {
    height: '47px',
    backgroundColor: LYNKED_GREEN,
    color: LYNKED_WHITE,
    width: '100%',
  },
  disabled: {
    height: '47px',
    backgroundColor: LYNKED_LIGHT_GREY,
    color: LYNKED_WHITE,
    width: '100%',
  },
  lineup_remarks_placeholder: {
    width: '100%',
    border: '1px solid #B3B3B3',
    borderRadius: '4px',
    padding: '0.5rem',
  },
  reservation_details: {
    height: '40px',
    paddingTop: '0.5rem',

    fontSize: '12px',
    lineHeight: '28px',

    textAlign: 'left',
    flexGrow: 1,
    backgroundColor: '#FAFAFA',
    padding: '0 1rem',
  },
  reservation_details_container: {
    marginLeft: '1rem',
    marginRight: '1rem',
    flexGrow: 1,

    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'space-between',
  },
  lineup_username_container: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'flex-start',
    marginTop: '1rem',
  },
  lineup_username: {
    width: '100%',
    border: '1px solid #B3B3B3',
    borderRadius: '4px',
    padding: '0.5rem',
  },
  lineup_table_number: {
    width: '100%',
    border: '1px solid #B3B3B3',
    borderRadius: '4px',
    padding: '0.5rem',
  },
  adult_child_seat_container: {
    display: 'flex',
    flexFlow: 'row',

    justifyContent: 'space-between',

    marginTop: '1rem',
    minHeight: '1rem',
  },
  adult_child_seat_container_inner: {
    width: '100%',

    display: 'flex',
    flexFlow: 'row',

    justifyContent: 'space-between',

    marginTop: '1rem',
  },
  adult_seat_container: {
    width: '45%',
    height: '100%',

    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',
  },
  child_seat_container: {
    width: '45%',
    height: '100%',

    display: 'flex',
    flexFlow: 'row',
    alignItems: 'center',
  },
  grid_item_vertical_center: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  grid_item_horizontal_center: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default useStyles;
