import React from 'react';
import { useSnackbar } from 'notistack';

import { MenyContext } from 'src/MenyApp/contexts';
import { monitorMenyServiceRequestByOrderHistoryId } from 'src/modals/service_request';
import active_lang from 'src/langs/jp_en';
import { SERVICE_REQUEST_CLEARED, SERVICE_REQUEST_NOT_CLEARED } from 'src/consts/SERVICE_REQUEST_STATE';
import { isDevelopment } from 'src/utils/isDevelopment';
import { useTranslation } from 'react-i18next';

let LOG_PREFIX = 'ServiceRequest';

export function ServiceRequest() {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation()
  let {
    setServiceRequestToMonitor1,
    rest_id,
    meny_client_state_machine,
    setServiceRequestNotCleared,
    service_request_not_cleared,
    service_request_cleared,
    setServiceRequestCleared,
    last_alert_cleared_count,
    setLastAlertClearedCount,
  } = React.useContext(MenyContext);

  let [current_client_state, sendClientState] = meny_client_state_machine;
  let { order_history_id } = current_client_state.context;

  const showPleaseWaitAsStaffWillBeOnTheirWayToAssistYou = () => {
    if (isDevelopment()) {
      enqueueSnackbar(t('OTHERS_STAFF_WILL_COME_MSG'), { variant: 'success' });
    } else {
      enqueueSnackbar(t('OTHERS_STAFF_WILL_COME_MSG'), { variant: 'success' });
    }
  };

  const handleGetLastAlertClearedCount = ({ rest_id }) => {
    let count = localStorage.getItem(`${rest_id}/last_alert_cleared_count`);
    return count ? parseInt(count) : 0;
  };

  const handleSetLastAlertClearedCount = (value) => {
    console.log(LOG_PREFIX, 'handleSetLastAlertClearedCount');
    localStorage.setItem(`${rest_id}/last_alert_cleared_count`, JSON.stringify(value));
  };

  React.useEffect(() => {
    handleSetLastAlertClearedCount(last_alert_cleared_count);
  }, [last_alert_cleared_count]);

  React.useEffect(() => {
    let count = handleGetLastAlertClearedCount({ rest_id });
    setLastAlertClearedCount(count);
  }, []);

  let [skip_prompt, setSkipPrompt] = React.useState(true);
  React.useEffect(() => {
    console.log(LOG_PREFIX, 'service_request_cleared', {
      last_alert_cleared_count,
      not_clear_count: service_request_not_cleared.state.length,
      clear_count: service_request_cleared.state.length,
      result: last_alert_cleared_count > service_request_not_cleared.state.length,
    });

    if (skip_prompt) {
      setSkipPrompt(false);
    } else {
      if (service_request_cleared.state.length > last_alert_cleared_count) {
        showPleaseWaitAsStaffWillBeOnTheirWayToAssistYou();
        setLastAlertClearedCount(service_request_cleared.state.length);
      } else {
        // consider: increase number of clear alert 0->1, 1->2
        setLastAlertClearedCount(service_request_cleared.state.length);
      }
    }
  }, [service_request_cleared]);

  React.useEffect(() => {
    // console.log(LOG_PREFIX, { rest_id, order_history_id });
    let unsub = monitorMenyServiceRequestByOrderHistoryId(rest_id, order_history_id, (ss) => {
      let not_cleared_service_request = [];
      let cleared_service_request = [];
      ss.docs.forEach((doc) => {
        console.log(LOG_PREFIX, doc.data());
        switch (true) {
          case doc.data().status == SERVICE_REQUEST_NOT_CLEARED:
            not_cleared_service_request.push(doc);
            break;

          case doc.data().status == SERVICE_REQUEST_CLEARED:
            cleared_service_request.push(doc);
            break;

          default:
            break;
        }
      });

      setServiceRequestNotCleared({ state: not_cleared_service_request });
      setServiceRequestCleared({ state: cleared_service_request });

      console.log(LOG_PREFIX, 'not_cleared_service_request', not_cleared_service_request);
      console.log(LOG_PREFIX, 'cleared_service_request', cleared_service_request);
    });

    return () => {
      if (unsub) unsub();
    };
  }, [order_history_id]);

  return <></>;
}
