import React from 'react';
import { Box } from '@material-ui/core';

import { MenyContext } from '../../contexts';
import { LYNKED_BLACK, LYNKED_WHITE } from 'src/consts/colors';

import RestaurantBarWithBackButton from '../RestaurantBarWithBackButton';

export default ({ handleBackOnClick }) => {
  let { restaurant_config } = React.useContext(MenyContext);
  let { name } = restaurant_config;

  return (
    <>
      <Box
        style={{
          backgroundColor: LYNKED_BLACK,
          color: LYNKED_WHITE,

          minHeight: '20px',
          width: '100%',

          display: 'flex',
          flexFlow: 'column',
          justifyContent: 'center',
          textAlign: 'center',

          fontSize: '10px',
          lineHeight: '18px',
        }}
      >
        {name}
      </Box>

      <Box
        style={{
          backgroundColor: LYNKED_WHITE,
          color: ' white',
          height: '100px',
          width: '100%',
          boxShadow: '0px 3px 6px #00000029',
        }}
      >
        <RestaurantBarWithBackButton handleBackOnClick={handleBackOnClick} />
      </Box>
    </>
  );
};
