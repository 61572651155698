import React from 'react';
import ShowYen from 'src/components/ShowYen';

import { LYNKED_DEEP_GREY, LYNKED_RED, LYNKED_WHITE } from 'src/consts/colors';
import isRestaurantClosed from '../../../utils/isRestaurantClosed';
import { MenyContext } from '../../../contexts';

import active_lang from 'src/langs/jp_en';
import { useTranslation } from 'react-i18next';

export default function ConfirmButton({ order_total }) {
  const { restaurant_config } = React.useContext(MenyContext);
  const { t } = useTranslation();

  return (
    <>
      <div
        style={{
          minHeight: '50px',
          width: '100%',
          display: 'flex',
          flexFlow: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          background: '0% 0% no-repeat padding-box',
          backgroundColor: isRestaurantClosed(restaurant_config) ? LYNKED_DEEP_GREY : LYNKED_RED,
          borderRadius: '5px',
          color: LYNKED_WHITE,

          margin: '0 1rem',
        }}
      >
        <div>{t('CART_ORDER_CONFIRM')}</div>
        <div style={{ paddingLeft: '1rem' }}>
          <ShowYen value_yen={order_total} restaurant_config={restaurant_config} />
        </div>
      </div>
    </>
  );
}
