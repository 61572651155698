import React from 'react';
import { useNavigate } from 'react-router-dom';

import { RESTAURANT_OPEN_STATE_PREPARING } from '../../../consts/RESTAURANT_OPEN_STATE';
import ShowDebug from 'src/components/ShowDebug';

import { MenyContext } from '..';

// import { LineupDocIdContext } from 'src/contexts/LocalStorageContext/LineupDocIdContext';
// import { AssignedTableContext } from 'src/contexts/LocalStorageContext/AssignedTableContext';
// import { OrderHistoryContext } from 'src/contexts/LocalStorageContext/OrderHistoryContext';
// import { AssignedTablePromptedAlreadyContext } from 'src/contexts/LocalStorageContext/AssignedTablePromptedAlreadyContext';
// import { SharedContext } from 'src/contexts/SharedContext';

import { getFoodMenuLandingLink, getNewUserLineupLink, isUserOnNewUserLineupPage } from 'src/utils/getLinks';

import {
  CLIENT_BILL_CLEARED,
  CLIENT_LANDING,
  CLIENT_TABLE_ASSIGNED,
  CLIENT_WAITING_TABLE,
  MONITORING,
  NOT_MONITORING,
  RESTAURANT_CLOSED,
  RESTAURANT_OPENED,
  RESTAURANT_STATUS_UNKNOWN,
} from './STATES';

import { RESTAURANT_OPEN_STATE_OPENED } from 'src/consts/RESTAURANT_OPEN_STATE';

const LOG_PREFIX = 'MenyClientStateMachineComponent';
let countRerender = 0;

export default function MenyClientStateMachine({ machines }) {
  let navigate = useNavigate();
  let {
    rest_id,
    menu_id,
    restaurant_config,
    setOpenTableAvailableDialog,
    setLocalClientState,
    loadAssignedTablePromptedAlreadyNumber,
    clearAssignedTablePromptedAlready,
    user_engage_state,
    USER_ENGAGED,
    setOpenRestaurantClosedSign,
    showRestaurantClosedSignAndRedirect,
    resetCartItems,
    order_history,
    clearLocalClientState,
    closeFoodDetailDialog,
    setOrderHistory,
    loadIsTheFirstTime,
    storeIsTheFirstTime,
    formInfo,
  } = React.useContext(MenyContext);

  let { meny_client_state_machine, monitor_order_status_machine, monitor_table_status_machine } = machines;
  let [client_state, sendClientState] = meny_client_state_machine;
  let [, sendMonitorTableStatusMachine] = monitor_table_status_machine;
  let [, sendMonitorOrderStatus] = monitor_order_status_machine;

  const isTableAvailablePrompted = () => loadAssignedTablePromptedAlreadyNumber()?.done === 1;
  const isTheFirstTime = () => loadIsTheFirstTime()?.isTheFirstTime;
  const resetAppState = () => {
    resetCartItems();
    clearAssignedTablePromptedAlready();
    clearLocalClientState();
  };

  const printClientState = () => {
    let { value, context } = client_state;
    console.log(LOG_PREFIX, 'client_state', { value, context });
  };

  const checkRestaurantOpenIdStillValid = () => {
    console.log(LOG_PREFIX, 'checkRestaurantOpenIdStillValid', {
      restaurant_config,
      client_state: client_state.context,
      check_1: restaurant_config.restaurant_open_id === client_state.context.restaurant_open_id,
      check_2: restaurant_config.restaurant_open_state === RESTAURANT_OPEN_STATE_OPENED,
    });
    let is_restaurant_open_valid = restaurant_config.restaurant_open_id === client_state.context.restaurant_open_id;
    let is_restaurant_still_open = restaurant_config.restaurant_open_state === RESTAURANT_OPEN_STATE_OPENED;
    return is_restaurant_open_valid && is_restaurant_still_open;
  };

  React.useEffect(() => {
    countRerender++;
    // only call 1 time when, calculate base on countRerender
    if (formInfo && formInfo?.status === 'SEAT_ASSIGNED' && countRerender === 1) {
      storeIsTheFirstTime(false);
    }
  }, [client_state]);

  React.useEffect(() => {
    printClientState();
    setLocalClientState(client_state);

    if (client_state.matches(CLIENT_WAITING_TABLE)) {
      if (checkRestaurantOpenIdStillValid()) {
        let { lineup_id, form_info } = client_state.context;
        sendMonitorTableStatusMachine(MONITORING, { lineup_id, rest_id, menu_id, form_info });

        console.log(LOG_PREFIX, '{rest_id, menu_id}', { rest_id, menu_id });

        // consider:
        // page refresh ?
        if (window.location.href.search('/NewUserlineup') > 0) {
          console.log(LOG_PREFIX, 'redirect client to food menu page');
          navigate(getFoodMenuLandingLink(rest_id, menu_id), { replace: true });
        }
      } else {
        console.log(LOG_PREFIX, 'CLIENT_WAITING_TABLE->user_engage_state', user_engage_state);
        // consder: user already on the screen ? MENY-429
        if (user_engage_state === USER_ENGAGED) {
          // show closed message but stay
          if (restaurant_config.restaurant_open_state == RESTAURANT_OPEN_STATE_PREPARING) {
            // consider:
            // client waiting table, restaurant close during client on the page
            showRestaurantClosedSignAndRedirect();
          }
        } else {
          // consideration:
          // check if restaurant open->close->open during user closed browser
          // console.log(LOG_PREFIX, 'Restaurant_open_id invalid, redirect to NewUserLineup');
          // sendClientState(RESTAURANT_STATUS_UNKNOWN);
          // navigate(getNewUserLineupLink(rest_id, menu_id), { replace: true });

          // consider: restaurant_id_invalid, user_not_engaged
          console.log(LOG_PREFIX, 'restaurant_id_invalid, user_not_engaged');
          if (isUserOnNewUserLineupPage()) {
            // consider:
            // restaurant open->close->open during client closed browser, client directly scan qr afterwards
            sendClientState(RESTAURANT_STATUS_UNKNOWN);
          } else {
            // consider:
            // restaurant open->close->open during client closed browser, client directly go food menu/order list/order history
            sendClientState(RESTAURANT_STATUS_UNKNOWN);
            setTimeout(() => {
              navigate(getNewUserLineupLink(rest_id, menu_id), { replace: true });
            }, 100);
          }
        }
      }
    } else if (client_state.matches(CLIENT_BILL_CLEARED)) {
      // consider: client finish bill
      setOrderHistory({});
      sendClientState(RESTAURANT_STATUS_UNKNOWN);
      navigate(getNewUserLineupLink(rest_id, menu_id), { replace: true });
    } else if (client_state.matches(CLIENT_LANDING)) {
      // consider:
      // init application on landing, remove old state
      setOrderHistory({});
      resetCartItems();
      clearAssignedTablePromptedAlready();

      // consider:
      // MENY-442, turn off monitoring if any
      sendMonitorTableStatusMachine(NOT_MONITORING);
      sendMonitorOrderStatus(NOT_MONITORING);

      // consider:
      // MENY-433, restaurant open->close->open during client disconnect, client close food detail and fallback to lineup page
      // MENY-441, client got table number assigned, client disconnect, restaurant open->close->open, client fallback to lineup page
      // MENY-440, restaurant close during client on the food detail page
      closeFoodDetailDialog();

      // consider:
      // if user fall into food menu page with client landing status
      if (!isUserOnNewUserLineupPage()) {
        // consider:
        // user lineup, restaurant open->close->open, user re-open browser,   customize for iphone
        resetAppState();
        sendClientState(RESTAURANT_STATUS_UNKNOWN);
        navigate(getNewUserLineupLink(rest_id, menu_id), { replace: true });
      }
    } else if (client_state.matches(CLIENT_TABLE_ASSIGNED)) {
      // consider:
      // client waiting table and close browser, restaurant open->close->open, client open browser again, MENY-431
      if (checkRestaurantOpenIdStillValid()) {
        let { lineup_id, order_history_id } = client_state.context;
        if (!isTableAvailablePrompted() && !restaurant_config.isSkippableToAssignTable && isTheFirstTime()) {
          setOpenTableAvailableDialog(true);
        }

        sendMonitorOrderStatus(MONITORING, { lineup_id, rest_id, menu_id, order_history_id });
        if (window.location.href.search('/NewUserlineup') > 0) {
          navigate(getFoodMenuLandingLink(rest_id, menu_id), { replace: true });
        }
      } else {
        // consder: user already on the screen ? MENY-429
        if (user_engage_state === USER_ENGAGED) {
          // consider: show only when restaurant open -> close
          // show closed message but stay
          if (restaurant_config.restaurant_open_state == RESTAURANT_OPEN_STATE_PREPARING) {
            // consider: if user have empty order history
            if (order_history.orders.length > 0) {
              setOpenRestaurantClosedSign(true);
            } else {
              showRestaurantClosedSignAndRedirect();
            }
          }
        } else {
          console.log(LOG_PREFIX, 'Restaurant_open_id invalid, order_history', order_history);

          if (order_history.orders.length > 0) {
            // consider:
            // restaurant_open_id invalid, user not engaged(entering / loading to the client page)
            // , order_history not empty, bill not cleared, MENY-437
            let { lineup_id, order_history_id } = client_state.context;
            sendMonitorOrderStatus(MONITORING, { lineup_id, rest_id, menu_id, order_history_id });

            // consider:
            // restaurant_open_id invalid, user not engaged(entering / loading new user lineup page)
            // , order_history not empty, bill not cleared, MENY-436
            if (window.location.href.search('/NewUserlineup') > 0) {
              navigate(getFoodMenuLandingLink(rest_id, menu_id), { replace: true });
            }
          } else {
            // consider:
            // MENY-431, consider client table assigned, client close browser, restaurant open->close->open, client open browser again, order list empty
            // showRestaurantClosedSignAndRedirect();
            sendClientState(RESTAURANT_STATUS_UNKNOWN);
            setTimeout(() => {
              navigate(getNewUserLineupLink(rest_id, menu_id), { replace: true });
            }, 100);
          }
        }
      }
    } else if (client_state.matches(RESTAURANT_CLOSED)) {
    } else if (client_state.matches(RESTAURANT_OPENED)) {
    } else if (client_state.matches(RESTAURANT_STATUS_UNKNOWN)) {
    } else {
    }
  }, [client_state, restaurant_config]);

  const restOpen = () => sendClientState(RESTAURANT_OPENED, { rest_id: 'opend_rest_id', menu_id: 'opend_menu_id' });
  const restClosed = () => sendClientState(RESTAURANT_CLOSED, { rest_id: 'opend_rest_id', menu_id: 'opend_menu_id' });

  const waitingTable = () => {
    sendClientState(CLIENT_WAITING_TABLE, {
      form_info: { hello: 'world' },
      lineup_id: 'waiting_table_lineup_id',
      rest_id: 'waiting_table_rest_id',
      menu_id: 'waiting_table_menu_id',
      restaurant_open_id: 'waiting_table_restaurant_open_id',
    });
  };

  const tableAssigned = () => sendClientState(CLIENT_TABLE_ASSIGNED, { table_assign_status: 'table_assign_status' });
  const billCleared = () => sendClientState(CLIENT_BILL_CLEARED, { table_assign_status: 'table_assign_status' });
  const clientLanding = () => sendClientState(CLIENT_LANDING);
  const restUnknown = () => sendClientState(RESTAURANT_STATUS_UNKNOWN);

  return (
    <>
      {/* <ShowDebug>
        <pre>{JSON.stringify({ rest_id, menu_id })}</pre>
        <button onClick={() => printClientState()}>printClientState</button>
        <button onClick={() => restUnknown()}>restUnknown</button>
        <button onClick={() => clientLanding()}>clientLanding</button>
        <button onClick={() => waitingTable()}>waitingTable</button>
        <button onClick={() => tableAssigned()}>tableAssigned</button>
        <button onClick={() => billCleared()}>billCleared</button>

        <button onClick={() => restClosed()}>restClosed</button>
        <button onClick={() => restOpen()}>restOpen</button>
      </ShowDebug> */}
    </>
  );
}
