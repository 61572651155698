import React from 'react';
import { Link as RouterLink, useMatch, useResolvedPath } from 'react-router-dom';

import { Box, Button } from '@material-ui/core';

import EventNoteOutlinedIcon from '@material-ui/icons/EventNoteOutlined';

import active_lang from 'src/langs/jp_en';
import { useStyles, StyledBadge } from './styles';

// import { OperationModesContext } from 'src/contexts/FoodMenuContext/OperationModesContext';

import { MenyContext } from '../../contexts';
import { getOrderListAdsLink } from 'src/utils/getLinks';
import classNames from 'classnames';
import { ReactComponent as Orders} from 'src/assets/icons/orders.svg';
import { useTranslation } from 'react-i18next';

const MAX_ORDER_LIST_SHOWN_IN_BADGE = 9;

export default function OrderListButton() {
  const classes = useStyles();
  // TODO: review
  // let { rest_id, menu_id } = useParams();
  let { rest_id, menu_id, num_of_cart_items } = React.useContext(MenyContext);
  const orderListAdsLink = getOrderListAdsLink(rest_id, menu_id);
  const resolved = useResolvedPath(orderListAdsLink);
  const match = useMatch({ path: resolved.pathname });
  const { t } = useTranslation();

  return (
    <Box>
      <RouterLink to={orderListAdsLink} style={{ textDecoration: 'none' }} replace>
        <Button id="button-order-list" className={classNames(classes.button, { active: match})}>
          <Box className={classes.button_style}>
            <Box p={0} m={0} className={classes.button_icon}>
              <StyledBadge badgeContent={num_of_cart_items} max={MAX_ORDER_LIST_SHOWN_IN_BADGE}>
              <Orders width={22} height={22} className="btn-icon" />
              </StyledBadge>
            </Box>
            <Box p={0} m={0}>
              {t('COMMON_ORDER_LIST')}
            </Box>
          </Box>
        </Button>
      </RouterLink>
    </Box>
  );
}
