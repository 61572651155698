import React from 'react';
import { isNull, isUndefined } from 'lodash';
import { Box } from '@material-ui/core';

import { STATUS_FOOD_SHOW } from 'src/modals/food_details';
import { isDefined } from 'src/utils/lodash-cust';
import { getTagIdFromTagName } from 'src/utils/getTagIdFromTagName';

import { MenyLightContext } from 'src/MenyLightApp/contexts';

import FoodMenuListingCategoryScrollBar from '../../components/FoodMenuListingCategoryScrollBar';
import FoodMenuBody from '../../components/FoodMenuBody';

import { LYNKED_BLACK, LYNKED_WHITE } from 'src/consts/colors';

import BackToTopButton from './BackToTopButton';
import FoodMenuAdWindow from './FoodMenuAdWindow';

export default () => {
  let food_list_ref = React.useRef(null);
  let [already_on_top, setAlreadyOnTop] = React.useState(true);
  const refreshFoodMenuAds = () => {};

  let { rest_id, menu_id, food_menu, restaurant_config } = React.useContext(MenyLightContext);

  const handleUpdateSelectedOnCategoryBar = (cat_in) => {
    setCategorySelected(cat_in);
  };

  let categories =
    food_menu &&
    food_menu.columnOrder.map((co) => {
      return {
        name: food_menu.columns[co].title,
        id: co,
        html_id: getTagIdFromTagName(co),
        category_show: food_menu.columns[co].category_show,
      };
    });

  let [category_selected, setCategorySelected] = React.useState(categories[0].name);

  let checkCategoryShowing = (co) =>
    isDefined(food_menu.columns[co]) &&
    (isUndefined(food_menu.columns[co].category_show) || food_menu.columns[co].category_show === STATUS_FOOD_SHOW);

  React.useEffect(() => {
    let visibility_status = Array(categories.length).fill(false);

    if (isNull(food_list_ref) || isNull(food_list_ref.current)) {
    } else {
      food_list_ref.current.addEventListener('scroll', () => {
        let el = food_list_ref.current;
        var rect = el.getBoundingClientRect();

        // food_menu.columnOrder.
        categories
          .map((c) => c.id)
          .forEach((co, idx) => {
            if (checkCategoryShowing(co)) {
              let tag_el = document.querySelector(`#${categories[idx].html_id}`);

              if (isNull(tag_el)) {
              } else {
                var rect_el = tag_el.getBoundingClientRect();
                if (rect_el.top >= rect.top) {
                  if (rect_el.bottom < rect.bottom) {
                    visibility_status[idx] = true;
                  } else {
                    visibility_status[idx] = false;
                  }
                } else {
                  visibility_status[idx] = false;
                }
              }
            }
          });

        for (let i = 0; i < categories.length; i++) {
          let last_item = categories.length;
          if (visibility_status[last_item] === true) {
            handleUpdateSelectedOnCategoryBar(categories[last_item].name);
            break;
          } else {
            let first_true_occurence = visibility_status.indexOf(true);
            try {
              handleUpdateSelectedOnCategoryBar(categories[first_true_occurence].name);
            } catch (error) {
              console.log('handleUpdateSelectedOnCategoryBar', 'error', error);
              console.log('handleUpdateSelectedOnCategoryBar', 'categories', categories);
            }
          }
        }

        if (food_list_ref.current.scrollTop > 30) {
          setAlreadyOnTop(false);
        } else {
          setAlreadyOnTop(true);
        }
      });
    }
  }, [food_list_ref]);

  return (
    <>
      <Box style={{ backgroundColor: LYNKED_WHITE }}>
        <FoodMenuAdWindow
          food_menu={food_menu}
          rest_id={rest_id}
          // MENY-257, refresh ads when tap back button
          refreshFoodMenuAds={refreshFoodMenuAds}
        />
      </Box>

      <Box
        style={{
          color: LYNKED_BLACK,

          display: 'flex',
          flexFlow: 'column',
          justifyContent: 'center',
          alignItems: 'center',

          height: '70px',
          minHeight: '70px',
        }}
      >
        {/* TODO: clear encryption / encrypt me local_menu_id */}
        <FoodMenuListingCategoryScrollBar
          list_ref={food_list_ref}
          menu_id={menu_id}
          category_selected={category_selected}
          setCategorySelected={setCategorySelected}
        />
      </Box>
      <div
        ref={food_list_ref}
        id={'food-list'}
        style={{
          flexGrow: '1',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        <FoodMenuBody
          food_menu={food_menu}
          list={categories}
          refreshFoodMenuAds={refreshFoodMenuAds}
          restaurant_config={restaurant_config}
        />
      </div>
      <BackToTopButton already_on_top={already_on_top} />
    </>
  );
};
