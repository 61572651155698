import React from 'react';
import { useQuery, useMutation } from 'react-query';

import config from 'src/config';

const useMutationIncreaseAdsImpressions = () => {
  let REST_MNGT_ENDPOINT = config.REST_MNGT_ENDPOINT;
  const dateToFormat = Date.now();
  return useMutation(['add_ads_impressions', dateToFormat], async (rest_id) => {
    const response = await fetch(
      `${REST_MNGT_ENDPOINT}/increaseAdsCounter?rest_id=${rest_id}`
    );

    if (!response.ok) {
      // throw new Error('Network response was not ok');
      console.log('error during adding ads impressions');
    }

    return response.json();
  });
};

export default useMutationIncreaseAdsImpressions;
