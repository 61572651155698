import React from 'react';

import ShowOrderListPage from './ShowOrderListPage';

export default () => {
  return (
    <>
      <ShowOrderListPage />
    </>
  );
};
