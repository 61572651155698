import React from 'react';

import ShowFoodMenuBody from './ShowFoodMenuBody';

import ListEnd from './ListEnd';

export default function FoodMenuBody({
  setAlreadyOnTop,
  food_menu,
  list,
  handleUpdateSelectedOnCategoryBar,
  food_menu_view_only,
  restaurant_config,
}) {
  return (
    <>
      <ShowFoodMenuBody
        setAlreadyOnTop={setAlreadyOnTop}
        food_menu={food_menu}
        list={list}
        categories={list}
        handleUpdateSelectedOnCategoryBar={handleUpdateSelectedOnCategoryBar}
        food_menu_view_only={food_menu_view_only}
        restaurant_config={restaurant_config}
      />
      <ListEnd />
    </>
  );
}
