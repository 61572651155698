import React, { useContext } from 'react';

import { MenyContext } from '..';

let LOG_PREFIX = 'OrderList';
export default () => {
  let { cart_items, setCartItems, loadCartItems, storeCartItems, is_init_done, updateNumOfFoodItemCount } =
    useContext(MenyContext);

  React.useEffect(() => {
    console.log(LOG_PREFIX, 'update order list');
    if (is_init_done) {
      storeCartItems(cart_items);
    }
    updateNumOfFoodItemCount(cart_items);
  }, [cart_items]);

  React.useEffect(() => {
    setCartItems(loadCartItems());
  }, []);

  return <></>;
};
