import React from 'react';
import { Box } from '@material-ui/core';
import LynkedLogoJsx from 'src/components/LynkedLogoJsx';

import { useStyles } from './styles';
import { MenyLightContext } from '../../contexts';

function RestaurantLogo() {
  const classes = useStyles();
  let { restaurant_profile_pic_base64 } = React.useContext(MenyLightContext);

  return (
    <>
      {restaurant_profile_pic_base64 ? (
        <>
          <Box style={{ height: '100px', width: '100%' }}>
            <Box
              id="restaurant-profile-pic"
              className={classes.headbar_content}
              style={{
                backgroundImage: `url('${restaurant_profile_pic_base64}')`,
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
              }}
            ></Box>
          </Box>
        </>
      ) : (
        <>
          <Box style={{ height: '100%' }}>
            <LynkedLogoJsx width="100%" height="100px" />
          </Box>
        </>
      )}
    </>
  );
}

export default React.memo(RestaurantLogo);
