import React from 'react';

import useMutationIncreaseMenyUsageCount from 'src/hooks/useMutationIncreaseMenyUsageCount';

import { MenyLightContext } from '../../contexts';

import ShowFoodMenuPage from './ShowFoodMenuPage';

const LOG_PREFIX = 'RestaurantIsActive';

export default function RestaurantIsActive({ show_reservation_page_directly, view_only }) {
  let { rest_id, menu_id, food_menu } = React.useContext(MenyLightContext);
  let mutation_increase_meny_usage_count = useMutationIncreaseMenyUsageCount();

  React.useEffect(() => {
    mutation_increase_meny_usage_count.mutateAsync(rest_id).then((res_json) => {
      console.log(LOG_PREFIX, 'res_json', res_json);
    });
  }, []);

  return (
    <>
      <ShowFoodMenuPage
        menu_id={menu_id}
        rest_id={rest_id}
        show_reservation_page_directly={show_reservation_page_directly}
        food_menu={food_menu}
        view_only={view_only}
      />
    </>
  );
}
