import React from 'react';

import MenyLayout from 'src/MenyApp/layouts/MenyLayout';

import FoodMenuLandingWithAdsPage from 'src/MenyApp/views/meny/FoodMenuLandingWithAdsPage';
import MenyNewUserlineupPage from 'src/MenyApp/views/meny/MenyNewUserlineupPage';
import MenyTechnicalSupportPage from 'src/MenyApp/views/meny/TechnicalSupportPage';
import OrderHistoryWithAdsPage from 'src/MenyApp/views/meny/OrderHistoryWithAdsPage';
import OrderListWithAdsPage from 'src/MenyApp/views/meny/OrderListWithAdsPage';
import RedirectToLineupPage from 'src/MenyApp/views/meny/RedirectToLineupPage';

import MenyContextProvider from 'src/MenyApp/contexts';
import ErrorsHandler from 'src/MenyApp/errors/ErrorsHandler';
import { I18nProvider } from 'src/utils/i18n';
import { MenyContext } from 'src/MenyApp/contexts';


const ENV_PUBLIC_URL = process.env.PUBLIC_URL;

const MenyApp = () => {
  return (
    <ErrorsHandler>
      <MenyContextProvider>
        <I18nProvider context={MenyContext}>
          <MenyLayout />
        </I18nProvider>
      </MenyContextProvider>
    </ErrorsHandler>
  );
};

const meny_routes = [
  {
    path: `${ENV_PUBLIC_URL}/meny/:rest_id/:menu_id`,
    element: <MenyApp />,

    children: [
      { path: '/NewUserlineup', element: <MenyNewUserlineupPage /> },
      { path: '/FoodMenuLandingAds', element: <FoodMenuLandingWithAdsPage /> },
      { path: '/OrderListAds', element: <OrderListWithAdsPage /> },
      { path: '/OrderHistoryAds', element: <OrderHistoryWithAdsPage /> },

      { path: '/*', element: <RedirectToLineupPage /> },
      { path: '/*', element: <MenyTechnicalSupportPage /> },
    ],
  },
];

export default meny_routes;
