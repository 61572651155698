import React from 'react';

import useMutationIncreaseMenyUsageCount from 'src/hooks/useMutationIncreaseMenyUsageCount';

export default function IncreaseMenyUsageCount({ rest_id }) {
  let mutation_increase_meny_usage_count = useMutationIncreaseMenyUsageCount();

  React.useEffect(() => {
    mutation_increase_meny_usage_count.mutateAsync(rest_id).then((res_json) => {
      console.log('mutation_increase_meny_usage_count', 'res_json', res_json);
    });
  }, []);

  return <></>;
}
