import React, { useContext } from 'react';

import { Box, IconButton, Grid, makeStyles } from '@material-ui/core';

import NotificationsIcon from '@material-ui/icons/Notifications';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

// MENY-237,meny restaurant help request is not working at client side
import ServiceRequestDialog from '../../views/meny/ServiceRequestDialog';
import RestaurantLogo from '../RestaurantLogo';

// import { GlobalContext } from 'src/contexts/global';
// import { StateMachineContext } from 'src/contexts/StateMachineContext';
// import { StateMachineContext } from 'src/contexts/StateMachineContext';
// import { SharedContext } from 'src/contexts/SharedContext';

import { CLIENT_TABLE_ASSIGNED } from 'src/StateMachines/STATES';

import { LYNKED_DEEP_GREY, BELL_COLOR_GREY, BELL_COLOR_ORANGE } from 'src/consts/colors';
import { MenyContext } from '../../contexts';

const useStyles = makeStyles(() => ({
  headbar_content: {
    height: '100%',
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

function RestaurantBar({ show_back_button, back_button_callback }) {
  const classes = useStyles();
  // let { service_requests_count } = React.useContext(GlobalContext);
  // let { current } = React.useContext(MenyClientStateMachineContext);

  let { meny_client_state_machine, service_request_not_cleared, restaurant_config } = React.useContext(MenyContext);
  let [current_meny_client_state_machine] = meny_client_state_machine;

  let [open_service_request_dialog, setOpenServiceRequestDialog] = React.useState(false);

  return (
    <>
      <ServiceRequestDialog open={open_service_request_dialog} setOpen={setOpenServiceRequestDialog} />
      <Grid container style={{ height: '100%' }}>
        <Grid item xs={3} style={{ height: '100%' }}>
          {show_back_button ? (
            <Box className={classes.headbar_content}>
              <IconButton id="back_to_food_menu_button" onClick={back_button_callback}>
                <ArrowBackIcon />
              </IconButton>
            </Box>
          ) : (
            <></>
          )}
        </Grid>
        <Grid item xs={6} style={{ height: '100%' }}>
          <RestaurantLogo />
        </Grid>
        <Grid item xs={3} style={{ height: '100%' }}>
          {restaurant_config.isEnableBellRing && (
            <Box className={classes.headbar_content} style={{ alignItems: 'flex-start' }}>
              <Box
                style={{
                  color: LYNKED_DEEP_GREY,

                  display: 'flex',
                  flexFlow: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',

                  whiteSpace: 'nowrap',

                  fontSize: '10px',
                  lineHeight: '18px',
                }}
              >
                <Grid container>
                  <Grid item xs={6} style={{ textAlign: 'right' }}></Grid>
                  <Grid item xs={6} style={{ textAlign: 'left' }}>
                    {current_meny_client_state_machine.matches(CLIENT_TABLE_ASSIGNED) ? (
                      <>
                        <IconButton
                          id="create_service_request_button"
                          onClick={() => setOpenServiceRequestDialog(true)}
                          style={{
                            padding: '0.25rem',
                            color: service_request_not_cleared.state.length > 0 ? BELL_COLOR_ORANGE : BELL_COLOR_GREY,
                          }}
                        >
                          <NotificationsIcon />
                        </IconButton>
                      </>
                    ) : (
                      <></>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default React.memo(RestaurantBar);
