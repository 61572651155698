import React from 'react';
import ShowDebug from 'src/components/ShowDebug';
import { useParams, useNavigate } from 'react-router';
import useQueryRestaurantConfig from 'src/hooks/useQueryRestaurantConfig';

import InitDone from './InitDone';

import FoodMenuSharedVar from './FoodMenuShare/var';
import FoodMenuShare from './FoodMenuShare';

import RestaurantAssetsSharedVar from './RestaurantAssets/shared_var';
import { RestaurantAssets } from './RestaurantAssets';

import LineupSharedVar from './Lineup/shared_var';
import { Lineup } from './Lineup';

import DialogsSharedVar from './Dialogs/shared_var';
import { Dialogs } from './Dialogs';

import { StateMachines } from './StateMachines';
import StateMachinesSharedVar from './StateMachines/shared_var';

import { Helloworld } from './Helloworld';
import HelloworldSharedVar from './Helloworld/shared_var';

import AdvertisementContextSharedVar from './AdvertisementContext/shared_var';
import { AdvertisementContext } from './AdvertisementContext';

import ServiceRequestSharedVar from './ServiceRequest/shared_var';
import { ServiceRequest } from './ServiceRequest';

import MonitorRestaurantConfigSharedVar from './MonitorRestaurantConfig/shared_var';
import MonitorRestaurantConfig from './MonitorRestaurantConfig';

import RestaurantClosedSharedVar from './RestaurantClosed/shared_var';
import RestaurantClosed from './RestaurantClosed';

import NetworkStatusSharedVar from './NetworkStatus/shared_var';
import NetworkStatus from './NetworkStatus';

import AcceptTAndCSharedVar from './AcceptTAndC/shared_var';
import AcceptTAndC from './AcceptTAndC';

import UserEngagedState from './UserEngagedState';
import UserEngagedStateSharedVar from './UserEngagedState/shared_var';

import PreLoadAsset from './PreLoadAsset';
import FetchDB from './FetchDB';
import OrderList from './OrderList';
import OrderListSharedVar from './OrderList/shared_var';
import useMutationSubscribeRestaurantConfig from 'src/hooks/useMutationSubscribeRestaurantConfig';
import { RESTAURANT_STATUS_UNKNOWN, CLIENT_BILL_CLEARED } from 'src/StateMachines/STATES';
import { getCurrentLineup } from 'src/modals/lineup';

const LOG_PREFIX = 'MenyContext';

export const MenyContext = React.createContext();

export default ({ children }) => {
  let { rest_id, menu_id } = useParams();
  let [restaurant_config, setRestaurantConfig] = React.useState();
  let [is_init_done, setIsInitDone] = React.useState(false);

  const navigate = useNavigate();
  const backToLineup = () => {
    navigate(`/meny/${rest_id}/${rest_id}/NewUserlineup`, { replace: true });
  };

  const resetClient = () => {
    sendClientState(RESTAURANT_STATUS_UNKNOWN);
    backToLineup();
  };
  const mutation_subscribe_restaurant_config = useMutationSubscribeRestaurantConfig();
  // consideration:
  // init get restaurant config, prefetch
  let { data } = useQueryRestaurantConfig(rest_id);

  let root_var = { is_init_done, setIsInitDone, rest_id, menu_id, restaurant_config, setRestaurantConfig };

  let shared_varirables = {
    ...FoodMenuSharedVar(root_var),
    ...StateMachinesSharedVar(root_var),
    ...RestaurantAssetsSharedVar(root_var),
    ...LineupSharedVar(root_var),
    ...DialogsSharedVar(root_var),
    ...AdvertisementContextSharedVar(root_var),
    ...HelloworldSharedVar(root_var),
    ...OrderListSharedVar(root_var),
    ...MonitorRestaurantConfigSharedVar(root_var),
    ...ServiceRequestSharedVar(root_var),
    ...RestaurantClosedSharedVar(root_var),
    ...NetworkStatusSharedVar(root_var),
    ...AcceptTAndCSharedVar(root_var),
    ...UserEngagedStateSharedVar(root_var),
    resetClient,
  };

  const { meny_client_state_machine } = { ...shared_varirables };
  const [client_state, sendClientState] = meny_client_state_machine;
  const { lineup_id, form_info } = client_state.context;

  const [formInfo, setFormInfo] = React.useState(form_info);

  const setLocalStorage = (key, value) => localStorage.setItem(key, JSON.stringify(value));

  React.useEffect(() => {
    if (rest_id) {
      mutation_subscribe_restaurant_config.mutateAsync(rest_id).then((res) => {
        res.onSnapshot({ includeMetadataChanges: true }, (doc) => {
          console.log('setRestaurantConfig', doc.data());
          setRestaurantConfig(doc.data());
        });
      });
    }
  }, [rest_id]);

  React.useEffect(() => {
    if (lineup_id && rest_id) {
      getCurrentLineup(rest_id, lineup_id).onSnapshot((doc) => {
        setFormInfo(doc.data());
        const clientState = {
          ...client_state,
          context: {
            ...client_state.context,
            form_info: { ...doc.data() },
            table_name: doc.data()?.assign_table_name,
          },
          value: doc.data()?.status === 'SEAT_ASSIGNED' ? 'CLIENT_TABLE_ASSIGNED' : client_state.value,
        };

        if (client_state.value === CLIENT_BILL_CLEARED) {
          clientState.value = CLIENT_BILL_CLEARED;
        }

        setLocalStorage(`${rest_id}/client_state`, clientState);
      });
    }
  }, [lineup_id, rest_id, client_state]);

  return (
    <>
      {/* <ShowDebug>
        <pre>{JSON.stringify({ rest_id, menu_id })}</pre>
        <button onClick={resetClient}>resetClient</button>
      </ShowDebug> */}
      <MenyContext.Provider value={{ ...root_var, ...shared_varirables, formInfo }}>
        <RestaurantAssets />
        <FoodMenuShare />
        <MonitorRestaurantConfig />
        <Lineup />
        <ServiceRequest />
        <Dialogs />
        <AdvertisementContext />
        <Helloworld />
        <OrderList />
        <RestaurantClosed />
        <NetworkStatus />
        <AcceptTAndC />
        <UserEngagedState />

        <PreLoadAsset>
          <FetchDB>
            <StateMachines />
            <InitDone>{children}</InitDone>
          </FetchDB>
        </PreLoadAsset>
      </MenyContext.Provider>
    </>
  );
};
