import React from 'react';

import LoadAdWindow from './LoadAdWindow';
import { MenyContext } from '../../contexts';

export default ({ food_menu }) => {
  let { advertisement_config } = React.useContext(MenyContext);
  let food_menu_ad = advertisement_config?.filter((ad) => ad.data.ad_window.name === 'order_list')[0]?.data;

  if (food_menu_ad) return <LoadAdWindow ads_info={food_menu_ad} food_menu={food_menu} />;

  return <></>;
};
