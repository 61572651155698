import React from 'react';

import { Box, Grid, IconButton } from '@material-ui/core';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import NotificationsIcon from '@material-ui/icons/Notifications';

import ServiceRequestDialog from 'src/MenyApp/views/meny/ServiceRequestDialog';
import { CLIENT_TABLE_ASSIGNED } from 'src/StateMachines/STATES';
import { LYNKED_DEEP_GREY, BELL_COLOR_GREY, BELL_COLOR_ORANGE } from 'src/consts/colors';

import { useStyles } from './styles';
import { MenyContext } from '../../contexts';
import RestaurantLogo from '../RestaurantLogo';

export default function RestaurantBarWithBackButton({ handleBackOnClick }) {
  const classes = useStyles();
  let { meny_client_state_machine, service_request_not_cleared, restaurant_config } = React.useContext(MenyContext);

  let [open_service_request_dialog, setOpenServiceRequestDialog] = React.useState(false);

  let [current_meny_client_state_machine] = meny_client_state_machine;

  return (
    <>
      <ServiceRequestDialog open={open_service_request_dialog} setOpen={setOpenServiceRequestDialog} />
      <Grid container style={{ height: '100%' }}>
        <Grid item xs={3} style={{ height: '100%' }}>
          <Box
            style={{
              display: 'flex',
              flexFlow: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100px',
            }}
          >
            <IconButton id={'back-to-food-menu-button'} onClick={handleBackOnClick}>
              <ArrowBackRoundedIcon />
            </IconButton>
          </Box>
        </Grid>
        <Grid
          item
          xs={6}
          style={{
            height: '100%',
            width: '100%',
            display: 'flex',
            flexFlow: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <RestaurantLogo />
        </Grid>
        <Grid item xs={3} style={{ height: '100%' }}>
          {restaurant_config.isEnableBellRing && (
            <Box className={classes.headbar_content} style={{ alignItems: 'flex-start' }}>
              <Box
                style={{
                  color: LYNKED_DEEP_GREY,
                  display: 'flex',
                  flexFlow: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  whiteSpace: 'nowrap',
                  fontSize: '10px',
                  lineHeight: '18px',
                  textAlign: 'center',
                }}
              >
                <Grid container>
                  <Grid item xs={6} style={{ textAlign: 'right' }}></Grid>
                  <Grid item xs={6} style={{ textAlign: 'left' }}>
                    {current_meny_client_state_machine.matches(CLIENT_TABLE_ASSIGNED) ? (
                      <>
                        <IconButton
                          id="create_service_request_button_food_detail"
                          onClick={() => setOpenServiceRequestDialog(true)}
                          style={{
                            padding: '0.25rem',
                            color: service_request_not_cleared.state.length > 0 ? BELL_COLOR_ORANGE : BELL_COLOR_GREY,
                          }}
                        >
                          <NotificationsIcon />
                        </IconButton>
                      </>
                    ) : (
                      <></>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>
    </>
  );
}
