import { Badge, makeStyles, withStyles } from '@material-ui/core';

import { LYNKED_RED, LYNKED_WHITE } from 'src/consts/colors';

const StyledBadge = withStyles(() => ({
  root: {},
  badge: {
    backgroundColor: LYNKED_RED,
    color: LYNKED_WHITE,
    // height: '16px',
    // minWidth: '16px',
    padding: '3px 3px',
  },
}))(Badge);

const useStyles = makeStyles(() => ({
  bottom_button_bar_style: {
    height: '75px',
    width: '100%',
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    color: '#9A9A9A',
    '&.active': {
      position: 'relative',
      color: 'black',

      '&::before': {
          content: '""',
          display: 'block',
          height: 5,
          width: '100%',
          backgroundColor: 'black',
          position: 'absolute',
          top: 0,
          left: 0,
          borderRadius: '0 0 10px 10px'
        },
      '& .btn-icon': {
        color: 'black'
      }
    },
    '&.green': {
      color: '#378D01'
    },
    '&.active.green': {
      color: '#378D01',

      '&::before': {
          backgroundColor: '#378D01',
        },
      '& .btn-icon': {
        color: '#378D01',
      }
    },
  },
  button_style: {
    display: 'inline-flex',
    flexFlow: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button_icon: {
    height: '31px',
    minHeight: '31px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%'
  },
  
}));

export { useStyles, StyledBadge };
