import React from 'react';
import { Box } from '@material-ui/core';

export default function ShowFoodTag({ tag_name, id }) {
  return (
    <Box
      id={id}
      style={{
        whiteSpace: 'nowrap',
        lineHeight: '28px',
        paddingTop: '0.25rem',
        paddingBottom: '0.25rem',
        paddingLeft: '0.5rem',
        textAlign: 'left',
        fontFamily: 'Noto Sans JP',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '20px',
        color: 'black',
        backgroundColor: '#fafafa'
      }}
    >
      {tag_name}
    </Box>
  );
}
