import React from 'react';
import { isUndefined } from 'lodash';
import { Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import ShowYen from 'src/components/ShowYen';
import SoldOutLabel from '../../components/SoldOutLabel';
import FoodDetailDialog from '../../components/FoodDetailDialog';

import { STATUS_SOLD_OUT } from 'src/consts/SoldOutStatus';
import { LYNKED_DEEP_DEEP_GREY, LYNKED_LIGHT_GREY } from 'src/consts/colors';

let initial_render = true;

const useStyles = makeStyles(() => ({
  order_button_enabled_style: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: '0px',
  },
  order_button_disabled_style: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: '0px',
  },
  food_image_sold_out_style: {
    opacity: '0.25',
  },
}));

export default function ShowFoodEntry({ food_id, food_entry, idx, refreshFoodMenuAds, view_only, restaurant_config }) {
  const classes = useStyles();

  const [open_food_detail_dialog, setOpenFoodDetailDialog] = React.useState(false);

  const [food_sold_out, setFoodSoldOut] = React.useState(false);
  const [order_button_enable, setOrderButtonEnable] = React.useState(true);

  const checkFoodEntryValid = () => {
    if (food_entry && food_entry.name) return true;
    return false;
  };

  const handleFoodOnClick = () => {
    // alert('hello handleFoodOnClick')
    setOpenFoodDetailDialog(true);
  };

  const enableOrderButton = () => {
    setFoodSoldOut(false);
    setOrderButtonEnable(true);
  };

  const disableOrderButton = () => {
    setFoodSoldOut(true);
    setOrderButtonEnable(false);
  };

  React.useEffect(() => {
    if (!isUndefined(food_entry.sold_out)) {
      if ([STATUS_SOLD_OUT].includes(food_entry.sold_out)) {
        disableOrderButton();
      } else {
        enableOrderButton();
      }
    } else {
      enableOrderButton();
    }
  }, [food_entry]);

  React.useEffect(() => {
    if (initial_render) {
      initial_render = false;
    }
  }, []);

  if (!checkFoodEntryValid()) {
    console.log('checkFoodEntryValid failed food_id', food_id);
    console.log('checkFoodEntryValid failed food_entry', food_entry);
    return <></>;
  }

  return (
    <>
      <FoodDetailDialog
        open={open_food_detail_dialog}
        setOpen={setOpenFoodDetailDialog}
        food_entry={food_entry}
        refreshFoodMenuAds={refreshFoodMenuAds}
        initial_render={initial_render}
        restaurant_config={restaurant_config}
        view_only={view_only}
      />
      <Box
        style={{
          minHeight: '75px',
          display: 'flex',
          flexFlow: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          border: '1px solid #ecf0f1',
          fontFamily: 'Noto Sans JP',
        }}
      >
        <Button
          id={`food_list_${idx}`}
          key={idx}
          className={order_button_enable ? classes.order_button_enabled_style : classes.order_button_disabled_style}
          onClick={handleFoodOnClick}
        >
          <Box
            style={{
              textAlign: 'left',
              width: '66vw',
            }}
          >
            <Box
              style={{
                whiteSpace: 'nowrap',
                lineHeight: '18px',
                textAlign: 'left',

                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '12px',
                color: food_sold_out ? LYNKED_LIGHT_GREY : LYNKED_DEEP_DEEP_GREY,
                paddingRight: '10px',
              }}
            >
              <Box style={{ whiteSpace: 'normal', color: 'black', fontWeight: 'bold' }}>{food_entry.name}</Box>
              <Box style={{ whiteSpace: 'normal' }}>{food_entry.long_name}</Box>
              <Box mt="0.25rem">
                <ShowYen value_yen={food_entry.unit_price} restaurant_config={restaurant_config} />
              </Box>
            </Box>
          </Box>

          <div style={{ width: '110px', height: '68px', paddingRight: '20px' }}>
            <Box
              id={`food-entry-image-${idx}`}
              className={food_sold_out ? classes.food_image_sold_out_style : ''}
              style={{
                width: '100%',
                height: '100%',
                borderRadius: '3px',
                backgroundImage: `url(${food_entry.image_url})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'right center',
                // backgroundColor: 'gold',

                display: 'flex',
                flexFlow: 'row',
                justifyContent: 'flex-end',
                alignItems: 'flex-start',
              }}
            ></Box>
            {food_sold_out ? (
              <div style={{ position: 'absolute', top: '20px', paddingLeft: '60px', opacity: '1' }}>
                <SoldOutLabel />
              </div>
            ) : (
              <></>
            )}
          </div>
        </Button>
      </Box>
    </>
  );
}
