import React from 'react';

import { MenyLightContext } from '../../contexts';

import ShowDebug from 'src/components/ShowDebug';

import FoodMenuListingPageAdTapToFoodDetail from '../../components/FoodMenuListingPageAdTapToFoodDetail';
import FoodMenuListingPageAdTapToHighlightCategories from '../../components/FoodMenuListingPageAdTapToHighlightCategories';

import { select_values } from 'src/modals/advertisement_config';

const LOG_PREFIX = 'FoodMenuAdWindow';

export default () => {
  const { food_menu, advertisement_config } = React.useContext(MenyLightContext);

  const food_menu_ads_config = advertisement_config.filter((ads) => ads.data.ad_window.name === 'food_menu');

  if (food_menu_ads_config.length > 0) {
    let config = food_menu_ads_config[0].data;

    console.log(LOG_PREFIX, config);

    switch (config.ad_action.name) {
      case select_values.ad_action.FOOD_MENU.name:
        return <FoodMenuListingPageAdTapToHighlightCategories config={config} />;

      case select_values.ad_action.FOOD_DETAIL.name:
        return <FoodMenuListingPageAdTapToFoodDetail config={config} food_menu={food_menu} />;

      default:
        break;
    }
  }

  return (
    <>
      <ShowDebug>no food menu ads configured</ShowDebug>
    </>
  );
};
