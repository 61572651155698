import React from 'react';
import { Button } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';

import { QueryClient, QueryClientProvider } from 'react-query';
import { isDevelopment } from 'src/utils/isDevelopment';

const queryClient = new QueryClient();

export const ThirdPartyLibContext = React.createContext({});

export function ThirdPartyLibProvider({ children }) {
  const notistackRef = React.createRef();
  const alert_duration = isDevelopment() ? null : 3000;

  const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <SnackbarProvider
          ref={notistackRef}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          autoHideDuration={alert_duration}
          maxSnack={2}
          action={(key) => {
            if (isDevelopment()) {
              return (
                <Button id={'dismiss-alert-button'} onClick={onClickDismiss(key)}>
                  X
                </Button>
              );
            } else return null;
          }}
        >
          {children}
        </SnackbarProvider>
      </QueryClientProvider>
    </>
  );
}
