import React from 'react';

import moment from 'moment-timezone';

import ShowYen from 'src/components/ShowYen';

import { LYNKED_WHITE, LYNKED_DEEP_GREY, LYNKED_RED, LYNKED_BLACK } from 'src/consts/colors';

import active_lang from 'src/langs/jp_en';
import { Grid } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

import getAdjustedItemPrice from 'src/utils/getAdjustedItemPrice';
import { useTranslation } from 'react-i18next';

import './Entry.css';

const useStyles = makeStyles((theme) => ({
  clipLoader: {
    display: 'block',
    margin: '0 auto',
    borderColor: LYNKED_RED,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  price: {
    // font: 'normal normal normal 12px/18px Noto Sans JP',
    fontSize: '12px',
    lineHeight: '18px',
    color: LYNKED_RED,
    width: '25%',
    textAlign: 'right',
    textDecoration: 'line-through',
  },
  customize_settings: {
    // font: 'normal normal normal 12px/18px Noto Sans JP',
    fontSize: '12px',
    lineHeight: '18px',
    textAlign: 'left',
    textDecoration: 'line-through',
    paddingLeft: '1rem',
  },
}));

export default function BlackEntry({ entry, restaurant_config }) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      {/* <pre>{JSON.stringify(entry, null, 2)}</pre> */}
      <div style={{ padding: '1rem 0', borderBottom: '1px solid #ebebeb' }}>
        <Grid container>
          <Grid item xs={8}>
            <Grid container>
              <Grid
                item
                xs={3}
                style={{
                  display: 'flex',
                  flexFlow: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    // font: 'normal normal normal 7px/10px Noto Sans JP',
                    fontSize: '7px',
                    lineHeight: '10px',

                    borderRadius: '2px',
                    background: '0% 0% no-repeat padding-box',
                    backgroundColor: LYNKED_BLACK,
                    width: '50px',
                    height: '11px',
                    display: 'inline-flex',
                    flexFlow: 'column',
                    justifyContent: 'center',
                    color: LYNKED_WHITE,
                  }}
                >
                  <div>{t('ORDER_HISTORY_CANCEL')}</div>
                </div>
              </Grid>
              <Grid
                item
                xs={9}
                style={{
                  display: 'flex',
                  flexFlow: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    // font: 'normal normal normal 9px/18px Noto Sans JP',
                    fontSize: '9px',
                    lineHeight: '18px',

                    color: LYNKED_DEEP_GREY,
                    paddingLeft: '1rem',
                  }}
                >
                  {t('ORDER_HISTORY_ORDER_ACCEPTED')}:{'  '}
                  {entry ? <>{moment.unix(entry.created.seconds).format(t('ORDER_TIME_FORMAT'))}</> : <></>}
                </div>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  <div
                    style={{
                      // font: 'normal normal normal 12px/18px Noto Sans JP',
                      fontSize: '12px',
                      lineHeight: '18px',
                      textAlign: 'left',
                      textDecoration: 'line-through',
                    }}
                  >
                    {entry ? <>{entry.item_name}</> : <></>}
                  </div>
                </Grid>
              </Grid>
              <Grid container>
                {entry &&
                  entry.item_customize_settings.map((cust_setting) => (
                    <>
                      <Grid item xs={12} className={classes.customize_settings}>
                        <span class="icon-invalid twitter"></span>
                        <span>{'     '}</span>
                        <span>{`${cust_setting.name}`}</span>
                      </Grid>
                    </>
                  ))}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4} className={classes.price}>
            {entry ? (
              <>
                <ShowYen value_yen={getAdjustedItemPrice(entry.item_price, entry.item_customize_settings)} restaurant_config={restaurant_config} /> x{' '}
                {entry.item_quantity}{t('ORDER_HISTORY_POINT')}
              </>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      </div>
    </>
  );
}
