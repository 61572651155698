import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { LYNKED_LIGHT_GREY_HOVER } from 'src/consts/colors';
import ShowYen from 'src/components/ShowYen';

export default ({ food_entry, restaurant_config }) => {
  const getTaxFoodDetail = () => {
    if (!restaurant_config?.taxFoodDetail) 
      return '(税込)';
    if (!restaurant_config.taxFoodDetail.trim())
      return "";
    return `(${restaurant_config.taxFoodDetail})`;
  }

  return (
    <>
      <Box
        style={{
          width: '100%',
          borderBottom: `1px solid ${LYNKED_LIGHT_GREY_HOVER}`,
          fontWeight: 'bold',
        }}
      >
        <Grid container spacing={2} style={{ padding: '1rem' }}>
          <Grid item xs={8} style={{ whiteSpace: 'break-spaces' }}>
            <Box>
              <Grid container>
                <Grid item xs={12}>
                  {food_entry.name}
                </Grid>
                <Grid item xs={12}>
                  {food_entry.long_name}
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={4} style={{ textAlign: 'right' }}>
            <Box
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexFlow: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Box
                style={{
                  width: '100%',
                  display: 'flex',
                  flexFlow: 'row',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                <ShowYen value_yen={food_entry.unit_price} restaurant_config={restaurant_config} />{' '}
                {getTaxFoodDetail()}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
