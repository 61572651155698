import React from 'react';
import { Box, Button } from '@material-ui/core';

import { LYNKED_RED, LYNKED_WHITE } from 'src/consts/colors';

import LynkedLogoSvg from 'src/components/LynkedLogoSvg';
import { useTranslation } from 'react-i18next';

import background_jpg from './background.jpg';
import meny_logo_webp from './meny_logo.webp';

let CLOSE_THIS_PAGE = 'ページを閉じる';

export default function RestaurantIsNotActive() {
  const { t } = useTranslation();

  return (
    <>
      <Box
        style={{
          backgroundColor: LYNKED_WHITE,
          width: '100vw',
          height: '100vh',
          backgroundImage: `url(${background_jpg})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <Box
          style={{
            paddingTop: '1rem',
            paddingBottom: '7rem',
          }}
        >
          <Box
            style={{
              width: '100px',
              height: '100px',
              backgroundImage: `url(${meny_logo_webp})`,
              backgroundSize: 'contain',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              margin: 'auto',
            }}
          ></Box>
        </Box>

        <Box
          style={{
            fontStyle: 'normal',
            fontWeight: 'bold',
            fontSize: '30px',
            lineHeight: '37px',
            textAlign: 'center',
            paddingBottom: '1rem',
          }}
        >
          {t('OTHERS_SORRY')}
        </Box>

        <Box
          style={{
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '16px',
            lineHeight: '24px',
            textAlign: 'center',

            paddingBottom: '5rem',
          }}
        >
          {t('OTHERS_MENY_CANNOT_USE')}
        </Box>

        <div style={{ display: 'none' }}>
          <Box
            style={{
              display: 'flex',
              flexFlow: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Button
              style={{
                backgroundColor: LYNKED_RED,
                color: LYNKED_WHITE,
                borderRadius: '15px',
                padding: '10px 20px',
              }}
              onClick={() => window.close()}
            >
              <Box>{CLOSE_THIS_PAGE}</Box>
            </Button>
          </Box>
        </div>

        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',

            position: 'absolute',
            left: '0',
            right: '0',
            margin: 'auto',
            bottom: '1rem',
          }}
        >
          <Box
            style={{
              fontStyle: 'normal',
              fontWeight: '600',
              fontSize: '10px',
              lineHeight: '96.4%',
              textAlign: 'center',
              paddingRight: '0.5rem',
            }}
          >
            Powered by
          </Box>
          <Box style={{ width: '100px', height: '20px' }}>
            <LynkedLogoSvg />
          </Box>
        </Box>
      </Box>
    </>
  );
}
