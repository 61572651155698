import { useMutation } from 'react-query';
import { subscribeFoodMenuChange } from 'src/modals/food_menu';

export default function useMutationSubscribeFoodMenu() {
  const mutation = useMutation(
    (rest_id) => {
      return subscribeFoodMenuChange(rest_id);
    },
    {
      retry: 10,
    }
  );

  return mutation;
}
