import React from 'react';
import { useSnackbar } from 'notistack';

import { Box, Button, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core';

import AlarmIconJsx from './AlarmIcon';

import active_lang from 'src/langs/jp_en';

import useMutationAddServiceRequest from 'src/hooks/useMutationAddServiceRequest';

import { useStyles } from './style';

import { MenyLightContext } from '../../contexts';
import ShowDebug from 'src/components/ShowDebug';
import { useTranslation } from 'react-i18next';

const LOG_PREFIX = 'ServiceRequestDialog';

export default function DialogBody({ handleBackOnClick, setOpen }) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  let { menu_id, rest_id, table_name } = React.useContext(MenyLightContext);

  const handleCloseOnClick = () => {
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
  };

  let mutationAddServiceRequest = useMutationAddServiceRequest();

  const handleSendServiceRequest = () => {
    // NOTES: order_history_id set to empty for meny-light restaurant
    mutationAddServiceRequest
      .mutateAsync({
        rest_id: rest_id,
        table_name: table_name,
        username: '',
        order_history_id: '',
      })
      .then((service_request_doc) => {
        let service_request_id = service_request_doc.id;

        // MENY-256, update fixing request monitor missing after browser refresh
        enqueueSnackbar(t('OTHERS_CALL_COMPLETED'), { variant: 'success' });
        console.log(LOG_PREFIX, 'handleSetMonitorServiceRequests', { service_request_id });
      })
      .catch((err) => {
        console.error(LOG_PREFIX, 'handleSendServiceRequest', { err });
      })
      .finally(() => {});

    return handleBackOnClick();
  };

  return (
    <>
      {/* <ShowDebug>{JSON.stringify({ rest_id, menu_id, table_name })}</ShowDebug> */}

      <DialogTitle id="alert-dialog-slide-title" style={{ textAlign: 'center' }}>
        <Typography variant="h3" color="textPrimary" style={{ fontSize: '23.4px', fontWeight: '700' }}>
          {t('OTHERS_CALL_REQUEST')}
        </Typography>
      </DialogTitle>
      <DialogContent style={{ textAlign: 'center' }}>
        <Box style={{ display: 'inline-flex', flexFlow: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <Box style={{ width: '50px', height: '50px' }}>
            <AlarmIconJsx width="100%" height="50px" />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center' }}>
        <Box style={{ paddingTop: '2rem' }}>
          <Button id="button-cancel" onClick={handleCloseOnClick} className={classes.button_no}>
            {t('OTHERS_NO')}
          </Button>
          <Button id="button-ok" onClick={handleSendServiceRequest} className={classes.button_yes}>
            {t('OTHERS_YES')}
          </Button>
        </Box>
      </DialogActions>
    </>
  );
}
