import firebase from 'firebase';

import { getDecryptedUserUid } from '../utils/getDecryptedUserUid';

import db_config from 'src/configs/db_config';
import db from 'src/Firebase/db';

function addOrderToRestaurant(encrypted_rest_id, doc_id, order_to_add) {
  let rest_id = getDecryptedUserUid(encrypted_rest_id);

  console.log('addOrderToRestaurant', 'rest_id', rest_id);
  console.log('addOrderToRestaurant', 'doc_id', doc_id);
  console.log('addOrderToRestaurant', 'order_to_add', order_to_add);

  if (doc_id == null) {
    // if the doc_id not exist, create new one
    return db
      .collection(db_config.DB_TABLE_ALL_ORDERS)
      .doc(rest_id)
      .collection('orders')
      .add({
        ...order_to_add,
        status: 'NOT_CLEARED',
        order_create_time: firebase.firestore.FieldValue.serverTimestamp(),
      })
      .then((docRef) => {
        console.log('addOrderToRestaurant', 'order add done');
        console.log('docRef', docRef.id);
        return docRef.id;
      });
  } else {
    // if the doc_id already exist, append it

    let order_to_add_list = order_to_add.order;

    let promise = new Promise(function (resolve, reject) {
      setTimeout(() => reject(doc_id), 10000);
      for (let i = 0; i < order_to_add_list.length; i++) {
        let order = order_to_add_list[i];
        let DocRef = db.collection(db_config.DB_TABLE_ALL_ORDERS).doc(rest_id).collection('orders').doc(doc_id);

        return db
          .runTransaction((transaction) => {
            return transaction.get(DocRef).then((sfDoc) => {
              // transaction.set(DocRef, payload, { merge: true });
              transaction.update(DocRef, {
                order: firebase.firestore.FieldValue.arrayUnion(order),
              });
            });
          })
          .then(() => {
            console.log('Transaction successfully committed!');
          })
          .catch((error) => {
            console.log('Transaction failed: ', error);
          });
      }
      return resolve(doc_id);
    });
    return promise;
  }
}

function updateMenyOrder(rest_id, doc_id, payload) {
  console.log('updateMenyOrder', 'triggered');
  console.log('updateMenyOrder', 'rest_id', rest_id);
  console.log('updateMenyOrder', 'doc_id', doc_id);
  console.log('updateMenyOrder', 'payload', payload);

  let DocRef = db.collection(db_config.DB_TABLE_ALL_ORDERS).doc(rest_id).collection('orders').doc(doc_id);

  return db
    .runTransaction((transaction) => {
      return transaction.get(DocRef).then((sfDoc) => {
        transaction.set(DocRef, payload, { merge: true });
      });
    })
    .then(() => {
      console.log('Transaction successfully committed!');
    })
    .catch((error) => {
      console.log('Transaction failed: ', error);
    });
}

function addNewMenyOrderToRestaurant(rest_id, doc_id, payload) {
  let DocRef = db.collection(db_config.DB_TABLE_ALL_ORDERS).doc(rest_id).collection('orders').doc(doc_id);

  return db
    .runTransaction((transaction) => {
      return transaction.get(DocRef).then((sfDoc) => {
        transaction.set(DocRef, payload, { merge: true });
      });
    })
    .then(() => {
      console.log('Transaction successfully committed!');
    })
    .catch((error) => {
      console.log('Transaction failed: ', error);
    });
}

function addMenyOrderToRestaurant(doc_id, payload) {
  let DocRef = db.collection(db_config.DB_TABLE_ALL_ORDERS).doc(doc_id);

  return db
    .runTransaction((transaction) => {
      return transaction.get(DocRef).then((sfDoc) => {
        transaction.set(DocRef, payload, { merge: true });
      });
    })
    .then(() => {
      console.log('Transaction successfully committed!');
    })
    .catch((error) => {
      console.log('Transaction failed: ', error);
    });
}

function getNewOrderId(rest_id) {
  return db.collection(db_config.DB_TABLE_ALL_ORDERS).doc(rest_id).collection('orders').doc();
}

function modals_order_helloworld() {
  console.log('modals_order_helloworld');
}

function order_history_helloworld() {
  alert('order_history_helloworld helloworld');
}

function getOrderHistoryByOrderHistoryId(rest_id, order_history_id) {
  return db.collection(db_config.DB_TABLE_ALL_ORDERS).doc(rest_id).collection('orders').doc(order_history_id).get();
}

function monitorOrderChange(rest_id, order_id, cb) {
  return db
    .collection(db_config.DB_TABLE_ALL_ORDERS)
    .doc(rest_id)
    .collection('orders')
    .doc(order_id)
    .onSnapshot((doc) => {
      console.log('order.js', 'monitorOrderChange', 'update received');
      cb(doc);
    });
}

export {
  monitorOrderChange,
  order_history_helloworld,
  modals_order_helloworld,
  addOrderToRestaurant,
  getNewOrderId,
  addMenyOrderToRestaurant,
  addNewMenyOrderToRestaurant,
  updateMenyOrder,
  getOrderHistoryByOrderHistoryId,
};
