import React from 'react';

import { Box, Grid } from '@material-ui/core';

import { useStyles } from './styles';

import FoodMenuButton from './FoodMenuButton';
import OrderListButton from './OrderListButton';
import OrderHistoryButton from './OrderHistoryButton';

export default function BottomBarWithAds() {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.bottom_button_bar_style}>
        <Grid container>
          <Grid item xs={4}>
            <FoodMenuButton />
          </Grid>
          <Grid item xs={4}>
            <OrderListButton />
          </Grid>
          <Grid item xs={4}>
            <OrderHistoryButton />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
