import React from 'react';
import { MenyContext } from '..';

export default () => {
  let { setHelloworldToMonitor1 } = React.useContext(MenyContext);

  React.useEffect(() => {
    setHelloworldToMonitor1('helloworld_to_monito22222');
  }, []);

  return <></>;
};
