import React from 'react';
import { Box } from '@material-ui/core';
import HorizontalScrollBar from '../HorizontalScrollBar';
import { getFoodMenu } from 'src/modals/food_menu';

export default function FoodMenuListingCategoryScrollBar({
  list_ref,
  menu_id,
  rest_id,
  category_selected,
  setCategorySelected,
}) {
  const [food_menu, setFoodMenu] = React.useState(null);

  const [food_menu_loading, setFoodMenuLoading] = React.useState(true);

  const fetchFoodMenu = (menu_id) => {
    console.log('global.js', 'fetchFoodMenu', 'menu_id', menu_id);
    getFoodMenu(menu_id)
      .then((food_menu) => {
        setFoodMenu(food_menu);
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  React.useEffect(() => {
    if (menu_id != null) {
      fetchFoodMenu(menu_id);
    }
  }, [menu_id]);

  React.useEffect(() => {
    if (food_menu != null) {
      // TODO: better handling for null value, food_menu.food_details
      setFoodMenuLoading(false);
    }
  }, [food_menu]);

  return (
    <>
      <Box
        id="food-category-scroll"
        style={{
          // backgroundColor: 'white',
          width: '100%',
        }}
      >
        {/* Category scrolling */}
        {food_menu_loading ? (
          <></>
        ) : (
          <HorizontalScrollBar
            food_menu={food_menu}
            category_selected={category_selected}
            setCategorySelected={setCategorySelected}
            list_ref={list_ref}
          />
        )}
      </Box>
    </>
  );
}
