import React from 'react';

import TermsAndConditionsSign from '../../components/TermsAndConditionsSign';

import MenyLightSorryRestaurantClosedSign from 'src/MenyLightApp/components/MenyLightSorryRestaurantClosedSign';

import { RESTAURANT_OPEN_STATE_OPENED, RESTAURANT_OPEN_STATE_PREPARING } from 'src/consts/RESTAURANT_OPEN_STATE';

import { MenyLightContext } from '../../contexts';

import FoodMenuHead from './FoodMenuHead';
import FoodMenuBodyWithCategories from './FoodMenuBodyWithCategories';

const LOG_PREFIX = 'ShowFoodMenuPage';

export default function ShowFoodMenuPage({ view_only }) {
  let { restaurant_config } = React.useContext(MenyLightContext);

  let [open_sorry_we_are_closed, setOpenSorryWeAreClosed] = React.useState(false);

  const acknowledgeRestaurantClosedDialog = () => {
    let { restaurant_open_id } = restaurant_config;
    console.log(LOG_PREFIX, { restaurant_config });
    storeSorryRestaurantClosedSignPromptedAlready(restaurant_open_id);
  };

  let {
    storeSorryRestaurantClosedSignPromptedAlready,
    clearSorryRestaurantClosedSignPromptedAlready,
    loadSorryRestaurantClosedSignPromptedAlready,
  } = React.useContext(MenyLightContext);

  React.useEffect(() => {
    let { restaurant_open_state } = restaurant_config;
    switch (restaurant_open_state) {
      case RESTAURANT_OPEN_STATE_OPENED:
        clearSorryRestaurantClosedSignPromptedAlready();
        break;

      case RESTAURANT_OPEN_STATE_PREPARING:
        let { restaurant_open_id } = restaurant_config;

        // MENY-381, meny light restaurant when closed, user side does not show restaurant closed dialog message
        // let prompted_closeing_id = loadSorryRestaurantClosedSignPromptedAlready();
        // if (prompted_closeing_id && prompted_closeing_id === restaurant_open_id) {
        //   setOpenSorryWeAreClosed(false);
        // } else {
        //   setOpenSorryWeAreClosed(true);
        // }
        setOpenSorryWeAreClosed(true);

        break;

      default:
        break;
    }
  }, [restaurant_config]);

  return (
    <>
      <MenyLightSorryRestaurantClosedSign
        open={open_sorry_we_are_closed}
        setOpen={setOpenSorryWeAreClosed}
        acknowledgeDialog={acknowledgeRestaurantClosedDialog}
      />
      <TermsAndConditionsSign />

      <div style={{ height: '100%', display: 'flex', flexFlow: 'column', overflowY: 'auto' }}>
        <FoodMenuHead />

        <FoodMenuBodyWithCategories view_only={view_only} />
      </div>
    </>
  );
}
