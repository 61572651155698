import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ShowDebug from 'src/components/ShowDebug';

import { MenyContext } from '..';
import { monitorTableAssignStatusChange } from 'src/modals/table';

import {
  // RESTAURANT_OPENED,
  NOT_MONITORING,
  MONITORING,
  // RESTAURANT_CLEAR_BILL,
  // CLIENT_LANDING,
  CLIENT_TABLE_ASSIGNED,
} from './STATES';

let SEAT_WAITING = 'SEAT_WAITING';
let SEAT_ASSIGNED = 'SEAT_ASSIGNED';

const LOG_PREFIX = 'MonitorTableStatusMachineComponent';

export default function MonitorTableStatusMachine({ machines }) {
  const navigate = useNavigate();
  const { rest_id, resetClient } = React.useContext(MenyContext);
  let { monitor_table_status_machine, monitor_order_status_machine, meny_client_state_machine } = machines;

  let [monitor_table_status_state, sendMonitorTableStatusMachine] = monitor_table_status_machine;

  let [meny_client_state_machine_state, sendMenyClientStateMachine] = meny_client_state_machine;
  let [monitor_order_status_state, sendMonitorOrderStatus] = monitor_order_status_machine;

  const printTableStatus = () => {
    let { value, context } = monitor_table_status_state;
    console.log(LOG_PREFIX, { value, context });
  };

  let unsub;

  React.useEffect(() => {
    printTableStatus();
    let doc_data = {};

    let { lineup_id, rest_id, menu_id } = monitor_table_status_state.context;
    if (monitor_table_status_state.matches(MONITORING)) {
      unsub = monitorTableAssignStatusChange(lineup_id, rest_id, menu_id, (doc) => {
        doc_data = doc.data();
        // consideration:
        // new_order_id and status update in 2 response
        if (!doc.data()) {
          resetClient();
          sendMonitorTableStatusMachine(NOT_MONITORING);
          return;
        }
        let table_assign_status = doc.data();

        if (table_assign_status?.status) {
          let { status } = table_assign_status;
          switch (status) {
            case SEAT_ASSIGNED:
              let { new_order_id, assign_table_name } = table_assign_status;
              if (new_order_id) {
                let order_history_id = table_assign_status.new_order_id;
                console.log('monitorTableAssignStatusChange', { table_assign_status });

                sendMonitorOrderStatus(MONITORING, { lineup_id, rest_id, menu_id, order_history_id });
                sendMenyClientStateMachine(CLIENT_TABLE_ASSIGNED, {
                  table_name: assign_table_name,
                  order_history_id,
                });

                sendMonitorTableStatusMachine(NOT_MONITORING);
              } else {
                console.log(LOG_PREFIX, 'skip as new_order_id is not defined');
              }

              break;

            case SEAT_WAITING:
              break;

            default:
              break;
          }
        }
      });
    } else if (monitor_table_status_state.matches(NOT_MONITORING)) {
      console.log('updateByMonitorTableStatusMachine', 'unsub', unsub);
      if (unsub) unsub();
    } else {
    }

    // setTableName(assign_table_name);
  }, [monitor_table_status_state]);

  const tableMonitorOn = () => {
    sendMonitorTableStatusMachine('MONITORING');
  };

  const tableMonitorOff = () => {
    sendMonitorTableStatusMachine('NOT_MONITORING');
  };

  return (
    <>
      {/* <ShowDebug>
        <button onClick={() => printTableStatus()}>printTableStatus</button>
        <button onClick={() => tableMonitorOn()}>tableMonitorOn</button>
        <button onClick={() => tableMonitorOff()}>tableMonitorOff</button>
      </ShowDebug> */}
    </>
  );
}
