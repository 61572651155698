import React from 'react';
import { Dialog, Slide } from '@material-ui/core';

import ReservationNumberSign from 'src/MenyApp/views/meny/ReservationNumberSign';

import { dialog_transition_duration } from 'src/consts/TRANSITION';
import { MenyContext } from 'src/MenyApp/contexts';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ReservationNumberAssignedDialog({ open, setOpen }) {
  const { reservation_number } = React.useContext(MenyContext);

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        transitionDuration={dialog_transition_duration}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <ReservationNumberSign reservation_number={reservation_number} handleClose={handleClose} />
      </Dialog>
    </>
  );
}
