import React from 'react';
import { Detector } from 'react-detect-offline';
import { MenyLightContext } from '..';

const LOG_PREFIX = 'NetworkStatus';
const POLLING_ADDRESS = window.location.origin;

export default function NetworkStatus() {
  let { setIsOnline } = React.useContext(MenyLightContext);

  const handleOnline = (is_online) => {
    console.log(LOG_PREFIX, 'is_online', is_online);
    setIsOnline(is_online);
  };

  return (
    <>
      <Detector
        polling={{ url: `${POLLING_ADDRESS}/` }}
        render={({ online }) => {
          handleOnline(online);
          return <></>;
        }}
      />
    </>
  );
}
