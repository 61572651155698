import firebase_app from '../Firebase/config';
import db_config from 'src/configs/db_config';

import db from 'src/Firebase/db';

function oldGetFoodMenu(table) {
  return db
    .collection(table)
    .get()
    .then((querySnapShot) => {
      let temp_food_menu = [];
      querySnapShot.forEach((doc) => {
        let doc_data = doc.data();
        temp_food_menu.push(doc_data);
      });
      console.log('querySnapShot.size', querySnapShot.size);
      return temp_food_menu;
    });
}

function getFoodMenu1(menu_id) {
  console.log('getFoodMenu', 'menu_id', menu_id);
  return db
    .collection(db_config.DB_TABLE_ALL_FOOD_DETAILS)
    .doc(menu_id)
    .collection('food_details')
    .get()
    .then((querySnapShot) => {
      let temp_food_menu = [];
      querySnapShot.forEach((doc) => {
        let doc_data = doc.data();
        temp_food_menu.push(doc_data);
      });
      console.log('querySnapShot.size', querySnapShot.size);
      return temp_food_menu;
    });
}

function getFoodMenu(menu_id) {
  console.log('getFoodMenu', 'menu_id', menu_id);
  return db
    .collection(db_config.DB_TABLE_ALL_FOOD_DETAILS)
    .doc(menu_id)
    .get()
    .then((querySnapShot) => {
      return querySnapShot.data();
    });
}

function updateAllFoods(rest_id, payload) {
  let DocRef = db.collection(db_config.DB_TABLE_ALL_FOOD_DETAILS).doc(rest_id);

  return db
    .runTransaction((transaction) => {
      return transaction.get(DocRef).then(() => {
        transaction.set(DocRef, payload);
      });
    })
    .then(() => {
      console.log('updateAllFoods committed!');
      console.log('updateAllFoods', 'payload', payload);
    })
    .catch((error) => {
      console.log('updateAllFoods failed: ', error);
      console.log('updateAllFoods', 'payload', payload);
    });
}

function subscribeFoodMenuChange(rest_id) {
  return db.collection(db_config.DB_TABLE_ALL_FOOD_MENU).doc(rest_id);
}

export { oldGetFoodMenu, getFoodMenu, updateAllFoods, subscribeFoodMenuChange };
