import React from 'react';

import Dialog from '@material-ui/core/Dialog';

import { MenyLightContext } from 'src/MenyLightApp/contexts';
import DialogBody from './DialogBody';

const LOG_PREFIX = 'FoodDetailDialog';

export default ({ food_entry, open, setOpen, view_only, restaurant_config }) => {
  const { rest_id, increaseAdsImpressions, advertisement_config } = React.useContext(MenyLightContext);
  let food_menu_ad = advertisement_config?.filter((ad) => ad.data.ad_window.name === 'food_menu')[0]?.data;

  const handleClose = () => {
    console.log(LOG_PREFIX, { advertisement_config });
    if (food_menu_ad) {
      // consider:
      // add counter when return food menu page, for food menu ads,
      console.log(LOG_PREFIX, 'increaseAdsImpressions');
      increaseAdsImpressions({ rest_id });
    }

    setOpen(false);
  };

  const handleOnEnter = () => {
    // changeViewToFoodDetail();
  };

  return (
    <>
      <Dialog
        open={open}
        onEnter={handleOnEnter}
        // onClose={handleClose}
        style={{ height: '100%' }}
        transitionDuration={{ enter: 0, exit: 0 }}
        fullScreen
      >
        <DialogBody
          handleBackOnClick={handleClose}
          food_entry={food_entry}
          view_only={view_only}
          restaurant_config={restaurant_config}
        />
      </Dialog>
    </>
  );
};
