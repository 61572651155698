import React from 'react';
import { makeStyles, Slide } from '@material-ui/core';

import { LYNKED_WHITE } from 'src/consts/colors';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexFlow: 'column',
    overflowY: 'auto',
  },
  restaurant_name: {
    backgroundColor: 'black',
    color: ' white',
    minHeight: '20px',
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    // font: 'normal normal normal 10px/18px Noto Sans JP',
    fontSize: '10px',
    lineHeight: '18px',
  },
  restaurant_bar: {
    minHeight: '100px',

    // MENY-243, required for iphone
    height: '100px',

    backgroundColor: LYNKED_WHITE,
    // boxShadow: '0px 3px 6px #00000029',
  },
  food_menu_food_entry: {
    flexGrow: '1',
    overflowY: 'scroll',
    overflowX: 'hidden',
  },
  bottom_bar: {
    minHeight: '75px',
  },
}));

export { useStyles, Transition };
