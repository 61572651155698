import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import 'src/index.css';
import App from 'src/App';

import * as serviceWorker from 'src/serviceWorker';

import FullScreenLoading from 'src/components/FullScreenLoading';
import AppContexts from 'src/contexts/AppContexts';
import BUILD_INFO from 'src/consts/URLS/data.json';

console.log('hello from menymeny.com', BUILD_INFO.GIT_COMMIT);

if (process.env.NODE_ENV !== 'development') {
  console.log = () => {};
  console.assert = () => {};
  console.warn = () => {};

  if (window.location.href.search('web.app') === -1) {
    console.debug = () => {};
    console.error = () => {};
  }

  // init sentry as not in development
  let { REACT_APP_SENTRY_IO } = process.env;
  Sentry.init({
    dsn: REACT_APP_SENTRY_IO,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <React.Suspense fallback={<FullScreenLoading />}>
      <AppContexts>
        <App style={{ overflow: 'hidden' }} />
      </AppContexts>
    </React.Suspense>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
