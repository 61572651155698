import React from 'react';
import { useQuery, useMutation } from 'react-query';

import { getFoodMenu } from 'src/modals/food_menu';

import config from 'src/config';

const useMutationFoodMenu = () => {
  return useMutation(
    `mutateion-food-menu`,
    async (menu_id) => {
      return getFoodMenu(menu_id).then((food_menu) => {
        console.log('useMutationFoodMenu', 'menu_id', menu_id);
        return food_menu;
      });
    },
    { retry: 5 }
  );
};

export default useMutationFoodMenu;
