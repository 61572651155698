let ORDERLIST_WITH_ADS_LOADING = 'ORDERLIST_WITH_ADS_LOADING';

let PLEASE_WAIT_WHILE_YOUR_TABLE_BEING_ASSIGNED = '受付完了まで少々お待ちください。';

let USER_ACKNOWLEDGE_WAIT_ASSIGN_TABLE = 'OK';

let SORRY_BUT_WE_RE_CLOSED = 'SORRY_BUT_WE_RE_CLOSED';
let USER_ACKNOWLEDGE_RESTAURANT_CLOSED = 'OK';

export default { ORDERLIST_WITH_ADS_LOADING, PLEASE_WAIT_WHILE_YOUR_TABLE_BEING_ASSIGNED, USER_ACKNOWLEDGE_WAIT_ASSIGN_TABLE, SORRY_BUT_WE_RE_CLOSED, USER_ACKNOWLEDGE_RESTAURANT_CLOSED };
