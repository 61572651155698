import React from 'react';
import { Box } from '@material-ui/core';
import { LYNKED_DEEP_GREY } from 'src/consts/colors';

export default function TableNumberLabel({ formInfo }) {
  return (
    <>
      <Box
        style={{
          width: '100%',
          height: '24px',

          backgroundColor: LYNKED_DEEP_GREY,
          borderRadius: '12px 0px 0px 12px',

          display: 'flex',
          flexFlow: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box>{formInfo?.assign_table_name || ''}</Box>
      </Box>
    </>
  );
}
