import React from 'react';

import { Grid } from '@material-ui/core';
import ShowYen from 'src/components/ShowYen';

import AddRemoveButton from './AddRemoveButton';

import { makeStyles } from '@material-ui/core/styles';

import './OrderItemStyle.css';

import testValidItemCustomizeSetting from 'src/utils/testValidItemCustomizeSetting';

const useStyles = makeStyles((theme) => ({
  list_base: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
  },
  order_list_item_name: {
    fontFamily: 'Noto Sans JP',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '17px',
    textAlign: 'left',
  },
  order_list_item_price: {
    fontFamily: 'Noto Sans JP',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '17px',
    textAlign: 'right',
  },
  order_list_item_options_text: {
    fontFamily: 'Noto Sans JP',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '11px',
    lineHeight: '17px',
    paddingLeft: '1rem',
  },
  order_list_item_options_price: {
    fontFamily: 'Noto Sans JP',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '11px',
    lineHeight: '17px',
    textAlign: 'right',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

export default function OrderItem({
  cart_item,
  cart_items,
  setCartItems,
  item_idx,
  getOrderTotal,
  updateNumOfFoodItemCount,
  restaurant_config,
}) {
  const classes = useStyles();
  console.log('cart_items', restaurant_config);
  let [item_customize_settings, setItemCustomizeSetting] = React.useState([]);

  React.useEffect(() => {
    if (testValidItemCustomizeSetting(cart_item.item_customize_settings)) {
      setItemCustomizeSetting(cart_item.item_customize_settings);
    } else {
      // MENY-197, When there is 2 food at cart page, first food has modifier group, second food does not have modifier group, remove the first food item, the second food will have modifier group from first food
      setItemCustomizeSetting([]);
    }

    // MENY-188, used to refresh when cart_items list changed,
  }, [cart_item, cart_items]);

  return (
    <>
      <div
        className={classes.list_base}
        style={{ textAlign: 'left', borderBottom: '1px solid #EBEBEB', padding: '1rem' }}
      >
        <Grid container spacing={2}>
          <Grid item xs={8} container alignItems="center">
            <Grid container>
              <Grid item xs={8}>
                <div className={classes.order_list_item_name}>{cart_item.item_name}</div>
              </Grid>
              <Grid item xs={4}>
                <div className={classes.order_list_item_price}>
                  <ShowYen value_yen={cart_item.item_price} restaurant_config={restaurant_config} />
                </div>
              </Grid>
              <Grid container style={{ paddingTop: '10px' }} spacing={1}>
                {item_customize_settings.map((item, idx) => {
                  return (
                    <>
                      <Grid item xs={9} key={idx}>
                        <div
                          className={classes.order_list_item_options_text}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <span class="icon twitter"></span>

                          <div>{item.name}</div>
                        </div>
                      </Grid>
                      <Grid item xs={3}>
                        <div className={classes.order_list_item_options_price}>
                          <ShowYen value_yen={item.price} restaurant_config={restaurant_config} />
                        </div>
                      </Grid>
                    </>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={4}
            style={{
              display: 'flex',
              flexFlow: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <AddRemoveButton
              cart_item={cart_item}
              setCartItems={setCartItems}
              item_idx={item_idx}
              cart_items={cart_items}
              getOrderTotal={getOrderTotal}
              updateNumOfFoodItemCount={updateNumOfFoodItemCount}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
}
