import React from 'react';
import { Box } from '@material-ui/core';

import { MenyContext } from '../../contexts';

import FoodMenuHead from './FoodMenuHead';
import FoodImage from './FoodImage';
import FoodNameAndPrice from './FoodNameAndPrice';
import FoodDetailDescription from './FoodDetailDescription';
import FoodDetailBottomRow from './FoodDetailBottomRow';
import CustomizeButtonGroup from './CustomizeButtonGroup';

export default ({ setOpen, handleBackOnClick, food_entry, restaurant_config }) => {
  let { setOpenFoodAddToCartSign } = React.useContext(MenyContext);

  let [list_customize_options, setListCustomizeOptions] = React.useState({ a: [] });

  const handleClose = () => {
    setOpenFoodAddToCartSign(true);
    setOpen(false);
  };

  return (
    <>
      <Box
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexFlow: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        <FoodMenuHead handleBackOnClick={handleBackOnClick} />
        <FoodImage food_entry={food_entry} />
        <FoodNameAndPrice food_entry={food_entry} restaurant_config={restaurant_config} />
        <FoodDetailDescription food_entry={food_entry} />

        <Box style={{ width: '100%' }}>
          <CustomizeButtonGroup
            food_entry={food_entry}
            list_customize_options={list_customize_options}
            setListCustomizeOptions={setListCustomizeOptions}
            restaurant_config={restaurant_config}
          />
        </Box>

        <Box
          style={{
            width: '100%',
            minHeight: '75px',
            height: '75px',
            display: 'flex',
            flexFlow: 'row',
            justifyContent: 'space-between',
          }}
        >
          <FoodDetailBottomRow
            food_entry={food_entry}
            handleClose={handleClose}
            list_customize_options={list_customize_options}
          />
        </Box>
      </Box>
    </>
  );
};
