import React from 'react';

export default () => {
  let [foodmenu_to_monitor1, setFoodMenuToMonitor1] = React.useState('foodmenu_to_monitor1');
  let [restaurant_food_menu, setRestaurantFoodMenu] = React.useState('foodmenu_to_monitor1');
  let [is_loading_food_menu, setIsLoadingFoodMenu] = React.useState(true);

  let [table_name, setTableName] = React.useState('');

  return {
    foodmenu_to_monitor1,
    setFoodMenuToMonitor1,

    restaurant_food_menu,
    setRestaurantFoodMenu,

    table_name,
    setTableName,

    is_loading_food_menu,
    setIsLoadingFoodMenu,
  };
};
