import React from 'react';

import { TextField, IconButton, makeStyles } from '@material-ui/core';
import RemoveIcon from '@material-ui/icons/Remove';
import AddIcon from '@material-ui/icons/Add';
import getPriceAdjustedByCustomization from 'src/utils/getPriceAdjustedByCustomization';
import getItemSubtotal from 'src/utils/getItemSubtotal';
import RubbishBinButton from 'src/MenyApp/components/RubbishBinButton';

import { LYNKED_WHITE, LYNKED_DEEP_GREY } from 'src/consts/colors';
import { isUndefined } from 'lodash';
import { MenyContext } from 'src/MenyApp/contexts';

const min_quantity = 0;
const max_quantity = 99;

const useStyles = makeStyles((theme) => ({
  buttons: {
    borderRadius: '26px',
    border: '1px solid',
    borderColor: LYNKED_DEEP_GREY,
    width: '28px',
    height: '28px',

    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  number_value: {
    width: '3rem',
    textAlign: 'center',
  },
}));

function IncValueButton({ handleOnClick }) {
  const classes = useStyles();
  return (
    <div className={classes.buttons}>
      <IconButton onClick={handleOnClick}>
        <AddIcon />
      </IconButton>
    </div>
  );
}

function DecValueButton({ handleOnClick }) {
  const classes = useStyles();
  return (
    <div className={classes.buttons}>
      <IconButton onClick={handleOnClick}>
        <RemoveIcon />
      </IconButton>
    </div>
  );
}

function AddRemoveButton({ cart_items, cart_item, setCartItems, item_idx, getOrderTotal, updateNumOfFoodItemCount }) {
  const { restaurant_food_menu } = React.useContext(MenyContext);
  const classes = useStyles();
  let [localItem, setLocalItem] = React.useState(cart_items[item_idx]);
  const handleRemoveItem = () => {
    setLocalItem((prevState) => ({ ...prevState, item_quantity: Math.max(min_quantity, prevState.item_quantity - 1) }));
  };
  const handleAddItem = () => {
    Object.values(restaurant_food_menu.food_details).map((item) => {
      if (item.id === localItem.item_food_id && parseInt(localItem.item_quantity) < parseInt(item.foodStockCount)) {
        setLocalItem((prevState) => ({
          ...prevState,
          item_quantity: Math.max(min_quantity, prevState.item_quantity + 1),
        }));
      }
      if (item.id === localItem.item_food_id && (!item.foodStockCount || !item.isEnableStockCount)) {
        setLocalItem((prevState) => ({
          ...prevState,
          item_quantity: Math.max(min_quantity, prevState.item_quantity + 1),
        }));
      }
    });
  };

  const updateItemQuantity = (e) => {
    e.persist();
    Object.values(restaurant_food_menu.food_details).map((item) => {
      if (item.id === localItem.item_food_id && !e.target.value) {
        setLocalItem((prevState) => ({
          ...prevState,
          item_quantity: 1,
        }));
      } else if (
        item.id === localItem.item_food_id &&
        parseInt(e.target.value) <= parseInt(item.foodStockCount) &&
        item.isEnableStockCount
      ) {
        setLocalItem((prevState) => ({
          ...prevState,
          item_quantity: Math.min(max_quantity, Math.max(min_quantity, e.target.value)),
        }));
      } else if (
        item.id === localItem.item_food_id &&
        parseInt(e.target.value) > parseInt(item.foodStockCount) &&
        item.isEnableStockCount
      ) {
        setLocalItem((prevState) => ({
          ...prevState,
          item_quantity: parseInt(item.foodStockCount),
        }));
      } else if (item.id === localItem.item_food_id && (!item.foodStockCount || !item.isEnableStockCount)) {
        setLocalItem((prevState) => ({
          ...prevState,
          item_quantity: Math.min(max_quantity, Math.max(min_quantity, e.target.value)),
        }));
      }
    });
  };

  const handleRemoveEntry = () => {
    let new_cart_items = [];
    cart_items.forEach((cart_item, idx) => {
      if (idx != item_idx) {
        new_cart_items.push(cart_item);
      }
    });

    updateNumOfFoodItemCount(new_cart_items);

    setCartItems(new_cart_items);
  };

  React.useEffect(() => {
    let new_cart_items = cart_items;
    let unit_price = new_cart_items[item_idx].item_price;
    let adjustment_by_customization = getPriceAdjustedByCustomization(cart_item.item_customize_settings);
    new_cart_items[item_idx].item_quantity = localItem.item_quantity;
    new_cart_items[item_idx].item_subtotal = getItemSubtotal(
      localItem.item_quantity,
      unit_price,
      adjustment_by_customization
    );

    setCartItems(new_cart_items);
    updateNumOfFoodItemCount(new_cart_items);
    getOrderTotal();
  }, [localItem.item_quantity]);

  React.useEffect(() => {
    console.log('cart_items', 'item_idx', item_idx);

    if (!isUndefined(cart_items[item_idx])) {
      setLocalItem(cart_items[item_idx]);
    }
  }, [cart_items]);

  return (
    <>
      <div>
        <div
          style={{
            width: '100%',
            height: '100%',
            backgroundColor: LYNKED_WHITE,

            display: 'flex',
            flexFlow: 'row',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          {localItem.item_quantity > 1 ? (
            <DecValueButton handleOnClick={handleRemoveItem} />
          ) : (
            <RubbishBinButton handleOnClick={handleRemoveEntry} />
          )}

          <TextField
            className={classes.number_value}
            type="number"
            value={localItem.item_quantity || 1}
            onInput={updateItemQuantity}
            inputProps={{ style: { textAlign: 'center' } }}
            InputProps={{ disableUnderline: true }}
          />
          <IncValueButton handleOnClick={handleAddItem} />
        </div>
      </div>
    </>
  );
}

export default React.memo(AddRemoveButton);
