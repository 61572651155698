import React from 'react';
import { useSnackbar } from 'notistack';
import { isDevelopment } from 'src/utils/isDevelopment';
import { SERVICE_REQUEST_CLEARED, SERVICE_REQUEST_NOT_CLEARED } from 'src/consts/SERVICE_REQUEST_STATE';

import { monitorMenyLightServiceRequestByTableName } from 'src/modals/service_request';

import { MenyLightContext } from '..';

import active_lang from 'src/langs/jp_en';
import { useTranslation } from 'react-i18next';

const LOG_PREFIX = 'ServiceRequest';

export let unsub_service_request_changes = {};

export function ServiceRequest() {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  let [init_count_done, setInitCountDone] = React.useState(false);

  let {
    rest_id,
    setServiceRequestsCount,
    table_name,
    service_request_not_cleared,
    setServiceRequestNotCleared,

    service_request_cleared,
    setServiceRequestCleared,
    last_alert_cleared_count,
    setLastAlertClearedCount,
  } = React.useContext(MenyLightContext);

  const showPleaseWaitAsStaffWillBeOnTheirWayToAssistYou = () => {
    if (isDevelopment()) {
      enqueueSnackbar(t('OTHERS_STAFF_WILL_COME_MSG'), { variant: 'success' });
    } else {
      enqueueSnackbar(t('OTHERS_STAFF_WILL_COME_MSG'), { variant: 'success' });
    }
  };

  const handleGetLastAlertClearedCount = ({ rest_id }) => {
    let count = localStorage.getItem(`${rest_id}/last_alert_cleared_count`);
    return count ? parseInt(count) : 0;
  };

  const handleSetLastAlertClearedCount = (value) => {
    console.log(LOG_PREFIX, 'handleSetLastAlertClearedCount');
    localStorage.setItem(`${rest_id}/last_alert_cleared_count`, JSON.stringify(value));
  };

  React.useEffect(() => {
    handleSetLastAlertClearedCount(last_alert_cleared_count);
  }, [last_alert_cleared_count]);

  React.useEffect(() => {
    console.log(LOG_PREFIX, {
      last_alert_cleared_count,
      not_clear_count: service_request_not_cleared.state.length,
      clear_count: service_request_cleared.state.length,
      result: service_request_cleared.state.length > last_alert_cleared_count,
      init_count_done,
    });

    if (init_count_done) {
      if (service_request_cleared.state.length > last_alert_cleared_count) {
        showPleaseWaitAsStaffWillBeOnTheirWayToAssistYou();
        setLastAlertClearedCount(service_request_cleared.state.length);
      } else {
        // consider: increase number of clear alert 0->1, 1->2
        setLastAlertClearedCount(service_request_cleared.state.length);
      }
    }
  }, [service_request_cleared]);

  React.useEffect(() => {
    let unsub = monitorMenyLightServiceRequestByTableName({ rest_id, table_name }).onSnapshot((ss) => {
      let not_cleared_service_request = [];
      let cleared_service_request = [];

      ss.docs.forEach((doc) => {
        switch (true) {
          case doc.data().status == SERVICE_REQUEST_NOT_CLEARED:
            not_cleared_service_request.push(doc);
            break;

          case doc.data().status == SERVICE_REQUEST_CLEARED:
            cleared_service_request.push(doc);
            break;

          default:
            break;
        }
      });

      setServiceRequestNotCleared({ state: not_cleared_service_request });
      setServiceRequestCleared({ state: cleared_service_request });
      setServiceRequestsCount(ss.docs.length);

      if (!init_count_done) {
        console.log(LOG_PREFIX, cleared_service_request.length);
        setLastAlertClearedCount(cleared_service_request.length);
        setInitCountDone(true);
      }
    });

    return () => {
      if (unsub) unsub();
    };
  }, []);

  return <></>;
}
