import React from 'react';

export default function ShowDebug({ children }) {
  if (process.env.NODE_ENV === 'development')
    return (
      <>
        <div
          style={{
            display: 'block',
            backgroundColor: 'gold',
            width: '100%',
            maxheight: '3rem',
            padding: '0',
            margin: '0',
          }}
        >
          <pre style={{ padding: 0, margin: 0 }}>{children}</pre>
        </div>
      </>
    );

  return <></>;
}
