import React from 'react';
import { isNull } from 'lodash';
import { Detector } from 'react-detect-offline';
import { MenyContext } from '..';

import ShowDebug from 'src/components/ShowDebug';

import {
  CLIENT_TABLE_ASSIGNED,
  CLIENT_WAITING_TABLE,
  RESTAURANT_STATUS_UNKNOWN,
  UPDATE_RESTAURANT_OPEN_ID,
} from '../../StateMachines/STATES';

const LOG_PREFIX = 'NetworkStatus';
const POLLING_ADDRESS = window.location.origin;

export default function NetworkStatus() {
  let { setIsOnline, meny_client_state_machine, order_history, restaurant_config } = React.useContext(MenyContext);
  let [client_state, sendClientState] = meny_client_state_machine;

  let [is_offline_found, setIsOfflineFound] = React.useState(false);

  const isRestaurantOpenIdStillValid = (test_rest_open_id) => {
    return (
      !isNull(client_state.context.restaurant_open_id) && test_rest_open_id === client_state.context.restaurant_open_id
    );
  };

  const handleOnline = (is_online) => {
    setIsOnline(is_online);
    if (is_online) {
      if (is_offline_found) {
        switch (true) {
          case client_state.matches(CLIENT_WAITING_TABLE):
            if (isRestaurantOpenIdStillValid(restaurant_config.restaurant_open_id)) {
              console.log(LOG_PREFIX, 'restaurant not open->close->open during client offline, keep session');
            } else {
              console.log(LOG_PREFIX, 'send user back');
              sendClientState(RESTAURANT_STATUS_UNKNOWN);
            }

            break;

          case client_state.matches(CLIENT_TABLE_ASSIGNED):
            if (isRestaurantOpenIdStillValid(restaurant_config.restaurant_open_id)) {
              console.log(LOG_PREFIX, 'restaurant not open->close->open during client offline, keep session');
            } else {
              if (order_history?.orders?.length > 0) {
                let { restaurant_open_id } = restaurant_config;
                console.log(LOG_PREFIX, 'skip send user back as order history is not empty', { restaurant_open_id });
                sendClientState(UPDATE_RESTAURANT_OPEN_ID, { restaurant_open_id });
              } else {
                sendClientState(RESTAURANT_STATUS_UNKNOWN);
              }
            }
            break;

          default:
            break;
        }
      }
    } else {
      console.log(LOG_PREFIX, 'offline found');
      setIsOfflineFound(true);
    }
  };

  function MenyOffline({ online }) {
    console.log(LOG_PREFIX, 'is_online', online);

    if (online) return <></>;
    return <ShowDebug>offlined ...</ShowDebug>;
  }

  return (
    <>
      <Detector
        polling={{ url: `${POLLING_ADDRESS}/` }}
        render={({ online }) => {
          handleOnline(online);
          return <></>;
        }}
      />
    </>
  );
}
