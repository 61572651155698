import React from 'react';

import { Box, IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import { useStyles } from './styles';

export default ({ back_button_callback }) => {
  const classes = useStyles();

  return (
    <Box className={classes.headbar_content}>
      <IconButton id="back_to_food_menu_button" onClick={back_button_callback}>
        <ArrowBackIcon />
      </IconButton>
    </Box>
  );
};
