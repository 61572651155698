import React from 'react';
import { Grid } from '@material-ui/core';

import CustomizeButton from './CustomizeButton';

import testValidItemCustomizeSetting from '../../utils/testValidItemCustomizeSetting.js';

export default ({
  food_entry,
  disable_add_to_cart_button,
  list_customize_options,
  setListCustomizeOptions,
  restaurant_config,
}) => {
  React.useEffect(() => {
    if (testValidItemCustomizeSetting(food_entry.customize_group_settings)) {
      let temp = food_entry.customize_group_settings.map((x) => {
        return { ...x, enabled: false };
      });
      setListCustomizeOptions({ a: temp });
    } else {
      setListCustomizeOptions({ a: [] });
    }
  }, [food_entry]);

  return (
    <Grid container spacing={1} style={{ padding: '0.5rem' }}>
      {list_customize_options.a.map((option, idx) => (
        <Grid item xs={4}>
          <CustomizeButton
            disabled={true}
            key={idx}
            name={option.name}
            price={option.price}
            option_enabled={option.enabled}
            customize_idx={0}
            restaurant_config={restaurant_config}
            handleOnClick={() => {}}
          />
        </Grid>
      ))}
    </Grid>
  );
};
