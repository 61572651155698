import React from 'react';
import { useParams } from 'react-router';

import FullScreenLoading from '../components/FullScreenLoading';

import useQueryRestaurantConfig from 'src/hooks/useQueryRestaurantConfig';

import ShowDebug from 'src/components/ShowDebug';

import FoodMenuSharedVar from './FoodMenu/shared_var';
import { FoodMenu } from './FoodMenu';

import ServiceRequestSharedVar from './ServiceRequest/shared_var';
import { ServiceRequest } from './ServiceRequest';

import AdvertisementContextSharedVar from './AdvertisementContext/shared_var';
import { AdvertisementContext } from './AdvertisementContext';

import RestaurantClosedSharedVar from './RestaurantClosed/shared_var';
import RestaurantClosed from './RestaurantClosed';

import NetworkStatusSharedVar from './NetworkStatus/shared_var';
import NetworkStatus from './NetworkStatus';

import AcceptTAndCSharedVar from './AcceptTAndC/shared_var';
import AcceptTAndC from './AcceptTAndC';

import RestaurantAssetsSharedVar from './RestaurantAssets/shared_var';
import { RestaurantAssets } from './RestaurantAssets';

import PreLoadAsset from './PreLoadAsset';
import useMutationSubscribeRestaurantConfig from 'src/hooks/useMutationSubscribeRestaurantConfig';

const LOG_PREFIX = 'MenyLightContext';

export const MenyLightContext = React.createContext();

export function MenyLightContextProvider({ children }) {
  let { rest_id, menu_id, table_name } = useParams();
  const mutation_subscribe_restaurant_config = useMutationSubscribeRestaurantConfig();

  let [restaurant_config, setRestaurantConfig] = React.useState();

  let { data } = useQueryRestaurantConfig(rest_id);

  let root_var = { rest_id, menu_id, table_name, restaurant_config };

  let shared_varirables = {
    ...root_var,
    setRestaurantConfig,
    ...FoodMenuSharedVar(root_var),
    ...ServiceRequestSharedVar(root_var),
    ...AdvertisementContextSharedVar(root_var),
    ...RestaurantClosedSharedVar(root_var),
    ...NetworkStatusSharedVar(root_var),
    ...AcceptTAndCSharedVar(root_var),
    ...RestaurantAssetsSharedVar(root_var),
  };

  React.useEffect(() => {
    if (rest_id) {
      mutation_subscribe_restaurant_config.mutateAsync(rest_id).then((res) => {
        res.onSnapshot({ includeMetadataChanges: true }, (doc) => {
          console.log('setRestaurantConfig', doc.data());
          setRestaurantConfig(doc.data());
        });
      });
    }
  }, [rest_id]);

  if (restaurant_config)
    return (
      <MenyLightContext.Provider value={shared_varirables}>
        <FoodMenu />
        <ServiceRequest />
        <AdvertisementContext />
        <RestaurantClosed />
        <RestaurantAssets />
        <NetworkStatus />

        <AcceptTAndC />
        <PreLoadAsset>{children}</PreLoadAsset>
      </MenyLightContext.Provider>
    );

  return (
    <>
      {/* <ShowDebug>{JSON.stringify(rest_id, null, 2)}</ShowDebug> */}
      {/* <ShowDebug>restaurant_config is empty</ShowDebug> */}
      <FullScreenLoading />
    </>
  );
}
