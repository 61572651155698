import db_config from 'src/configs/db_config';
import db from 'src/Firebase/db';

const monitorTableAssignStatusChange = (
  ref_to_monitor,
  rest_id,
  menu_id,
  updateCb
) => {
  console.log('context', 'calling monitorTableAssignStatusChange');
  console.log('monitorTableAssignStatusChange', new Error().stack);

  return db
    .collection(db_config.DB_TABLE_LINEUP)
    .doc(rest_id)
    .collection('lineup')
    .doc(ref_to_monitor)
    .onSnapshot((doc) => {
      console.log('monitorTableAssignStatusChange', 'doc.data()', doc.data());
      updateCb(doc);
    });
};

const getTableAssignStatus = (lineup_id, rest_id, menu_id) => {
  console.log('getTableAssignStatus', 'path', `${rest_id}/lineup/${lineup_id}`);
  return db
    .collection(db_config.DB_TABLE_LINEUP)
    .doc(rest_id)
    .collection('lineup')
    .doc(lineup_id)
    .get();
};

const addLineupRequest = ({ rest_id, form_info }) => {
  return db
    .collection(db_config.DB_TABLE_LINEUP)
    .doc(rest_id)
    .collection('lineup')
    .add(form_info);
};

export {
  monitorTableAssignStatusChange,
  getTableAssignStatus,
  addLineupRequest,
};
