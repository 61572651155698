import React from 'react';

import ShowDebug from 'src/components/ShowDebug';

import LoadAdWindow from './LoadAdWindow';
import { MenyContext } from '../../contexts';

let LOG_PREFIX = 'FoodMenuAdvertisementWindow';

export default () => {
  let { advertisement_config } = React.useContext(MenyContext);
  let food_menu_ad = advertisement_config?.filter((ad) => ad.data.ad_window.name === 'food_menu')[0]?.data;

  if (food_menu_ad) {
    return <LoadAdWindow ads_info={food_menu_ad} />;
  }

  return (
    <>
      {/* <ShowDebug>{JSON.stringify({ food_menu_ad })}</ShowDebug> */}
    </>
  );
};
