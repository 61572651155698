import React from 'react';
import { Input } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// import { BusContext } from 'src/contexts/BusContext';
// import { RestaurantConfigContext } from 'src/contexts/LocalStorageContext/RestaurantConfigContext';

import AddPeopleButton from 'src/MenyApp/views/meny/OrderHistoryWithAdsPage/BottomPanel/AddPeopleButton';
import RemovePeopleButton from 'src/MenyApp/views/meny/OrderHistoryWithAdsPage/BottomPanel/RemovePeopleButton';

import { MenyContext } from '../../../../contexts';
import ShowYen from 'src/components/ShowYen';

import { LYNKED_WHITE, LYNKED_GREEN, LYNKED_BACKGROUND } from 'src/consts/colors';
import active_lang from 'src/langs/jp_en';
import { useTranslation } from 'react-i18next';

const ACCOUNT_ORDER_STATUS = ['preparing', 'delivered'];

const LOG_PREFIX = 'BottomPanel';

export default function BottomPanel({ order_history }) {
  let { rest_id, restaurant_config } = React.useContext(MenyContext);
  const { t } = useTranslation();

  let [sub_total_divided_by_people, setSubTotalDividedByPeople] = React.useState(100);
  let [order_subtotal, setOrderSubtotal] = React.useState(0);

  // let { loadRestaurantConfig } = React.useContext(RestaurantConfigContext);

  React.useEffect(() => {
    if (order_history && order_history?.orders) {
      console.log('BottomPanel', 'order_history', order_history);
      let temp_order_subtotal = 0;
      let orders = order_history.orders;
      let account_orders = orders.filter((o) => ACCOUNT_ORDER_STATUS.includes(o.status));
      let item_subtotals = account_orders.map((o) => o.item_subtotal);
      console.log('BottomPanel', 'item_subtotals', item_subtotals);

      for (const item of item_subtotals) {
        temp_order_subtotal = Number(temp_order_subtotal) + Number(item);
      }

      console.log('BottomPanel', 'order_history', order_history);

      setOrderSubtotal(Number(Number(temp_order_subtotal).toFixed(2)));
    }
  }, [order_history]);

  let [split_bill_into_num, setSplitBillIntoNum] = React.useState(1);

  const handleNumOfPeopleChange = (e) => {
    setSplitBillIntoNum(e.target.value);
  };
  const handleRemoveItem = () => {
    setSplitBillIntoNum(Math.max(1, split_bill_into_num - 1));
  };
  const handleAddItem = () => {
    console.log(LOG_PREFIX, { restaurant_config });
    if (restaurant_config) {
      let { max_num_of_children, max_num_of_adult } = restaurant_config.lineup;
      setSplitBillIntoNum(Math.min(max_num_of_children + max_num_of_adult, Number(split_bill_into_num) + 1));
    } else {
      // setSplitBillIntoNum(Math.min(20, split_bill_into_num + 1));
      setSplitBillIntoNum(2);
    }
  };

  React.useEffect(() => {
    setSubTotalDividedByPeople(Math.max(0, order_subtotal / split_bill_into_num).toFixed(2));
  }, [split_bill_into_num, order_subtotal]);

  React.useEffect(() => {
    setSubTotalDividedByPeople(Math.max(0, order_subtotal / split_bill_into_num).toFixed(2));
  }, []);

  return (
    <>
      <div style={{ marginTop: '0.5rem', marginLeft: '1.5rem', marginRight: '1.5rem' }}>
        <div style={{ display: 'flex', flexFlow: 'row', justifyContent: 'space-between' }}>
          <div style={{ width: '45vw' }}></div>
          <div style={{ width: '10vw' }}>
            <ExpandMoreIcon />
          </div>
          <div
            style={{
              width: '45vw',
              // font: 'normal normal normal 20px/28px Noto Sans JP',
              fontSize: '10px',
              lineHeight: '15px',
              textAlign: 'right',
            }}
          >
            {restaurant_config && restaurant_config.taxOrderHistory
              ? restaurant_config.taxOrderHistory
              : t('ORDER_HISTORY_TAX')}
          </div>
        </div>

        <div style={{ display: 'flex', flexFlow: 'column', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', flexFlow: 'row' }}>
            <div
              style={{
                color: LYNKED_GREEN,
                background: LYNKED_BACKGROUND,
                border: 'solid 1px white',
                fontSize: '20px',
                lineHeight: '28px',
                width: '48vw',
                textAlign: 'right',
                padding: '0.25rem 1rem',
                boxSizing: 'border-box',
              }}
            >
              <div>{t('COMMON_TOTAL')}</div>
            </div>
            <div
              style={{
                color: LYNKED_GREEN,
                background: LYNKED_BACKGROUND,
                border: 'solid 1px white',
                fontSize: '20px',
                lineHeight: '28px',

                width: '52vw',
                textAlign: 'right',
                padding: '0.25rem 1rem',
                boxSizing: 'border-box',
              }}
            >
              <div>
                <ShowYen value_yen={order_subtotal} restaurant_config={restaurant_config} />
              </div>
            </div>
          </div>

          <div style={{ display: 'flex', flexFlow: 'row' }}>
            <div
              style={{
                fontSize: '15px',
                lineHeight: '28px',
                width: '48vw',
                textAlign: 'right',
                background: LYNKED_BACKGROUND,
                border: 'solid 1px white',
                padding: '0.25rem 1rem',
                boxSizing: 'border-box',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <div>{t('ORDER_HISTORY_SPLIT_BILL')}</div>
            </div>

            <div
              style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexFlow: 'row',
                justifyContent: 'flex-end',
                alignItems: 'center',
                background: LYNKED_BACKGROUND,
                border: 'solid 1px white',
                padding: '0.25rem 1rem',
                boxSizing: 'border-box',
                width: '52vw',
              }}
            >
              {/* Remove people button */}
              <RemovePeopleButton handleRemoveItem={handleRemoveItem} />
              <div style={{ width: '15%' }}>
                <Input
                  type="number"
                  disableUnderline={true}
                  value={split_bill_into_num || 1}
                  onInput={handleNumOfPeopleChange}
                  inputProps={{
                    style: {
                      textAlign: 'center',

                      // font: 'normal normal normal 20px/28px Noto Sans JP',
                      fontSize: '21px',
                      lineHeight: '30px',
                    },
                  }}
                />
              </div>
              {/* Add people button */}
              <AddPeopleButton handleAddItem={handleAddItem} />
            </div>
          </div>

          <div style={{ display: 'flex', flexFlow: 'row', background: '#fafafa' }}>
            <div
              style={{
                fontSize: '15px',
                lineHeight: '28px',
                width: '48vw',
                textAlign: 'right',
                background: LYNKED_BACKGROUND,
                border: 'solid 1px white',
                padding: '0.25rem 1rem',
                boxSizing: 'border-box',
              }}
            >
              <div>{t('COMMON_TOTAL')}</div>
            </div>
            <div
              style={{
                fontSize: '15px',
                lineHeight: '28px',
                width: '52vw',
                textAlign: 'right',
                background: LYNKED_BACKGROUND,
                border: 'solid 1px white',
                padding: '0.25rem 1rem',
                boxSizing: 'border-box',
              }}
            >
              {t('ORDER_HISTORY_PER_PERSON')}{' '}
              <ShowYen value_yen={sub_total_divided_by_people} restaurant_config={restaurant_config} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
