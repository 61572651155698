import React from 'react';

export default function NetworkStatusSharedVar() {
  let [networkstatus_to_monitor1, setNetworkStatusToMonitor1] = React.useState(null);

  let [is_online, setIsOnline] = React.useState(true);

  return {
    networkstatus_to_monitor1,
    setNetworkStatusToMonitor1,

    is_online,
    setIsOnline,
  };
}
