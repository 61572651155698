import { ColorLuminance } from 'src/consts/ColorLuminance';

const LYNKED_RED = '#800000';
const LYNKED_RED_HOVER = ColorLuminance(LYNKED_RED, 0.2);
const LYNKED_RED_SUPER_LIGHT = ColorLuminance(LYNKED_RED, 0.4);
const LYNKED_RED_DARKEN = ColorLuminance(LYNKED_RED, -0.4);

const LYNKED_WHITE = '#FFFFFF';
const LYNKED_BACKGROUND = '#fafafa';
const LYNKED_WHITE_HOVER = '#CCCCCC';

const LYNKED_DEEP_GREY_HOVER = '#CCCCCC';
const LYNKED_DEEP_GREY = '#9A9A9A';
const LYNKED_DEEP_DEEP_GREY = '#525252';

const LYNKED_LIGHT_GREY_HOVER = '#E6E6E6';
const LYNKED_LIGHT_GREY = '#B3B3B3';

const LYNKED_BLUE_HOVER = '#4F68E8';
const LYNKED_BLUE = '#3E51B5';

const LYNKED_GREEN_HOVER = '#5BD461';
const LYNKED_GREEN = '#44A048';

const LYNKED_BORDER_GREY = '#F4F6F8';

const LYNKED_SUCCESS = LYNKED_GREEN;

const LYNKED_BACKGROUND_LIGHT_GREY = ColorLuminance(LYNKED_LIGHT_GREY, 0.4);

const LYNKED_NAV_HIGHLIGHT = '#E2EDFF';

const LYNKED_BLACK = '#000000';
const LYNKED_BLACK_HOVER = '#333333';
// const LYNKED_BLACK_HOVER = ColorLuminan

const BORDER_COLOR = '#E0E0E0';

const BELL_COLOR_GREY = LYNKED_BLACK;
const BELL_COLOR_ORANGE = 'orange';

export {
  BORDER_COLOR,
  LYNKED_BACKGROUND_LIGHT_GREY,
  LYNKED_BACKGROUND,
  LYNKED_BLACK_HOVER,
  LYNKED_BLACK,
  LYNKED_BLUE_HOVER,
  LYNKED_BLUE,
  LYNKED_BORDER_GREY,
  LYNKED_DEEP_DEEP_GREY,
  LYNKED_DEEP_GREY_HOVER,
  LYNKED_DEEP_GREY,
  LYNKED_GREEN_HOVER,
  LYNKED_GREEN,
  LYNKED_LIGHT_GREY_HOVER,
  LYNKED_LIGHT_GREY,
  LYNKED_NAV_HIGHLIGHT,
  LYNKED_RED_DARKEN,
  LYNKED_RED_HOVER,
  LYNKED_RED_SUPER_LIGHT,
  LYNKED_RED,
  LYNKED_SUCCESS,
  LYNKED_WHITE_HOVER,
  LYNKED_WHITE,
  BELL_COLOR_GREY,
  BELL_COLOR_ORANGE,
};
