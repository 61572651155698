import React from 'react';

export default ({ rest_id }) => {
  const handleGetLastAlertClearedCount = ({ rest_id }) => {
    let count = localStorage.getItem(`${rest_id}/last_alert_cleared_count`);

    return count ? parseInt(count) : 0;
  };

  let [servicerequest_to_monitor1, setServiceRequestToMonitor1] = React.useState('servicerequest_to_monitor1');
  let [service_request_not_cleared, setServiceRequestNotCleared] = React.useState({ state: [] });
  let [service_request_cleared, setServiceRequestCleared] = React.useState({ state: [] });
  let [last_alert_cleared_count, setLastAlertClearedCount] = React.useState(
    handleGetLastAlertClearedCount({ rest_id })
  );

  // const startMonitorServiceRequestStatusChanges = () => {};

  return {
    servicerequest_to_monitor1,
    setServiceRequestToMonitor1,
    // startMonitorServiceRequestStatusChanges,
    service_request_not_cleared,
    setServiceRequestNotCleared,

    service_request_cleared,
    setServiceRequestCleared,

    last_alert_cleared_count,
    setLastAlertClearedCount,
  };
};
