import React from 'react';
import { isUndefined } from 'lodash';
import { Box } from '@material-ui/core';
import { MenyContext } from '../../../contexts';

import TermsAndConditionsSign from '../../../components/TermsAndConditionsSign';
import BottomBarWithAds from '../../../components/BottomBarWithAds';
import RestaurantBar from '../../../components/RestaurantBar';
import HorizontalScrollBar from '../../../components/HorizontalScrollBar';
import FoodMenuBody from '../../../components/FoodMenuBody';
import FoodMenuAdvertisementWindow from '../../../components/FoodMenuAdvertisementWindow';

import { isDefined } from 'src/utils/lodash-cust';
import { getTagIdFromTagName } from 'src/utils/getTagIdFromTagName';
import useMutationIncreaseMenyUsageCount from 'src/hooks/useMutationIncreaseMenyUsageCount';

import ShowNamePlace from './ShowNamePlace';
import { useStyles } from './styles';
import { STATUS_FOOD_SHOW } from 'src/modals/food_details';
import BackToTopButton from './BackToTopButton';

let LOG_PREFIX = 'ShowFoodMenuPage';

export default () => {
  let classes = useStyles();

  let food_entry_list_ref = React.useRef(null);

  let [already_on_top, setAlreadyOnTop] = React.useState(true);

  let { restaurant_config, restaurant_food_menu } = React.useContext(MenyContext);

  let categories =
    restaurant_food_menu &&
    restaurant_food_menu.columnOrder
      .filter((co) => {
        if (isDefined(restaurant_food_menu.columns[co].category_show)) {
          return restaurant_food_menu.columns[co].category_show === STATUS_FOOD_SHOW;
        } else {
          return true;
        }
      })
      .map((co) => {
        return {
          name: restaurant_food_menu.columns[co].title,
          id: co,
          html_id: getTagIdFromTagName(co),
          category_show: restaurant_food_menu.columns[co].category_show,
        };
      });

  let [category_selected, setCategorySelected] = React.useState(categories[0].name);

  // let { storeFoodMenuViewOnlyPrompted } = React.useContext(FoodMenuViewOnlyPromptedContext);

  const handleUpdateSelectedOnCategoryBar = (cat_in) => {
    setCategorySelected(cat_in);
  };

  let checkCategoryShowing = (co) =>
    isDefined(restaurant_food_menu?.columns[co]) &&
    (isUndefined(restaurant_food_menu.columns[co].category_show) ||
      restaurant_food_menu.columns[co].category_show === STATUS_FOOD_SHOW);

  React.useEffect(() => {
    let visibility_status = Array(categories.length).fill(false);

    if (food_entry_list_ref?.current) {
      food_entry_list_ref.current.addEventListener('scroll', () => {
        let el = food_entry_list_ref.current;
        var rect = el.getBoundingClientRect();

        categories
          .map((c) => c.id)
          .forEach((co, idx) => {
            if (checkCategoryShowing(co)) {
              let tag_el = document.querySelector(`#${categories[idx].html_id}`);

              if (tag_el) {
                var rect_el = tag_el.getBoundingClientRect();
                if (rect_el.top >= rect.top) {
                  if (rect_el.bottom < rect.bottom) {
                    visibility_status[idx] = true;
                  } else {
                    visibility_status[idx] = false;
                  }
                } else {
                  visibility_status[idx] = false;
                }
              }
            }
          });

        for (let i = 0; i < categories.length; i++) {
          let last_item = categories.length;
          if (visibility_status[last_item] === true) {
            handleUpdateSelectedOnCategoryBar(categories[last_item].name);
            break;
          } else {
            let first_true_occurence = visibility_status.indexOf(true);
            try {
              handleUpdateSelectedOnCategoryBar(categories[first_true_occurence].name);
            } catch (error) {
              console.log(LOG_PREFIX, 'error', error);
              console.log(LOG_PREFIX, 'categories', categories);
            }
          }
        }

        if (food_entry_list_ref.current.scrollTop > 30) {
          setAlreadyOnTop(false);
        } else {
          setAlreadyOnTop(true);
        }
      });
    }
  }, [food_entry_list_ref]);

  let { advertisement_config } = React.useContext(MenyContext);
  let food_menu_ad = advertisement_config?.filter((ad) => ad.data.ad_window.name === 'food_menu')[0]?.data;

  React.useEffect(() => {
    // consider:
    // add ads counter, open to food menu page
    if (food_menu_ad) {
      console.log(LOG_PREFIX, 'add ads counter + 1');
    }
  }, []);

  return (
    <>
      <TermsAndConditionsSign />
      <Box className={classes.root}>
        <Box className={classes.restaurant_name}>
          <Box component="span">{restaurant_config?.name}</Box>
        </Box>

        <Box className={classes.restaurant_bar} style={{ boxShadow: '0px 3px 6px #00000029' }}>
          <RestaurantBar />
        </Box>

        <ShowNamePlace />

        <FoodMenuAdvertisementWindow />

        <Box>
          <HorizontalScrollBar
            list_ref={food_entry_list_ref}
            list={categories}
            category_selected={category_selected}
            setCategorySelected={setCategorySelected}
          />
        </Box>

        <Box ref={food_entry_list_ref} id="food-menu-food-entry" className={classes.food_menu_food_entry}>
          <FoodMenuBody
            food_menu={restaurant_food_menu}
            list={categories}
            categories={categories}
            food_menu_view_only={false}
            restaurant_config={restaurant_config}
          />
        </Box>

        <Box className={classes.bottom_bar}>
          <BottomBarWithAds />
        </Box>
      </Box>
      <BackToTopButton already_on_top={already_on_top} />
    </>
  );
};
