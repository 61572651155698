import React from 'react';

import { MenyLightContext } from 'src/MenyLightApp/contexts';
import ShowDebug from 'src/components/ShowDebug';

import BottomAds from './BottomAds';

export default () => {
  const { advertisement_config } = React.useContext(MenyLightContext);

  const bottom_ads_config = advertisement_config.filter((ads) => ads.data.ad_window.name === 'food_detail');
  const is_bottom_ads_exist = bottom_ads_config.length > 0;

  if (is_bottom_ads_exist) return <BottomAds bottom_ads_config={bottom_ads_config[0]} />;

  return (
    <>
      <ShowDebug>no bottom ads configured</ShowDebug>
    </>
  );
};
