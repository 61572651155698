import db_config from 'src/configs/db_config';

import db from 'src/Firebase/db';

const RESTAURANT_CONFIG_REF = db.collection(db_config.DB_TABLE_RESTAURANT_CONFIG);

function getRestInfo(rest_id) {
  return RESTAURANT_CONFIG_REF.doc(rest_id).get();
}

function loadRestaurantConfig(rest_id) {
  return RESTAURANT_CONFIG_REF.doc(rest_id).get();
}

function updateRestaurantConfig(rest_id, payload) {
  return RESTAURANT_CONFIG_REF.doc(rest_id).set(payload, { merge: true });
}

function monitorRestaurantConfigChange(rest_id, updateCb) {
  console.log('context', 'calling monitorRestaurantConfigChange');

  return RESTAURANT_CONFIG_REF.doc(rest_id).onSnapshot((doc) => {
    console.log('monitorRestaurantConfigChange', 'doc.data()', doc.data());
    updateCb(doc);
  });
}

function subscribeRestaurantConfig(rest_id) {
  return RESTAURANT_CONFIG_REF.doc(rest_id);
}

function testRestaurantConfigChange(rest_id) {
  console.log('context', 'calling testRestaurantConfigChange');

  return RESTAURANT_CONFIG_REF.doc(rest_id);
}

export {
  getRestInfo,
  loadRestaurantConfig,
  updateRestaurantConfig,
  monitorRestaurantConfigChange,
  testRestaurantConfigChange,
  subscribeRestaurantConfig,
};
