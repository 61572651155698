import { useMutation } from 'react-query';

import config from 'src/config';

const useMutationIncreaseMenyUsageCount = () => {
  let REST_MNGT_ENDPOINT = config.REST_MNGT_ENDPOINT;
  const dateToFormat = Date.now();
  return useMutation(
    ['increase_meny_usage_count', dateToFormat],
    async (rest_id) => {
      const response = await fetch(
        `${REST_MNGT_ENDPOINT}/increaseMenyUsageCount?rest_id=${rest_id}`
      );

      if (!response.ok) {
        // throw new Error('Network response was not ok');
        console.log('error during adding ads impressions');
      }

      return response.json();
    }
  );
};

export default useMutationIncreaseMenyUsageCount;
