import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  food_menu_ad: {
    background: 'rgba( 0, 0, 0, 0.50 )',

    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',

    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    backdropFilter: 'blur( 10px )',

    width: '100%',
    height: '75px',
    minHeight: '75px',
  },
}));

export { useStyles };
