import React from 'react';
import firebase from 'firebase/app';
import { useNavigate } from 'react-router-dom';
import { Box, Button } from '@material-ui/core';
import { MenyContext } from '../../../contexts';

import ItemAddToOrderHistorySign from '../ItemAddToOrderHistorySign';
import TableNotReadySign from '../TableNotReadySign';
import FoodOutOfStock from '../FoodOutOfStock';
import ListOrderItem from './ListOrderItem';
import ConfirmButton from './ConfirmButton';

import OrderListAdvertisementWindow from '../../../components/OrderListAdvertisementWindow';

import RestaurantBar from '../../../components/RestaurantBar';
import BottomBarWithAds from '../../../components/BottomBarWithAds';
import ShowDebug from 'src/components/ShowDebug';

import isRestaurantClosed from '../../../utils/isRestaurantClosed';
import { getOrderHistoryAdsLink } from '../../../../utils/getLinks';

import { CLIENT_TABLE_ASSIGNED } from 'src/StateMachines/STATES';

import { LYNKED_BLACK, LYNKED_WHITE } from 'src/consts/colors';
import active_lang from 'src/langs/jp_en';
import useMutationFoodMenu from 'src/hooks/useMutationFoodMenu';
import useMutateUpdateAllFoods from 'src/hooks/useMutateUpdateAllFoods';
import FoodOverStock from '../FoodOverStock';
import FoodGroupOverStock from '../FoodGroupOverStock';
import { compact } from 'lodash';
import { useTranslation } from 'react-i18next';

const STATUS_PREPARING = 'preparing';

const LOG_PREFIX = 'ShowOrderListPage';

function OrderListPageHead({ restaurant_name }) {
  return (
    <>
      <Box
        style={{
          color: LYNKED_WHITE,
          backgroundColor: LYNKED_BLACK,
          minHeight: '20px',
          display: 'flex',
          flexFlow: 'column',
          justifyContent: 'center',

          fontSize: '10px',
          lineHeight: '18px',
        }}
      >
        <Box component="span">{restaurant_name}</Box>
      </Box>
      <Box
        style={{
          backgroundColor: LYNKED_WHITE,
          height: '100px', //for iphone
          minHeight: '100px', // for chrome
          boxShadow: '0px 3px 6px #00000029',
        }}
      >
        <RestaurantBar />
      </Box>
    </>
  );
}

function CheckOutCartButtonHello({ order_total }) {
  // consideration:
  // 1. if there is no item in cart, should not show the button
  // 2. if food in the cart , show button
  // 3. if user not have table assigned, hide button

  let {
    rest_id,
    cart_items,
    setCartItems,
    meny_client_state_machine,
    restaurant_config,
    updateNumOfFoodItemCount,
    setOpenFoodAddToCartSign,
    monitor_order_status_machine,
    handleUpdateMenyOrder,
    handleUpdateCartItems,
  } = React.useContext(MenyContext);
  let [current_meny_client_state_machine] = meny_client_state_machine;
  let [current_monitor_order_status] = monitor_order_status_machine;
  const [open_table_not_ready, setOpenTableNotReady] = React.useState(false);
  const [foodOutOfStock, setFoodOutOfStock] = React.useState(false);
  const [listFoodOutOfStock, setListFoodOutOfStock] = React.useState([]);
  const [foodOverStockCount, setFoodOverStockCount] = React.useState(false);
  const [listFoodOverStockCount, setListFoodOverStockCount] = React.useState([]);
  const [foodGroupOverStock, setFoodGroupOverStock] = React.useState(false);
  const [listFoodGroupOverStock, setListFoodGroupOverStock] = React.useState([]);
  const mutationFoodMenu = useMutationFoodMenu();
  const mutationUpdateAllFoods = useMutateUpdateAllFoods();

  const handleCheckOutOnClick = () => {
    for (let i = 0; i < cart_items.length; i++) {
      cart_items[i]['status'] = STATUS_PREPARING;
      cart_items[i]['created'] = firebase.firestore.Timestamp.now();
    }

    let sanitized_cart_items = cart_items.filter((ci) => ci.item_quantity > 0);
    let { order_history_id } = current_monitor_order_status.context;

    handleUpdateMenyOrder(rest_id, order_history_id, sanitized_cart_items)
      .then(() => {
        setCartItems([]);
        updateNumOfFoodItemCount([]);
        // handleCookieSetCartItems([]);
        setOpenFoodAddToCartSign(true);
      })
      .catch((error) => console.error(LOG_PREFIX, 'error', error));
  };

  const handleTryCheckOut = () => {
    // check food is stock out or not
    mutationFoodMenu.mutateAsync(rest_id).then((res) => {
      let listItems = {};
      cart_items.forEach((item, index) => {
        if (listItems[item.item_food_id]) {
          listItems[item.item_food_id].quantity += item.item_quantity;
        } else {
          listItems[item.item_food_id] = {
            id: item.item_food_id,
            name: item.item_name,
            quantity: item.item_quantity,
          };
        }
        listItems[item.item_food_id].foodStockCount = res.food_details[item.item_food_id]?.foodStockCount
          ? Number(res.food_details[item.item_food_id]?.foodStockCount)
          : -1;
        listItems[item.item_food_id].soldOut = res.food_details[item.item_food_id]?.sold_out === 'STATUS_SOLD_OUT';
      });
      const arrayItems = Object.keys(listItems).map((key) => listItems[key]);
      const listFoodGroupOverStockCount = arrayItems.filter(
        (item) => (item.foodStockCount != -1 && item.quantity > item.foodStockCount) || item.soldOut
      );

      if (listFoodGroupOverStockCount.length) {
        setListFoodGroupOverStock(listFoodGroupOverStockCount);
        setFoodGroupOverStock(true);
        return;
      }

      const arrRestaurantIdOutOfStock = Object.values(res.food_details)
        .filter((food) => food.sold_out === active_lang.STATUS_SOLD_OUT)
        .map((item) => item.id);
      // let listItems = []
      const foodsOutStock = cart_items.filter((item) => arrRestaurantIdOutOfStock.includes(item.item_food_id));
      const foodsOverStockCount = [];

      Object.values(res.food_details).map((food) => {
        const listItems = cart_items.map((item) => {
          if (food.id === item.item_food_id && food.isEnableStockCount) {
            if (item.item_quantity > parseInt(food.foodStockCount)) {
              foodsOverStockCount.push(item);
              item.item_quantity = parseInt(food.foodStockCount);
              item.item_subtotal = item.item_price * parseInt(food.foodStockCount);
            }
          }
          return item;
        });
        handleUpdateCartItems(listItems);
      });

      if (foodsOutStock.length) {
        const cartItems = cart_items.filter((item) => !arrRestaurantIdOutOfStock.includes(item.item_food_id));
        handleUpdateCartItems(cartItems);
        setListFoodOutOfStock(foodsOutStock);
        setFoodOutOfStock(true);
        return;
      }
      if (foodsOverStockCount.length) {
        setListFoodOverStockCount(foodsOverStockCount);
        setFoodOverStockCount(true);
        return;
      }

      // consideration:
      // 1. user click when no table assigned
      // 2. user click with table assigned
      // 3. user click when restaurant is closed
      if (current_meny_client_state_machine.matches(CLIENT_TABLE_ASSIGNED)) {
        if (isRestaurantClosed(restaurant_config)) {
        } else {
          // reduce quantity in stock
          Object.values(res.food_details).map((food) => {
            cart_items.map((item) => {
              if (food.id === item.item_food_id && food.isEnableStockCount) {
                const foodStockCount = parseInt(food.foodStockCount) - item.item_quantity;
                if (foodStockCount <= 0) {
                  food.foodStockCount = 0;
                  food.sold_out = active_lang.STATUS_SOLD_OUT;
                  food.isEnableStockCount = false;
                } else {
                  food.foodStockCount = foodStockCount;
                }
              }
            });
            res.food_details[food.id] = food;
          });

          mutationUpdateAllFoods.mutateAsync({ rest_id, food_menu: res }).catch((err) => {
            console.err(err);
          });

          handleCheckOutOnClick();
        }
      } else {
        setOpenTableNotReady(true);
      }
    });
  };

  return (
    <>
      <TableNotReadySign open={open_table_not_ready} setOpen={setOpenTableNotReady} />
      <FoodOutOfStock open={foodOutOfStock} setOpen={setFoodOutOfStock} listFoodOutOfStock={listFoodOutOfStock} />
      <FoodGroupOverStock
        open={foodGroupOverStock}
        setOpen={setFoodGroupOverStock}
        listFoodGroupOverStock={listFoodGroupOverStock}
      />
      <FoodOverStock
        open={foodOverStockCount}
        setOpen={setFoodOverStockCount}
        listFoodOverStockCount={listFoodOverStockCount}
      />
      {cart_items && cart_items.length > 0 ? (
        <Button
          id="confirm-food-list-checkout"
          fullWidth
          onClick={handleTryCheckOut}
          disabled={isRestaurantClosed(restaurant_config)}
        >
          <ConfirmButton order_total={order_total} />
        </Button>
      ) : (
        <></>
      )}
    </>
  );
}

export default () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  let {
    menu_id,
    rest_id,
    meny_client_state_machine,
    monitor_order_status_machine,
    cart_items,
    setCartItems,
    open_food_add_to_cart_sign,
    setOpenFoodAddToCartSign,
    updateNumOfFoodItemCount,
    restaurant_config,
  } = React.useContext(MenyContext);

  let [current_meny_client_state_machine] = meny_client_state_machine;
  let [current_monitor_order_status] = monitor_order_status_machine;

  const [order_total, setOrderTotal] = React.useState(0);

  const handleOnFoodAddToCartSignDismiss = () => {
    setOpenFoodAddToCartSign(false);
    // navigate(`/meny/${rest_id}/${menu_id}/OrderHistoryWithAds`, {
    //   replace: true,
    // });
    navigate(getOrderHistoryAdsLink(rest_id, menu_id), { replace: true });
  };

  const getOrderTotal = () => {
    let temp_sum = 0;
    let temp_item_price = cart_items ? cart_items.map((cart_item) => cart_item.item_subtotal) : [];
    temp_item_price.forEach((price) => (temp_sum = temp_sum + price));
    setOrderTotal(Math.max(0, temp_sum).toFixed(2));
  };

  React.useEffect(() => {
    getOrderTotal();
  }, [cart_items]);

  return (
    <>
      <ItemAddToOrderHistorySign
        open={open_food_add_to_cart_sign}
        setOpen={setOpenFoodAddToCartSign}
        handleClose={handleOnFoodAddToCartSignDismiss}
      />

      <Box style={{ height: '100%', display: 'flex', flexFlow: 'column' }}>
        <OrderListPageHead restaurant_name={restaurant_config.name} />
        <OrderListAdvertisementWindow food_menu={`food_menu_result.data`} ads_info={`ads_info`} />
        <Box
          style={{
            height: '40px',
            fontSize: '20px',
            lineHeight: '28px',
            textAlign: 'left',
            padding: '0.5rem 1rem 0 1rem',
            background: '#fafafa',
          }}
        >
          {t('COMMON_ORDER_LIST')}
        </Box>
        <Box
          style={{
            flexGrow: 1,
            overflowY: 'auto',
          }}
        >
          <ListOrderItem
            cart_items={cart_items}
            setCartItems={setCartItems}
            getOrderTotal={getOrderTotal}
            updateNumOfFoodItemCount={updateNumOfFoodItemCount}
            restaurant_config={restaurant_config}
          />
        </Box>
        {/* <ShowDebug>{JSON.stringify(cart_items)}</ShowDebug> */}
        <Box style={{ minHeight: '75px', width: '100%' }}>
          <CheckOutCartButtonHello order_total={order_total} />
        </Box>
        <Box style={{ minHeight: '75px' }}>
          <BottomBarWithAds />
        </Box>
      </Box>
    </>
  );
};
