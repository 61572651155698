import React, { useState, useCallback } from 'react';
import { useQuery } from 'react-query';

import { loadRestaurantConfig } from 'src/modals/restaurant_config';

const useQueryRestaurantConfig = (rest_id) => {
  return useQuery(
    `query-restaurant-config-${rest_id}`,
    () => {
      return loadRestaurantConfig(rest_id).then((doc) => {
        return doc.data();
      });
    },
    { refetchOnWindowFocus: false, retry: true }
  );
};

export default useQueryRestaurantConfig;
