import React from 'react';

export default () => {
  let [monitor_restaurant_config, setMonitorRestaurantConfig] = React.useState(null);
  let [is_loading_restaurant_config, setIsLoadingRestaurantConfig] = React.useState(true);

  return {
    monitor_restaurant_config,
    setMonitorRestaurantConfig,
    is_loading_restaurant_config,
    setIsLoadingRestaurantConfig,
  };
};
