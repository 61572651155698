import React from 'react';

export default ({ rest_id }) => {
  let STORED_PATH = `${rest_id}/restaurant_closed_sign_prompted`;

  const clearSorryRestaurantClosedSignPromptedAlready = () => localStorage.removeItem(STORED_PATH);
  const loadSorryRestaurantClosedSignPromptedAlready = () => localStorage.getItem(STORED_PATH);

  const storeSorryRestaurantClosedSignPromptedAlready = (status_id) => {
    return localStorage.setItem(STORED_PATH, status_id);
  };

  return {
    loadSorryRestaurantClosedSignPromptedAlready,
    storeSorryRestaurantClosedSignPromptedAlready,
    clearSorryRestaurantClosedSignPromptedAlready,
  };
};
