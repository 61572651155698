import { Box, Button, Dialog } from '@material-ui/core';
import React from 'react';

import ShowDebug from 'src/components/ShowDebug';

import { LYNKED_WHITE } from 'src/consts/colors';
import active_lang from 'src/langs/jp_en';

import { useStyles } from './style';
import { useTranslation } from 'react-i18next';

export default function SorryWeAreClosedDialog({ open, setOpen, acknowledgeDialog }) {
  let classes = useStyles();
  const { t } = useTranslation();

  const handleUserAlreadyKnownRestaurantClosed = () => {
    setOpen(false);
    acknowledgeDialog();
  };

  return (
    <>
      <Dialog
        open={open}
        style={{ height: '100%' }}
        BackdropProps={{
          style: {
            background: 'rgba( 0, 0, 0, 0.15 )',
            boxShadow: '0 8px 32px 0 rgba( 31, 38, 135, 0.37 )',
            backdropFilter: 'blur( 4px )',
            borderRadius: '10px',
            border: '1px solid rgba( 255, 255, 255, 0.18 )',
          },
        }}
      >
        {/* <ShowDebug>SorryWeAreClosedDialog</ShowDebug> */}
        <Box className={classes.root}>
          <Box className={classes.dialog_container}>
            <Box
              style={{
                width: '90vw',
                minHeight: '250px',
                backgroundColor: LYNKED_WHITE,

                display: 'flex',
                flexFlow: 'column',
                justifyContent: 'space-between',
              }}
            >
              <Box
                style={{
                  paddingTop: '2rem',
                  paddingBottom: '1rem',

                  width: '100%',
                  textAlign: 'center',

                  display: 'flex',
                  flexFlow: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                id={'sorry_we_are_closed_dialog'}
              >
                <Box style={{ width: '80%', textAlign: 'center' }}>
                  <Box pb={'1rem'}>{t('OTHERS_CLOSE_STORE_MSG')}</Box>
                  <Box pb={'1rem'}>{t('OTHERS_WELCOME_BACK_MSG')}</Box>
                </Box>
              </Box>

              <Box style={{ width: '100%' }}>
                <Button
                  id="ack_sorry_we_are_closed"
                  fullWidth
                  style={{
                    height: '50px',
                    color: LYNKED_WHITE,
                    background: '#388E00 0% 0% no-repeat padding-box',
                    borderRadius: 'unset',
                  }}
                  onClick={handleUserAlreadyKnownRestaurantClosed}
                >
                  {active_lang.OPENING_HOURS_OK_TEXT_MENY_LIGHT}
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Dialog>
    </>
  );
}
