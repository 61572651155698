// client_state_machine

export const RESTAURANT_CLOSED = 'RESTAURANT_CLOSED';
export const RESTAURANT_OPENED = 'RESTAURANT_OPENED';

export const NOT_MONITORING = 'NOT_MONITORING';
export const MONITORING = 'MONITORING';

export const CLIENT_BILL_CLEARED = 'CLIENT_BILL_CLEARED';
export const RESTAURANT_STATUS_UNKNOWN = 'RESTAURANT_STATUS_UNKNOWN';
export const CLIENT_WAITING_TABLE = 'CLIENT_WAITING_TABLE';
export const CLIENT_TABLE_ASSIGNED = 'CLIENT_TABLE_ASSIGNED';
// export const RESTAURANT_ASSIGNED_TABLE = 'RESTAURANT_ASSIGNED_TABLE';
export const RESTAURANT_CLEAR_BILL = 'RESTAURANT_CLEAR_BILL';
export const CLIENT_LANDING = 'CLIENT_LANDING';
export const CLIENT_REQUEST_TABLE = 'CLIENT_REQUEST_TABLE';

// import {
//   RESTAURANT_CLOSED,
//   RESTAURANT_OPENED,
//   NOT_MONITORING,
//   MONITORING,
//   CLIENT_BILL_CLEARED,
//   RESTAURANT_STATUS_UNKNOWN,
//   CLIENT_WAITING_TABLE,
//   CLIENT_TABLE_ASSIGNED,
//   RESTAURANT_ASSIGNED_TABLE,
//   RESTAURANT_CLEAR_BILL,
//   CLIENT_LANDING,
// } from 'src/StateMachines/STATES';
