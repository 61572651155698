import React from 'react';
import { useNavigate } from 'react-router';

import { MenyContext } from '../../../contexts';

import { getNewUserLineupLink } from 'src/utils/getLinks';

export default function RedirectToLineupPage() {
  let navigate = useNavigate();
  let { rest_id, menu_id } = React.useContext(MenyContext);

  React.useEffect(() => {
    navigate(getNewUserLineupLink(rest_id, menu_id), { replace: true });
  }, []);
  return <></>;
}
