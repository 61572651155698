// import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core';

import { LYNKED_WHITE } from 'src/consts/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexFlow: 'column',
    background: '#ACACAC 0% 0% no-repeat padding-box',
    justifyContent: 'center',
    alignItems: 'center',
    overflowX: 'hidden',
  },
  dialog_container: {
    width: '90vw',
    backgroundColor: LYNKED_WHITE,

    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'space-between',
  },
  button_no: {
    color: theme.palette.error.main,
  },
}));

export { useStyles };
