const getAdjustedItemPrice = (item_price, customization_list) => {
  try {
    let total = Number(item_price);
    let adjustment = 0;
    console.log('getAdjustedItemPrice', 'customization_list', customization_list);

    for (const c of customization_list) {
      adjustment = Number(adjustment) + Number(c.price);
    }
    console.log('getAdjustedItemPrice', 'item_price', item_price);

    return Number(Math.max(0, total + adjustment).toFixed(2));
  } catch (error) {
    console.log('getAdjustedItemPrice', 'error', item_price, customization_list);
  }
};

export default getAdjustedItemPrice;
