import React from 'react';
import Dialog from '@material-ui/core/Dialog';

import useMutationIncreaseMenyUsageCount from 'src/hooks/useMutationIncreaseMenyUsageCount';

import { MenyContext } from '../../contexts';

import RestaurantIsNotActive from '../../components/RestaurantIsNotActive';

import DialogBody from './DialogBody';

const LOG_PREFIX = 'FoodDetailDialog';

export default function FoodDetailDialog({ food_entry, open, setOpen, food_menu_view_only }) {
  let [is_loading, setIsLoading] = React.useState(true);

  const { rest_id, restaurant_config, increaseAdsImpressions, advertisement_config } = React.useContext(MenyContext);

  let mutation_increase_meny_usage_count = useMutationIncreaseMenyUsageCount();

  let food_menu_ad = advertisement_config?.filter((ad) => ad.data.ad_window.name === 'food_menu')[0]?.data;

  const handleClose = () => {
    // meny 利用率, add on every shown of food menu
    mutation_increase_meny_usage_count
      .mutateAsync(rest_id)
      .then((res_json) => console.log(LOG_PREFIX, 'mutation_increase_meny_usage_count', { rest_id, res_json }))
      .catch((err) => console.error(LOG_PREFIX, 'mutation_increase_meny_usage_count', err));

    if (food_menu_ad) {
      // consider:
      // add counter when return food menu page, for food menu ads,
      console.log(LOG_PREFIX, 'add ads counter + 1');
      increaseAdsImpressions({ rest_id });
    }

    setOpen(false);
  };

  const handleOnEnter = () => {};

  React.useEffect(() => {
    if (food_entry && restaurant_config) {
      setIsLoading(false);
    }
  }, [food_entry, restaurant_config]);

  if (is_loading) return <></>;

  if (restaurant_config.restaurant_active === false) return <RestaurantIsNotActive />;

  return (
    <>
      <Dialog
        open={open}
        style={{ height: '100%' }}
        onEnter={handleOnEnter}
        transitionDuration={{ enter: 0, exit: 0 }}
        fullScreen
      >
        <DialogBody
          setOpen={setOpen}
          handleBackOnClick={handleClose}
          food_entry={food_entry}
          restaurant_config={restaurant_config}
        />
      </Dialog>
    </>
  );
}
