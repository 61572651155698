import React from 'react';

const NOT_MONITORING = 'not_monitoring';

const handleGetLastAlertClearedCount = ({ rest_id }) => {
  let count = localStorage.getItem(`${rest_id}/last_alert_cleared_count`);

  return count ? parseInt(count) : 0;
};

export default ({ rest_id }) => {
  let [backgroundmonitoring_to_monitor1, setBackgroundMonitoringToMonitor1] = React.useState(null);

  let [service_requests_count, setServiceRequestsCount] = React.useState(0);
  let [service_request_not_cleared, setServiceRequestNotCleared] = React.useState({ state: [] });
  let [service_request_cleared, setServiceRequestCleared] = React.useState({ state: [] });
  let [last_alert_cleared_count, setLastAlertClearedCount] = React.useState(
    handleGetLastAlertClearedCount({ rest_id })
  );

  return {
    backgroundmonitoring_to_monitor1,
    setBackgroundMonitoringToMonitor1,

    service_requests_count,
    setServiceRequestsCount,

    service_request_not_cleared,
    setServiceRequestNotCleared,

    service_request_cleared,
    setServiceRequestCleared,

    last_alert_cleared_count,
    setLastAlertClearedCount,
  };
};
