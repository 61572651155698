import React from 'react';
import { Box } from '@material-ui/core';
import { Outlet } from 'react-router-dom';

export default function MenyLightLayout() {
  return (
    <>
      <Box style={{ height: '100%', overflowY: 'auto' }}>
        <Outlet />
      </Box>
    </>
  );
}
