import React from 'react';
import moment from 'moment-timezone';

let LOG_PREFIX = 'AcceptTAndC.shared_var';

export default ({ rest_id }) => {
  let [helloworld_to_monitor1, setHelloworldToMonitor1] = React.useState('helloworld_to_monitor1');

  let STORE_PATH = `/user_accepted_t_and_c`;

  function getExpireTime() {
    var input = moment();
    var output = input.clone().startOf('year').hour(6);
    return output > input ? output : output.add(1, 'day');
  }
  const clearUserAcceptedTAndC = () => localStorage.removeItem(STORE_PATH);

  const loadUserAcceptedTAndC = () => {
    let ls_raw = JSON.parse(localStorage.getItem(STORE_PATH));
    console.log(LOG_PREFIX, 'loadUserAcceptedTAndC', 'ls_raw', ls_raw);

    if (ls_raw?.accept_status && ls_raw.t_and_c_contract_date) return ls_raw;

    return null;
  };

  const storeUserAcceptedTAndC = ({ accept_status, t_and_c_contract_date }) => {
    return localStorage.setItem(
      STORE_PATH,
      JSON.stringify({ accept_status, t_and_c_contract_date, expire: getExpireTime() })
    );
  };

  return {
    helloworld_to_monitor1,
    setHelloworldToMonitor1,

    loadUserAcceptedTAndC,
    storeUserAcceptedTAndC,
    clearUserAcceptedTAndC,
  };
};
