import React, { useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Box, Button } from '@material-ui/core';
import { ReactComponent as MenuIcon } from 'src/assets/icons/menu.svg'

import { useStyles } from './styles';
import { MenyContext } from '../../contexts';
import active_lang from 'src/langs/jp_en';

import { getFoodMenuLandingLink } from 'src/utils/getLinks';
import {
  useMatch,
  useResolvedPath,
} from "react-router-dom";
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

export default function FoodMenuButton() {
  const classes = useStyles();
  let { rest_id, menu_id } = React.useContext(MenyContext);
  const foodMenuLandingLink = useMemo(() => getFoodMenuLandingLink(rest_id, menu_id), [rest_id, menu_id]);
  const { t } = useTranslation();

  const resolved = useResolvedPath(foodMenuLandingLink);
  const match = useMatch({ path: resolved.pathname });

  return (
    <Box>
      <RouterLink to={foodMenuLandingLink} style={{ textDecoration: 'none' }} replace>
        <Button id="button-food-menu-landing" className={classNames(classes.button, { active: match})}>
        <Box className={classes.button_style}>
          <Box p={0} m={0} className={classes.button_icon}>
            <MenuIcon width={22} height={22} className="btn-icon" />
          </Box>
          <Box p={0} m={0}>
            {t('MENU_MENU')}
          </Box>
        </Box>
      </Button>
    </RouterLink>
    </Box >
  );
}
