import React from 'react';
import { Box } from '@material-ui/core';

import RestaurantBar from '../../components/RestaurantBar';
import { MenyLightContext } from 'src/MenyLightApp/contexts';

import { useStyles } from './styles';

export default () => {
  let classes = useStyles();

  let { restaurant_config } = React.useContext(MenyLightContext);

  return (
    <>
      <div
        style={{
          backgroundColor: 'black',
          color: 'white',
          minHeight: '20px',
          display: 'flex',
          flexFlow: 'column',
          justifyContent: 'center',
          fontSize: '10px',
          lineHeight: '18px',
        }}
      >
        <span>{restaurant_config.name}</span>
      </div>
      <Box className={classes.restaurant_bar} style={{ boxShadow: '0px 3px 6px #00000029' }}>
        <RestaurantBar />
      </Box>
    </>
  );
};
