import { makeStyles } from '@material-ui/core';
import { LYNKED_RED } from 'src/consts/colors';

const useStyles = makeStyles(() => ({
  buttons: {
    borderRadius: '26px',
    backgroundColor: LYNKED_RED,
    border: '1px solid #707070',
    width: '28px',
    height: '28px',

    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  number_value: {
    width: '3rem',
  },
}));

export { useStyles };
