import { isNull, isUndefined } from 'lodash';

function testValidItemCustomizeSetting(things_to_test) {
  if (!isNull(things_to_test)) {
    if (!isUndefined(things_to_test)) {
      if (Array.isArray(things_to_test)) {
        if (things_to_test.length > 0 && !isUndefined(things_to_test[0].name))
          return true;
      }
    }
  }
  return false;
}

export default testValidItemCustomizeSetting;
