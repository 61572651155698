import React from 'react';
import { Dialog } from '@material-ui/core';

import TableAssignedSign from 'src/MenyApp/views/TableAssignedSign';

import { Transition } from './styles';
import { dialog_transition_duration } from 'src/consts/TRANSITION';
import { MenyContext } from 'src/MenyApp/contexts';

let LOG_PREFIX = 'TableAvailableDialog';

export default ({ open, setOpen }) => {
  // const { storeAssignedTablePromptedAlreadyNumber } = React.useContext(AssignedTablePromptedAlreadyContext);
  let { storeAssignedTablePromptedAlreadyNumber, table_name } = React.useContext(MenyContext);

  const handleClose = () => {
    console.log(LOG_PREFIX, 'client tap ok on table ack');

    storeAssignedTablePromptedAlreadyNumber({ done: 1 });

    setOpen(false);
  };

  return (
    <>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        transitionDuration={dialog_transition_duration}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
        style={{ zIndex: 9999 }}
      >
        <TableAssignedSign table_name={table_name} handleOKClick={handleClose} />
      </Dialog>
    </>
  );
};
