import React from 'react';

import { Box } from '@material-ui/core';

import ShowFoodTag from './ShowFoodTag';
import ShowFoodEntry from './ShowFoodEntry';

import { STATUS_FOOD_HIDE, STATUS_FOOD_SHOW } from 'src/modals/food_details';
import { LYNKED_WHITE } from 'src/consts/colors';
import { isDefined } from 'src/utils/lodash-cust';

function ShowFoodMenuBody({ food_menu, categories, food_menu_view_only, restaurant_config }) {
  return (
    <>
      {categories
        .filter((c) => {
          if (isDefined(c.category_show)) {
            return c.category_show === STATUS_FOOD_SHOW;
          } else {
            return true;
          }
        })
        .map((temp_tag, idx) => {
          let tag_name = temp_tag.name;
          let tag_id = temp_tag.id;
          let tag_html_id = temp_tag.html_id;

          return (
            <Box key={idx} style={{ textAlign: 'left' }}>
              <ShowFoodTag tag_name={tag_name} id={tag_html_id} />
              <Box>
                {food_menu.columns[tag_id].taskIds
                  .filter((food_id) => {
                    let food_entry = food_menu.food_details[food_id];
                    if (isDefined(food_entry.food_show_status) && food_entry.food_show_status === STATUS_FOOD_HIDE) {
                      return false;
                    }
                    return true;
                  })
                  .map((food_id, idx) => {
                    let food_entry = food_menu.food_details[food_id];

                    return (
                      <ShowFoodEntry
                        food_id={food_id}
                        food_entry={food_entry}
                        idx={idx}
                        key={`show_food_entry_${idx}`}
                        food_menu_view_only={food_menu_view_only}
                        restaurant_config={restaurant_config}
                      />
                    );
                  })}
              </Box>
            </Box>
          );
        })}
    </>
  );
}

export default ShowFoodMenuBody;
