import React from 'react';
import { MenyContext } from '.';

export default ({ children }) => {
  let { setIsInitDone } = React.useContext(MenyContext);

  React.useEffect(() => {
    setIsInitDone(true);
  }, []);

  return <>{children}</>;
};
