import React from 'react';

import ShowDebug from 'src/components/ShowDebug';

import FullScreenLoading from '../components/FullScreenLoading';

import { MenyLightContext } from '.';

let LOG_PREFIX = 'PreLoadAsset';

export default ({ children }) => {
  let [is_loading, setIsLoading] = React.useState(true);

  let { is_loading_restaurant_assets, is_loading_advertisement, is_loading_food_menu } =
    React.useContext(MenyLightContext);

  React.useEffect(() => {
    if (is_loading_advertisement || is_loading_food_menu || is_loading_restaurant_assets) {
      console.log(LOG_PREFIX, {
        is_loading,
        is_loading_advertisement,
        is_loading_food_menu,
        is_loading_restaurant_assets,
      });
    } else setIsLoading(false);
  }, [is_loading_advertisement, is_loading_food_menu, is_loading_restaurant_assets]);

  if (is_loading)
    return (
      <>
        {/* <ShowDebug>
          PreLoadAsset{JSON.stringify({ is_loading, is_loading_advertisement, is_loading_food_menu })}
        </ShowDebug> */}
        <FullScreenLoading />
      </>
    );

  return <>{children}</>;
};
