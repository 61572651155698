import React from 'react';
import { Outlet } from 'react-router-dom';

import IncreaseMenyUsageCount from 'src/components/IncreaseMenyUsageCount';
import ShowDebug from 'src/components/ShowDebug';

import { MenyContext } from '../../contexts';

const LOG_PREFIX = 'MenyApp/MenyLayout';

export default () => {
  let { rest_id, user_engage_state, setUserEngageState, USER_NOT_ENGAGED, USER_ENGAGED } =
    React.useContext(MenyContext);

  React.useEffect(() => {
    setUserEngageState(USER_ENGAGED);

    return () => {
      setUserEngageState(USER_NOT_ENGAGED);
    };
  });

  return (
    <>
      {/* <ShowDebug>{JSON.stringify({ user_engage_state })}</ShowDebug> */}
      <IncreaseMenyUsageCount rest_id={rest_id} />
      <Outlet />
    </>
  );
};
