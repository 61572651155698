import React from 'react';
import { Box, IconButton } from '@material-ui/core';

import arrowRight from '@iconify-icons/gridicons/arrow-right';
import { Icon } from '@iconify/react';

import { MenyContext } from '../../contexts';

import { useStyles } from './styles';

let LOG_PREFIX = 'TapToFoodDetail';

export default ({ food_menu, ads_info }) => {
  let classes = useStyles();
  let { rest_id, increaseAdsImpressions, open_food_detail_dialog, setOpenFoodDetailDialog, food_detail, setFoodEntry } =
    React.useContext(MenyContext);

  let target_food_detail_id = ads_info?.ad_destination?.food_detail_id;
  let target_food_detail = food_menu.food_details[target_food_detail_id];
  let { image_urls } = ads_info;

  const handleAdBannerOnClick = () => {
    console.log(LOG_PREFIX, 'handleAdBannerOnClick, click to food detail');
    if (target_food_detail) {
      console.log(LOG_PREFIX, 'target_food_detail', target_food_detail);
      setFoodEntry(target_food_detail);
      setOpenFoodDetailDialog(true);
    }
  };

  React.useEffect(() => {
    increaseAdsImpressions({ rest_id });
  }, []);

  return (
    <>
      <Box
        className={classes.root}
        style={{
          // don't move for iphone styling
          background: 'rgba( 255, 255, 255, 0.50 )',
          backgroundImage: `url(${image_urls[0]})`,

          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',

          backdropFilter: 'blur( 10px )',
        }}
      >
        <Box className={classes.food_menu_ad} style={{ backgroundImage: `url(${image_urls[0]})` }}>
          <IconButton id={'tap-to-food-detail-button'} className={classes.button} onClick={handleAdBannerOnClick}>
            <Icon icon={arrowRight} />
          </IconButton>
        </Box>
      </Box>
    </>
  );
};
