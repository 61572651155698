import { useMutation } from 'react-query';

import config from 'src/config';

let { REST_MNGT_ENDPOINT } = config;

let LOG_PREFIX = 'useMutationGetTicketIndex';

const useMutationGetTicketIndex = () => {
  return useMutation(
    'get-ticket-index',
    async (rest_id) => {
      let url = `${REST_MNGT_ENDPOINT}/getTicketIndex?rest_id=${rest_id}`;
      console.log(LOG_PREFIX, 'useMutationGetTicketIndex', 'url', url);
      const response = await fetch(url);

      if (!response.ok) {
        // throw new Error('Network response was not ok');
        console.log(LOG_PREFIX, 'error during getting ticket');
      }

      return response.json();
    },
    { retry: 5 }
  );
};

export default useMutationGetTicketIndex;
