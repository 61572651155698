import React from 'react';
import { isUndefined } from 'lodash';

import FoodMenuLandingPage from '../FoodMenuLandingPage';
import { MenyLightContext } from '../../contexts';

import FullScreenLoading from '../../components/FullScreenLoading';
import RestaurantIsNotActive from '../../components/RestaurantIsNotActive';

export default function MenyLightInitializePage() {
  let [is_loading, setIsLoading] = React.useState(true);
  let [restaurant_active, setRestaurantActive] = React.useState(false);

  let { restaurant_config, advertisement_config, food_menu } = React.useContext(MenyLightContext);

  React.useEffect(() => {
    if (isUndefined(restaurant_config?.restaurant_active) || restaurant_config.restaurant_active) {
      setRestaurantActive(true);
    } else {
      setRestaurantActive(false);
    }
  }, [restaurant_config]);

  React.useEffect(() => {
    if (restaurant_config && food_menu && advertisement_config) {
      setIsLoading(false);
    }
  }, [restaurant_config, food_menu, advertisement_config]);

  if (is_loading) return <FullScreenLoading />;
  if (!restaurant_active) return <RestaurantIsNotActive />;

  return <FoodMenuLandingPage />;
}
