import React from 'react';

import ShowDebug from 'src/components/ShowDebug';
import FullScreenLoading from '../components/FullScreenLoading';

import { MenyContext } from '.';

let LOG_PREFIX = 'PreLoadAsset';

export default ({ children }) => {
  let [is_loading, setIsLoading] = React.useState(true);

  let {
    is_loading_restaurant_config,
    restaurant_food_menu,
    is_loading_advertisement,
    is_loading_food_menu,
    is_loading_restaurant_assets,
  } = React.useContext(MenyContext);

  React.useEffect(() => {
    console.log(LOG_PREFIX, 'restaurant_food_menu', restaurant_food_menu);
    if (
      is_loading_restaurant_config ||
      is_loading_food_menu ||
      is_loading_advertisement ||
      is_loading_restaurant_assets
    ) {
    } else setIsLoading(false);
  }, [is_loading_restaurant_config, is_loading_food_menu, is_loading_advertisement, is_loading_restaurant_assets]);

  if (is_loading)
    return (
      <>
        {/* <ShowDebug>{JSON.stringify(restaurant_food_menu, null, 2)}</ShowDebug>
        <ShowDebug>
          PreLoadAsset{JSON.stringify({ is_loading, is_loading_advertisement, is_loading_food_menu })}
        </ShowDebug> */}
        <FullScreenLoading />
      </>
    );

  return <>{children}</>;
};
