import React from 'react';

let initialize_done = false;

export default function FoodMenuSharedVar() {
  let [foodmenu_to_monitor1, setFoodMenuToMonitor1] = React.useState(null);

  let [food_menu, setFoodMenu] = React.useState();
  let [is_loading_food_menu, setIsLoadingFoodMenu] = React.useState(true);

  return {
    foodmenu_to_monitor1,
    setFoodMenuToMonitor1,

    food_menu,
    setFoodMenu,
    is_loading_food_menu,
    setIsLoadingFoodMenu,
  };
}
