import React from 'react';
import FullScreenLoading from '../../../components/FullScreenLoading';
import { MenyContext } from '../../../contexts';

import ShowFoodMenuPage from './ShowFoodMenuPage';

export default () => {
  let [is_loading, setIsLoading] = React.useState(true);

  let { menu_id, rest_id, restaurant_food_menu } = React.useContext(MenyContext);

  React.useEffect(() => {
    setIsLoading(false);
  }, []);

  if (is_loading) return <FullScreenLoading />;

  return (
    <>
      <ShowFoodMenuPage
        menu_id={menu_id}
        rest_id={rest_id}
        food_menu={restaurant_food_menu}
        food_menu_view_only={false}
        // json_food_menu_ads={{ data: null }}
        json_food_menu_ads={{}}
      />
    </>
  );
};
