import React from 'react';
import { makeStyles, Slide } from '@material-ui/core';

import { LYNKED_WHITE } from 'src/consts/colors';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: LYNKED_WHITE,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  food_menu_ad: {
    background: 'rgba( 0, 0, 0, 0.50 )',

    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',

    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    backdropFilter: 'blur( 10px )',
    // boxShadow: '0 8px 32px 0 rgba( 31, 38, 135, 0.37 )',

    width: '100%',
    height: '15vh',
    minHeight: '150px',
  },
  button: {
    color: LYNKED_WHITE,
    marginRight: '1rem',
  },
}));

export { useStyles, Transition };
