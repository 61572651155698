import React from 'react';
import ScrollMenu from 'react-horizontal-scrolling-menu';

import { Box } from '@material-ui/core';
import {ReactComponent as ChevronLeftIcon} from 'src/assets/icons/left-double-arrow.svg'
import {ReactComponent as ChevronRightIcon} from 'src/assets/icons/right-double-arrow.svg'

import Menu from './Menu';

import './horizontal-scrollbar.css';

const ArrowLeft = <ChevronLeftIcon />;
const ArrowRight = <ChevronRightIcon />;

export default function HorizontalScrollBar({ list_ref, list, category_selected, setCategorySelected }) {
  const menu = Menu(list_ref, list, category_selected);

  const onSelect = (key) => {
    console.log('onSelect', key);
    setCategorySelected(key);
  };

  return (
    <Box id="horizontal_scroll_bar">
      <ScrollMenu
        data={menu}
        arrowLeft={ArrowLeft}
        arrowRight={ArrowRight}
        selected={category_selected}
        onSelect={onSelect}
        scrollToSelected={true}
        itemStyle={{ display: 'inline-block', outlineColor: 'rgb(0,0,0,0)' }}
        list_ref={list_ref}
        style={{ height: '100%' }}
      />
    </Box>
  );
}
