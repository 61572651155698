import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogBody from './DialogBody';

export default ({ open, setOpen }) => {
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Dialog open={open} onClose={handleClose} style={{ height: '100%' }}>
        <DialogBody setOpen={setOpen} handleBackOnClick={handleClose} />
      </Dialog>
    </>
  );
};
