import React from 'react';

import active_lang from 'src/langs/jp_en';

import OrderEntry from './OrderEntry';
import { useTranslation } from 'react-i18next';

export default function ListOrders({ orders, load_status, restaurant_config }) {
  const { t } = useTranslation();

  if (orders && orders.length > 0) {
    return (
      <>
        {orders.map((o, i) => {
          return <OrderEntry restaurant_config={restaurant_config} entry={o} key={i} />;
        })}
      </>
    );
  } else {
    if (load_status == 'LOADING_DONE') {
      return <>{t('ORDER_HISTORY_NO_ORDER_HISTORY')}</>;
    } else {
      return <>{t('ORDER_HISTORY_NO_ORDER_HISTORY')}</>;
    }
  }
}
