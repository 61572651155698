import React from 'react';

import RedEntry from './RedEntry';
import BlackEntry from './BlackEntry';
import GreenEntry from './GreenEntry';

const STATUS_DELIVERED = 'delivered';
const STATUS_PREPARING = 'preparing';
const STATUS_CANCELLED = 'cancelled';

export default function OrderEntry({ entry, restaurant_config }) {
  switch (entry.status) {
    case STATUS_PREPARING:
      return <RedEntry entry={entry} restaurant_config={restaurant_config} />;

    case STATUS_DELIVERED:
      return <GreenEntry entry={entry} restaurant_config={restaurant_config} />;

    case STATUS_CANCELLED:
      return <BlackEntry entry={entry} restaurant_config={restaurant_config} />;

    default:
      return <GreenEntry entry={entry} restaurant_config={restaurant_config} />;
  }
}
