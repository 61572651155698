import React from 'react';
import { isUndefined } from 'lodash';

import { MenyContext } from '../../../contexts';
import FullScreenLoading from '../../../components/FullScreenLoading';
import RestaurantIsNotActive from '../../../components/RestaurantIsNotActive';

import RestaurantIsActive from './RestaurantIsActive';

export default function FoodMenuLandingWithAdsPage() {
  let [is_loading, setIsLoading] = React.useState(true);

  const { restaurant_config } = React.useContext(MenyContext);
  let restaurant_active = isUndefined(restaurant_config.restaurant_active) || restaurant_config.restaurant_active;

  React.useEffect(() => {
    setIsLoading(false);
  }, []);

  if (is_loading) return <FullScreenLoading />;

  if (restaurant_active) return <RestaurantIsActive />;

  return <RestaurantIsNotActive />;
}
