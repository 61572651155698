import React from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { LYNKED_DEEP_GREY, LYNKED_GREEN, LYNKED_WHITE } from 'src/consts/colors';
import ShowYen from 'src/components/ShowYen';

const useStyles = makeStyles((theme) => ({
  customize_button: {
    width: '100%',
    padding: '0px',
  },
  customize_button_base: {
    backgroundColor: LYNKED_WHITE,
    border: '1px solid #000000',
    borderRadius: '5px',

    textAlign: 'center',

    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',

    padding: '0.25rem',

    width: '100%',
  },
  customize_button_base_disabled: {
    backgroundColor: LYNKED_WHITE,
    border: `1px solid ${LYNKED_DEEP_GREY}`,
    borderRadius: '5px',

    textAlign: 'center',

    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',

    padding: '0.25rem',

    width: '100%',
  },
  button_status_base: {
    width: '33%',
    height: '100%',
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button_status_size: {
    width: '15px',
    height: '15px',
    borderRadius: '3px',
  },
  button_status_color_enabled: {
    backgroundColor: LYNKED_GREEN,
  },
  button_status_color_disabled: {
    border: `1px solid ${LYNKED_DEEP_GREY}`,
    backgroundColor: LYNKED_WHITE,
  },
  button_text_base: {
    width: '66%',
    height: '100%',
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  option_text: {
    fontFamily: 'Noto Sans JP',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '17px',
    display: 'flex',
    alignItems: 'center',
  },
  option_text_disabled: {
    fontFamily: 'Noto Sans JP',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '17px',
    display: 'flex',
    alignItems: 'center',
    color: LYNKED_DEEP_GREY,
  },
  option_price: {
    fontFamily: 'Noto Sans JP',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '17px',
    display: 'flex',
    alignItems: 'center',
  },
  option_price_disabled: {
    fontFamily: 'Noto Sans JP',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: '17px',
    display: 'flex',
    alignItems: 'center',
    color: LYNKED_DEEP_GREY,
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
}));

export default function CustomizeButton({
  name,
  price,
  option_enabled,
  customize_opt_idx,
  handleOnClick,
  disable_ripple_effect = false,
  restaurant_config,
}) {
  const classes = useStyles();

  return (
    <>
      <Button
        className={classes.customize_button}
        onClick={() => handleOnClick(customize_opt_idx)}
        disableRipple={disable_ripple_effect}
      >
        <div className={option_enabled ? classes.customize_button_base : classes.customize_button_base_disabled}>
          <div className={classes.button_status_base}>
            <div
              className={`${classes.button_status_size} ${
                option_enabled ? classes.button_status_color_enabled : classes.button_status_color_disabled
              }`}
            ></div>
          </div>
          <div className={classes.button_text_base}>
            <div className={option_enabled ? classes.option_text : classes.option_text_disabled}>{name}</div>
            <div className={option_enabled ? classes.option_price : classes.option_price_disabled}>
              <ShowYen value_yen={price} restaurant_config={restaurant_config} />
            </div>
          </div>
        </div>
      </Button>
    </>
  );
}
