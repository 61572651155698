const getPriceAdjustedByCustomization = (enabled_cust_list) => {
  try {
    let adjust_by_customization = 0;
    enabled_cust_list.forEach((x) => {
      adjust_by_customization = Number(adjust_by_customization) + Number(x.price);
    });
    console.log('getPriceAdjustedByCustomization', 'adjust_by_customization', adjust_by_customization);
    return Number(adjust_by_customization).toFixed(2);
  } catch (error) {
    console.log('error', 'getPriceAdjustedByCustomization', 'enabled_cust_list', enabled_cust_list);
  }
};

export default getPriceAdjustedByCustomization;
