import React from 'react';
import { Box, IconButton } from '@material-ui/core';

import { Icon } from '@iconify/react';
import arrowRight from '@iconify-icons/gridicons/arrow-right';

import { MenyLightContext } from 'src/MenyLightApp/contexts';

import MenyLightFoodDetailDialog from '../FoodDetailDialog';
import { useStyles } from './styles';

const LOG_PREFIX = 'FoodMenuListingPageAdTapToFoodDetail';

export default ({ config }) => {
  const classes = useStyles();
  let {
    food_menu,
    rest_id,
    increaseAdsImpressions,
    open_meny_light_ads_food_detail_dialog,
    setOpenMenyLightAdsFoodDetailDialog,
  } = React.useContext(MenyLightContext);

  let target_food_detail = config.ad_destination.food_detail_id;
  let ad_img_url = config.image_urls[0];

  const handleAdBannerOnClick = () => {
    if (food_menu?.food_details[target_food_detail]) {
      setOpenMenyLightAdsFoodDetailDialog(true);
    } else {
      console.log(LOG_PREFIX, 'target food detail not found');
    }
  };

  React.useEffect(() => {
    console.log(LOG_PREFIX, 'increaseAdsImpressions', { rest_id });
    increaseAdsImpressions({ rest_id });
  }, []);

  return (
    <>
      <MenyLightFoodDetailDialog
        open={open_meny_light_ads_food_detail_dialog}
        setOpen={setOpenMenyLightAdsFoodDetailDialog}
        food_entry={food_menu.food_details[target_food_detail]}
      />

      <Box className={classes.root} style={{ backgroundImage: `url(${ad_img_url})` }}>
        <Box
          id="food-menu-ads"
          className={classes.food_menu_ad}
          style={{ backgroundImage: `url(${ad_img_url})` }}
          onClick={handleAdBannerOnClick}
        >
          <Box>
            <IconButton aria-label="delete" className={classes.button} onClick={handleAdBannerOnClick}>
              <Icon icon={arrowRight} />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </>
  );
};
