import React from 'react';
import { Box } from '@material-ui/core';
import ClipLoader from 'react-spinners/ClipLoader';

import { LYNKED_RED } from 'src/consts/colors';
import active_lang from 'src/langs/jp_en';
import LynkedLogoJsx from 'src/components/LynkedLogoJsx';

import { useStyles } from './styles';
import ShowDebug from 'src/components/ShowDebug';
import lynked_black_svg from 'src/assets/tabi-life-black.svg';
import { useTranslation } from 'react-i18next';

export default function FullScreenLoading({ setCacheLoading }) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      {/* <ShowDebug>
        <pre>full screen loading</pre>
      </ShowDebug> */}
      <Box style={{ height: '100%', display: 'flex', flexFlow: 'column', justifyContent: 'center' }}>
        <Box mt={'3rem'} style={{ textAlign: 'center' }}>
          <ClipLoader className={classes.clipLoader} color={LYNKED_RED} loading={true} size={100} />
        </Box>
        <Box mt={'3rem'} style={{ textAlign: 'center' }}>
          {t('LINEUP_GETTING_MENU')}...
        </Box>
        <Box mt="3rem" style={{ width: '100px', height: '20px', margin: '40px auto' }}>
          <Box
            style={{
              width: '100%',
              height: '100%',
              backgroundImage: `url(${lynked_black_svg})`,
              backgroundSize: 'contain',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
            }}
          ></Box>
        </Box>
      </Box>
    </>
  );
}
