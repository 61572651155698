let MAKE_A_RESERVATION = '上記で受付する';
let SEND_LINEUP_REQUEST = '上記で受付する';
let SENDING_LINEUP_REQUEST = '受付中';

let ACCEPT_TO_USE_MENY_EXPLAIN = '※menyご利用いただくには上記で受付する必要があります。';

// 同意する
let ACCEPT_TO_USE_MENY = '同意する';

let BUTTON_TERMS_AND_CONDS_DETAIL_ENG = 'TERMS & CONDITIONS';
let BUTTON_TERMS_AND_CONDS_DETAIL_JP = '利用規約';

let BUTTON_TERMS_AND_CONDS_EXPLAIN_CLOSE = '閉じる';
let CHILDREN = '子供';
let ADULT = '大人';

export default { SEND_LINEUP_REQUEST, SENDING_LINEUP_REQUEST, MAKE_A_RESERVATION, ACCEPT_TO_USE_MENY, ACCEPT_TO_USE_MENY_EXPLAIN, BUTTON_TERMS_AND_CONDS_DETAIL_ENG, BUTTON_TERMS_AND_CONDS_DETAIL_JP, BUTTON_TERMS_AND_CONDS_EXPLAIN_CLOSE, CHILDREN, ADULT };
