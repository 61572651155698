import React from 'react';
import { Box } from '@material-ui/core';

import BottomAdsContainer from '../../components/BottomAdsContainer';

import CustomizeButtonGroup from './CustomizeButtonGroup';
import FoodMenuHead from './FoodMenuHead';
import FoodImage from './FoodImage';
import FoodNameAndPrice from './FoodNameAndPrice';
import FoodDetailDescription from './FoodDetailDescription';

export default ({ handleBackOnClick, food_entry, view_only, restaurant_config }) => {
  let [list_customize_options, setListCustomizeOptions] = React.useState({ a: [] });

  return (
    <>
      <Box
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          flexFlow: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        <FoodMenuHead handleBackOnClick={handleBackOnClick} view_only={view_only} />
        <FoodImage food_entry={food_entry} />
        <FoodNameAndPrice food_entry={food_entry} restaurant_config={restaurant_config} />
        <FoodDetailDescription food_entry={food_entry} />

        <Box style={{ width: '100%' }}>
          <CustomizeButtonGroup
            restaurant_config={restaurant_config}
            food_entry={food_entry}
            list_customize_options={list_customize_options}
            setListCustomizeOptions={setListCustomizeOptions}
          />
        </Box>

        <BottomAdsContainer />
      </Box>
    </>
  );
};
