// import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  button_yes: {
    color: theme.palette.success.main,
  },
  button_no: {
    color: theme.palette.error.main,
  },
}));

export { useStyles };
