import React from 'react';
import { isUndefined } from 'lodash';

import { Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import SoldOutLabel from '../SoldOutLabel';
import { MenyContext } from '../../contexts';
import ShowYen from 'src/components/ShowYen';

import { STATUS_SOLD_OUT } from 'src/consts/SoldOutStatus';
import { LYNKED_DEEP_DEEP_GREY, LYNKED_LIGHT_GREY } from 'src/consts/colors';

const useStyles = makeStyles(() => ({
  order_button_enabled_style: {
    fontFamily: 'Noto Sans JP',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: '0px',
  },
  order_button_disabled_style: {
    fontFamily: 'Noto Sans JP',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: '0px',
  },
  food_image_sold_out_style: {
    opacity: '0.5',
  },
}));

export default ({ food_entry, idx, restaurant_config }) => {
  const classes = useStyles();
  let { setFoodEntry, setOpenFoodDetailDialog } = React.useContext(MenyContext);

  const [order_button_enable, setOrderButtonEnable] = React.useState(true);
  const [food_sold_out, setFoodSoldOut] = React.useState(false);

  const handleFoodOnClick = () => {
    setFoodEntry(food_entry);
    setOpenFoodDetailDialog(true);
  };

  const enableOrderButton = () => {
    setFoodSoldOut(false);
    setOrderButtonEnable(true);
  };

  const disableOrderButton = () => {
    setFoodSoldOut(true);
    setOrderButtonEnable(false);
  };

  React.useEffect(() => {
    if (!isUndefined(food_entry) && !isUndefined(food_entry.sold_out)) {
      if ([STATUS_SOLD_OUT].includes(food_entry.sold_out)) {
        disableOrderButton();
      } else {
        enableOrderButton();
      }
    } else {
      enableOrderButton();
    }
  }, [food_entry]);

  return (
    <>
      <Box
        style={{
          minHeight: '75px',
          display: 'flex',
          flexFlow: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          border: '1px solid #ecf0f1',
          fontFamily: 'Noto Sans JP',
        }}
      >
        <Button
          id={`food_list_${idx}`}
          key={idx}
          className={order_button_enable ? classes.order_button_enabled_style : classes.order_button_disabled_style}
          style={{ textTransform: 'unset' }}
          onClick={handleFoodOnClick}
        >
          <Box style={{ textAlign: 'left', width: '66vw' }}>
            <Box
              style={{
                whiteSpace: 'nowrap',
                lineHeight: '18px',
                textAlign: 'left',

                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '12px',
                color: food_sold_out ? LYNKED_LIGHT_GREY : LYNKED_DEEP_DEEP_GREY,
                paddingRight: '10px',
              }}
            >
              <Box
                style={{ whiteSpace: 'normal', color: food_sold_out ? LYNKED_LIGHT_GREY : 'black', fontWeight: 'bold' }}
              >
                {food_entry.name}
              </Box>
              <Box style={{ whiteSpace: 'normal' }}>{food_entry.long_name}</Box>
              <Box mt="0.25rem">
                <ShowYen restaurant_config={restaurant_config} value_yen={food_entry.unit_price} />
              </Box>
            </Box>
          </Box>
          <div style={{ width: '110px', height: '68px', paddingRight: '20px' }}>
            <Box
              id={`food-entry-image-${idx}`}
              className={food_sold_out ? classes.food_image_sold_out_style : ''}
              style={{
                width: '100%',
                height: '100%',
                borderRadius: '3px',
                backgroundImage: `url(${food_entry.image_url})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'right center',
                display: 'flex',
                flexFlow: 'row',
                justifyContent: 'flex-end',
                alignItems: 'flex-start',
              }}
            ></Box>
            {food_sold_out ? (
              <>
                <div style={{ position: 'absolute', top: '20px', paddingLeft: '60px', opacity: '1' }}>
                  <SoldOutLabel />
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </Button>
      </Box>
    </>
  );
};
