let T_AND_C_SIGN_DATE = '20210701';

let SEAT_WAITING = 'SEAT_WAITING';
let SEAT_ASSIGNED = 'SEAT_ASSIGNED';
let SEAT_LINEUP_CANCELLED_AS_SHOP_CLOSED = 'SEAT_LINEUP_CANCELLED_AS_SHOP_CLOSED';

let INIT_SERVICE_REQUEST_STATUS = 'NOT_CLEARED';
let { REACT_APP_FIREBASE_FUNCTION_EMULATOR_HOST } = process.env;

let FIREBASE_FUNCTION_URL = 'https://us-central1-lynked-demo-tryout.cloudfunctions.net';
let LOCAL_FIREBASE_FUNCTION_URL = `http://${REACT_APP_FIREBASE_FUNCTION_EMULATOR_HOST}/lynked-demo-tryout/us-central1`;

let FIREBASE_FUNCTION_HOST =
  process.env.NODE_ENV === 'development' ? LOCAL_FIREBASE_FUNCTION_URL : FIREBASE_FUNCTION_URL;

let REST_MNGT_ENDPOINT = FIREBASE_FUNCTION_HOST + '/api';

const LINEUP_TEMP_COOKIE_OPTIONS = {
  path: '/',
  maxAge: 3600 * 24 * 1,
};
const DEFAULT_COOKIE_OPTIONS = {
  path: '/',
  maxAge: 3600 * 24 * 1,
};

// NAMES_FOR_COOKIE
let CART_ITEMS = 'CART_ITEMS';
let COOKIE_ACCEPT_T_AND_C = 'ACCEPT_T_AND_C';
let IGNORE_INITIAL_SCROLLING = 'IGNORE_INITIAL_SCROLLING';
let LINE_UP_DOC_ID = 'LINE_UP_DOC_ID';
let MONITOR_SERVICE_REQUESTS = 'monitor_service_requests';
let ORDER_HISTORY = 'ORDER_HISTORY';
let ORDER_HISTORY_ID = 'ORDER_HISTORY_ID';
let ORDER_ID = 'ORDER_ID';
let PROMPTED_ALREADY = 'PROMPTED_ALREADY';
let RESTAURANT_CONFIG = 'restaurant_config';

export default {
  T_AND_C_SIGN_DATE,
  CART_ITEMS,
  COOKIE_ACCEPT_T_AND_C,
  IGNORE_INITIAL_SCROLLING,
  LINE_UP_DOC_ID,
  MONITOR_SERVICE_REQUESTS,
  ORDER_HISTORY_ID,
  ORDER_HISTORY,
  ORDER_ID,
  PROMPTED_ALREADY,
  RESTAURANT_CONFIG,
  DEFAULT_COOKIE_OPTIONS,
  LINEUP_TEMP_COOKIE_OPTIONS,
  INIT_SERVICE_REQUEST_STATUS,
  SEAT_WAITING,
  SEAT_ASSIGNED,
  REST_MNGT_ENDPOINT,
};
