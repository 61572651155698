import React from 'react';
import ShowDebug from 'src/components/ShowDebug';
import { Box, Button } from '@material-ui/core';
import { LYNKED_GREEN, LYNKED_WHITE } from 'src/consts/colors';
import active_lang from 'src/langs/jp_en';
import { useTranslation } from 'react-i18next';

export default function ReservationNumberSign({ handleClose }) {
  const { t } = useTranslation();

  return (
    <>
      <div
        style={{
          height: '100%',
          display: 'flex',
          flexFlow: 'column',
          background: '#ACACAC 0% 0% no-repeat padding-box',

          justifyContent: 'center',
          alignItems: 'center',
          overflowX: 'hidden',
          borderRadius: '5px',
        }}
      >
        <Box
          style={{
            width: '90vw',
            height: '30vh',
            backgroundColor: LYNKED_WHITE,

            display: 'flex',
            flexFlow: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box
            style={{
              display: 'flex',
              flexFlow: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              flexGrow: 1,
              borderRadius: '0px 0px 5px 5px',
            }}
          >
            {/* あなたの受付番号は{reservation_number}番です。 */}
            {t('LINEUP_ACEEPTED')}
            {/* <ShowDebug>REQUEST_ACCEPTED</ShowDebug> */}
          </Box>
          <Box style={{ width: '100%' }}>
            <Button
              id="ack-reservation-number"
              fullWidth
              style={{
                height: '50px',
                color: LYNKED_WHITE,
                backgroundColor: LYNKED_GREEN,
                borderRadius: 'unset',
                // font: 'normal normal normal 15px/20px Noto Sans JP',
                fontSize: '15px',
                lineHeight: '20px',
              }}
              onClick={handleClose}
            >
              {t('LINEUP_OK')}
            </Button>
          </Box>
        </Box>
      </div>
    </>
  );
}
