import { useMutation } from 'react-query';

import { loadRestaurantConfig } from 'src/modals/restaurant_config';

const useMutationRestaurantConfig = () => {
  return useMutation(
    `mutation-restaurant-config`,
    (rest_id) => {
      console.log('useMutationRestaurantConfig', 'rest_id', rest_id);
      return loadRestaurantConfig(rest_id).then((doc) => {
        return doc.data();
      });
    },
    { retry: 5 }
  );
};

export default useMutationRestaurantConfig;
