import React from 'react';
import useMutationIncreaseAdsImpressions from 'src/hooks/useMutationIncreaseAdsImpressions';

export default () => {
  let [AdvertisementContext_to_monitor1, setAdvertisementContextToMonitor1] = React.useState(null);
  let [is_loading_advertisement, setIsLoadingAdvertisement] = React.useState(true);

  let [advertisement_config, setAdvertisementConfig] = React.useState();

  let [json_food_menu_ads, setJsonFoodMenuAds] = React.useState({
    isLoading: true,
  });

  const [cached_food_menu_ads, setCachedFoodMenuAds] = React.useState({});

  let mutateIncreaseAdsImpressions = useMutationIncreaseAdsImpressions();

  const increaseAdsImpressions = ({ rest_id }) => {
    return mutateIncreaseAdsImpressions.mutateAsync(rest_id);
  };
  const [open_meny_light_ads_food_detail_dialog, setOpenMenyLightAdsFoodDetailDialog] = React.useState(false);

  return {
    AdvertisementContext_to_monitor1,
    setAdvertisementContextToMonitor1,
    json_food_menu_ads,
    setJsonFoodMenuAds,
    advertisement_config,
    setAdvertisementConfig,
    cached_food_menu_ads,
    setCachedFoodMenuAds,
    increaseAdsImpressions,
    open_meny_light_ads_food_detail_dialog,
    setOpenMenyLightAdsFoodDetailDialog,
    is_loading_advertisement,
    setIsLoadingAdvertisement,
  };
};
