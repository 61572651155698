import React from 'react';
import { isUndefined } from 'lodash';

import { MenyContext } from '../../../contexts';

import RedirectAfterLoading from './RedirectAfterLoading';
import RestaurantIsNotActive from '../../../components/RestaurantIsNotActive';

export default () => {
  let { restaurant_config } = React.useContext(MenyContext);

  let is_restaurant_active = isUndefined(restaurant_config.restaurant_active) || restaurant_config.restaurant_active;

  if (!is_restaurant_active) return <RestaurantIsNotActive />;

  return (
    <>
      <RedirectAfterLoading />
    </>
  );
};
