import React from 'react';

import { Box, Button, Dialog } from '@material-ui/core';
import { LYNKED_WHITE, LYNKED_GREEN, LYNKED_DEEP_GREY } from 'src/consts/colors';
import active_lang from 'src/langs/jp_en';
import TAndCExplain from './TAndCExplain';

import { isNull, isUndefined } from 'lodash';
import config from 'src/config';

import { MenyContext } from '../../contexts';
import { useTranslation } from 'react-i18next';

export default function TermsAndConditionsSign() {
  let [open, setOpen] = React.useState(false);
  let [open_explain, setOpenExplain] = React.useState(false);
  let { rest_id, loadUserAcceptedTAndC, storeUserAcceptedTAndC } = React.useContext(MenyContext);
  const { t } = useTranslation();

  const handleAcceptOnClick = () => {
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleViewExplainTermsAndConditions = () => {
    setOpenExplain(true);
  };

  const handleUserAcceptTAndC = () => {
    handleAcceptOnClick();
    handleClose();
    storeUserAcceptedTAndC({
      accept_status: 'user_accepted',
      t_and_c_contract_date: config.T_AND_C_SIGN_DATE,
    });
  };

  let ls_user_accept_t_and_c = loadUserAcceptedTAndC();
  React.useEffect(() => {
    if (isNull(ls_user_accept_t_and_c) || isUndefined(ls_user_accept_t_and_c)) {
      setOpen(true);
    } else {
      if (
        isUndefined(ls_user_accept_t_and_c.accept_status) ||
        isUndefined(ls_user_accept_t_and_c.t_and_c_contract_date)
      ) {
        setOpen(true);
      } else {
        if (
          ls_user_accept_t_and_c.accept_status !== 'user_accepted' ||
          ls_user_accept_t_and_c.t_and_c_contract_date !== config.T_AND_C_SIGN_DATE
        ) {
          setOpen(true);
        }
      }
    }
  }, [ls_user_accept_t_and_c]);

  return (
    <>
      <TAndCExplain open={open_explain} setOpen={setOpenExplain} />
      <Dialog
        id="terms_and_condition_sign"
        open={open}
        style={{ height: '100%' }}
        BackdropProps={{
          style: {
            background: 'rgba( 0, 0, 0, 0.15 )',
            boxShadow: '0 8px 32px 0 rgba( 31, 38, 135, 0.37 )',
            backdropFilter: 'blur( 4px )',
            borderRadius: '10px',
            border: '1px solid rgba( 255, 255, 255, 0.18 )',
          },
        }}
        transitionDuration={{ enter: 0, exit: 0 }}
      >
        <div
          style={{
            height: '100%',
            display: 'flex',
            flexFlow: 'column',
            background: '#ACACAC 0% 0% no-repeat padding-box',

            justifyContent: 'center',
            alignItems: 'center',

            overflowX: 'hidden',
          }}
          id={'terms_and_conds_sign'}
        >
          <Box
            style={{
              width: '90vw',
              height: '250px',
              backgroundColor: LYNKED_WHITE,

              display: 'flex',
              flexFlow: 'column',
              justifyContent: 'space-between',
            }}
          >
            <Box
              style={{
                padding: '0rem',
                paddingTop: '3rem',
                width: '100%',
                textAlign: 'center',

                display: 'flex',
                flexFlow: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Box style={{ width: '60%', textAlign: 'center' }}>
                {t('LINEUP_AGREE_PRIVACY')}
              </Box>
              <Box style={{ paddingTop: '3rem' }}>
                <Button
                  id="explain_terms_and_conds_jp"
                  style={{
                    color: LYNKED_DEEP_GREY,
                    border: '0.5px solid #9A9A9A',
                    borderRadius: '14px',
                    marginRight: '0.2rem',
                    padding: '0 1rem',
                  }}
                  onClick={handleViewExplainTermsAndConditions}
                >
                  {t('LINEUP_TERM_SERVICES')}
                </Button>
                <Button
                  id="explain_terms_and_conds_en"
                  style={{
                    color: LYNKED_DEEP_GREY,
                    border: '0.5px solid #9A9A9A',
                    borderRadius: '14px',
                    marginLeft: '0.2rem',
                    padding: '0 1rem',
                  }}
                  onClick={handleViewExplainTermsAndConditions}
                >
                  {t('LINEUP_TERM_CONDITIONS')}
                </Button>
              </Box>
            </Box>

            <Box style={{ width: '100%' }}>
              <Button
                id="accept_terms_and_cond"
                fullWidth
                style={{
                  height: '50px',
                  color: LYNKED_WHITE,
                  backgroundColor: LYNKED_GREEN,
                  // background: '#388E00 0% 0% no-repeat padding-box',
                  borderRadius: 'unset',
                  fontWeight: 'bold',
                }}
                onClick={handleUserAcceptTAndC}
              >
                {t('LINEUP_AGREE')}
              </Button>
            </Box>
          </Box>
        </div>
      </Dialog>
    </>
  );
}
