import React from 'react';

function AlarmIconJsx() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      enableBackground="new 0 0 320 320"
      version="1.1"
      viewBox="0 0 320 320"
      xmlSpace="preserve"
    >
      <g>
        <path d="M160 0c-8.284 0-15 6.717-15 15v10.344c-50.816 7.301-90 51.118-90 103.923V260h-5c-8.284 0-15 6.717-15 15 0 8.285 6.716 15 15 15h67.58c6.192 17.459 22.865 30 42.42 30s36.227-12.541 42.42-30H270c8.284 0 15-6.715 15-15 0-8.283-6.716-15-15-15h-5V129.267c0-52.805-39.184-96.622-90-103.923V15c0-8.283-6.716-15-15-15z"></path>
      </g>
    </svg>
  );
}

export default AlarmIconJsx;
