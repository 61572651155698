import React from 'react';

import { Box, Button } from '@material-ui/core';
import lynked_svg from 'src/assets/tabi-life-black.svg';

function ListEnd() {
  const handleOnClick = () => {
    // NOTE: to navigate outside meny-client.menymeny.com
    window.location.href = ' http://tabilife.co.jp';
  };

  return (
    <>
      <Button style={{ textDecoration: 'unset', width: '100%' }} onClick={handleOnClick}>
        <Box style={{ width: '100%' }} mt="0.5rem" mb="0.5rem">
          <Box
            style={{
              display: 'flex',
              flexFlow: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: '40px',
            }}
          >
            <Box
              style={{
                height: '30px',
                display: 'flex',
                flexFlow: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                marginRight: '0.25rem',
              }}
            >
              Powered by
            </Box>
            <Box
              id="bottom-lynked-logo"
              style={{
                backgroundImage: `url(${lynked_svg})`,
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
                width: '100px',
                height: '20px',
              }}
            ></Box>
          </Box>
        </Box>
      </Button>
    </>
  );
}

export default React.memo(ListEnd);
