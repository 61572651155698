import React from 'react';

import { Box, IconButton } from '@material-ui/core';

import { useStyles } from './styles';

export default function RubbishBinButton({ handleOnClick }) {
  const classes = useStyles();

  return (
    <>
      <Box className={classes.buttons}>
        <IconButton onClick={handleOnClick}>
          {/* <Box
            style={{
              width: '18px',
              height: '18px',
              marginLeft: '2px',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              backgroundImage: `url(${RUBBISH_BIN_ICON_SVG})`,
            }}
          /> */}
          <div
            style={{
              width: '18px',
              height: '18px',
              display: 'flex',
              flexFlow: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" fill="none" viewBox="0 0 14 16">
              <path
                fill="#fff"
                d="M12.28 3.73l-1.578-.75.082-.171a1.515 1.515 0 00-.718-2.016L8.707.147A1.503 1.503 0 007.551.088C7.17.224 6.865.5 6.69.865l-.081.172-1.578-.75a1.66 1.66 0 00-2.209.787l-.404.85a.563.563 0 00.268.75l4.18 1.987h-5.53a.563.563 0 00-.562.61l.74 8.884A2.024 2.024 0 003.522 16h4.143c1.038 0 1.918-.81 2.004-1.845l.655-7.853 1.589.754a.56.56 0 00.75-.267l.404-.85a1.66 1.66 0 00-.786-2.209zM7.71 1.348a.384.384 0 01.514-.183l1.359.645a.387.387 0 01.183.515l-.082.173-2.057-.978.082-.172zm.836 12.714a.89.89 0 01-.882.811H3.52a.89.89 0 01-.882-.811l-.69-8.275h7.286l-.69 8.275zm3.502-8.607l-.162.342L3.68 1.899l.162-.342a.531.531 0 01.707-.252l7.249 3.443a.531.531 0 01.251.707z"
              ></path>
            </svg>
          </div>
        </IconButton>
      </Box>
    </>
  );
}
