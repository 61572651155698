import React from 'react';
import './horizontal-scrollbar.css';

import MenuItem from './MenuItem';

const Menu = (list_ref, list, selected) =>
  list.map((el, idx) => {
    const { name, html_id } = el;
    const first_html_id = list[0].html_id;

    return (
      <MenuItem
        idx={idx}
        list_ref={list_ref}
        text={name}
        key={name}
        selected={selected}
        html_id={html_id}
        first_html_id={first_html_id}
      />
    );
  });

export default Menu;
