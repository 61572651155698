import React from 'react';
import ShowDebug from 'src/components/ShowDebug';
import { NOT_MONITORING, MONITORING, CLIENT_BILL_CLEARED } from 'src/StateMachines/STATES';

import { monitorOrderChange } from 'src/modals/order';

import { MenyContext } from '..';

// import updateByMonitorOrderStatusMachine from './updateByMonitorOrderStatusMachine';
// import { GlobalContext } from 'src/contexts/global';
// import { AssignedTableContext } from 'src/contexts/LocalStorageContext/AssignedTableContext';
// import { BusContext } from 'src/contexts/BusContext';
// import { LineupDocIdContext } from 'src/contexts/LocalStorageContext/LineupDocIdContext';

let LOG_PREFIX = 'MonitorOrderStatusMachineComponent';

export default function MonitorOrderStatusMachineComponent({ machines }) {
  let { rest_id, setOrderHistory } = React.useContext(MenyContext);
  let { monitor_table_status_machine, monitor_order_status_machine, meny_client_state_machine } = machines;

  let [monitor_order_status_state, sendMonitorOrderStatus] = monitor_order_status_machine;
  let [client_state_machine, sendMenyClientStateMachine] = meny_client_state_machine;

  React.useEffect(() => {
    console.log(LOG_PREFIX, 'state changed', monitor_order_status_state);

    let unsub;

    if (monitor_order_status_state.matches(MONITORING)) {
      let { order_history_id } = monitor_order_status_state.context;
      console.log(LOG_PREFIX, 'order_history_id', { order_history_id });

      unsub = monitorOrderChange(rest_id, order_history_id, (doc) => {
        let doc_data = doc.data();
        console.log(LOG_PREFIX, 'monitorOrderChange', 'doc_data', doc_data);
        console.log(LOG_PREFIX, 'monitorOrderChange', 'order_history_id', order_history_id);
        if (doc_data) {
          setOrderHistory(doc_data);
          if (doc_data.bill_status === 'CLEARED') {
            sendMenyClientStateMachine(CLIENT_BILL_CLEARED);
            sendMonitorOrderStatus(NOT_MONITORING);
          } else {
          }
        }
      });
    } else if (monitor_order_status_state.matches(NOT_MONITORING)) {
      console.log(LOG_PREFIX, 'unsub', unsub);
      if (unsub) unsub();
    } else {
      console.error(LOG_PREFIX, 'monitor_order_status_state not handled');
    }
  }, [monitor_order_status_state]);

  const printOrderStatusMachine = () => {
    let { value, context } = monitor_order_status_state;
    console.log(LOG_PREFIX, 'monitor_order_status_state', { value, context });
  };

  return (
    <>
      {/* <ShowDebug>
        <button onClick={() => printOrderStatusMachine()}>printOrderStatusMachine</button>
      </ShowDebug> */}
    </>
  );
}
