import i18n from 'i18next';
import React from 'react';
import { initReactI18next } from 'react-i18next';
import { I18nextProvider } from 'react-i18next';
import useGetSourceLang from 'src/hooks/useGetSourceLang';
import { useEffect } from 'react';

i18n.use(initReactI18next).init({
  fallbackLng: 'ja',
  resources: {
    ja: {
      translation: {
        LINEUP_GETTING_MENU: "Loading"
      }
    }
  },
});

export function I18nProvider({ children, context }) {
  const { restaurant_config } = React.useContext(context);
  const { data: resources } = useGetSourceLang('client');

  useEffect(() => {
    if (restaurant_config.client_lang_display) {
      i18n.changeLanguage(restaurant_config?.client_lang_display);
    } else {
      i18n.changeLanguage('ja');
    }
    if (resources) {
      for (var key in resources) {
        i18n.addResourceBundle(key, 'translation', resources[key]);
      }
    }
  }, [resources, restaurant_config]);

  return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>;
}

export default i18n;
