import { Box, Fade } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import React from 'react';
import { LYNKED_LIGHT_GREY, LYNKED_WHITE } from 'src/consts/colors';

export default function BackToTopButton({ already_on_top }) {
  const handleBackToTop = () => {
    var ele_to_scroll = document.querySelector('#food-menu-food-entry');
    if (ele_to_scroll) {
      ele_to_scroll.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  };

  return (
    <>
      <Box style={{ position: 'fixed', bottom: '10vh', right: '4vw', zIndex: '1' }}>
        <Fade in={!already_on_top}>
          <Box
            style={{
              backgroundColor: LYNKED_LIGHT_GREY,
              borderRadius: '20%',
            }}
          >
            <IconButton
              id="back_to_top"
              variant="contained"
              aria-label="BackToTop"
              onClick={handleBackToTop}
              style={{ color: LYNKED_WHITE }}
            >
              <ExpandLessIcon />
            </IconButton>
          </Box>
        </Fade>
      </Box>
    </>
  );
}
